import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FileManagement } from '_components/_shared';

function TransactionFiles({
  isFetchingFiles,
  files,
  tempFiles,
  transaction,
  onFilesUpload,
  onFilesDelete,
  onFetchTransactionFiles,
  onClearTransactionFiles,
  onFilesSelected,
}) {
  useEffect(() => {
    onClearTransactionFiles();

    const transaction_id = transaction.id;

    if (!transaction_id) {
      return;
    }

    onFetchTransactionFiles(transaction_id);
  }, [onFetchTransactionFiles, onClearTransactionFiles, transaction]);

  return (
    <>
      <FileManagement
        files={files}
        tempFiles={tempFiles}
        isFetching={isFetchingFiles}
        entityId={transaction.id}
        entityType="transaction"
        onUpload={onFilesUpload}
        onDelete={onFilesDelete}
        onFilesSelected={onFilesSelected}
        emptyMessage="Nenhum arquivo anexado a essa movimentação."
        formFieldLabel="Arquivos dessa movimentação"
      />
    </>
  );
}

TransactionFiles.defaultProps = {
  transaction: {},
  isFetchingFiles: false,
  tempFiles: [],
  files: [],
  onFilesSelected: () => {},
};

TransactionFiles.propTypes = {
  transaction: PropTypes.object,
  files: PropTypes.array,
  tempFiles: PropTypes.array,
  isFetchingFiles: PropTypes.bool,
  onFilesUpload: PropTypes.func.isRequired,
  onFilesDelete: PropTypes.func.isRequired,
  onFetchTransactionFiles: PropTypes.func.isRequired,
  onClearTransactionFiles: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
};

export default TransactionFiles;
