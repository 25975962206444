import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';

import { isFeatureEnabled } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button, FloatingCard, Tabs } from '_components/_core';
import { LoadingIcon } from '_components/_shared';

import { ContactDetails, ContactFiles } from './components';
import { FormSchema } from './utilities';
import { StyledFooter } from './styles';

function ContactForm({
  isOpen,
  isSearching,
  contact_id,
  contact: oldContact,
  onCreateContact,
  onUpdateContact,
  onSearchCompanyByCnpj,
  onClearSearchedCompany,
  onFetchCities,
  onFetchContact,
  onToggleForm,
  onBeforeSaveCallback,
  onAfterSaveCallback,
  onAfterSaveCallbackWithReset,
  onDeleteContact,
  onAfterDeleteCallback,
  user,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [newContact, setNewContact] = useState({});
  const [activeTab, setActiveTab] = useState('MAIN');
  const [tempFiles, setTempFiles] = useState([]);

  const contact = useMemo(() => {
    if (contact_id) {
      return newContact;
    }

    return oldContact;
  }, [contact_id, newContact, oldContact]);

  useEffect(() => {
    if (!isOpen) {
      setActiveTab('MAIN');
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!contact_id) {
      return;
    }

    setIsLoading(true);

    onFetchContact(contact_id, found_contact => {
      setNewContact(found_contact);

      setIsLoading(false);
    });
  }, [contact_id, onFetchContact, isOpen]);

  const handleSubmitForm = useCallback(
    (values, { resetForm }) => {
      setIsLoading(true);

      if (onBeforeSaveCallback) {
        onBeforeSaveCallback(values);
      }

      if (values.id) {
        onUpdateContact(values.id, values, updated_contact => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(updated_contact);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(updated_contact);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }

          setIsLoading(false);
        });
      } else {
        onCreateContact(values, created_contact => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(created_contact);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(created_contact);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }

          setIsLoading(false);
        });
      }
    },
    [
      onCreateContact,
      onUpdateContact,
      onToggleForm,
      onBeforeSaveCallback,
      onAfterSaveCallback,
      onAfterSaveCallbackWithReset,
    ],
  );

  const handleDeleteContact = useCallback(() => {
    onDeleteContact(contact.id, {}, onAfterDeleteCallback);
  }, [onDeleteContact, contact, onAfterDeleteCallback]);

  const renderFooter = useCallback(
    (handleSubmit, isValid) => (
      <StyledFooter>
        <Button
          type="submit"
          variant="success-2"
          className="mr-2 flex-fill"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          {contact.id ? 'Salvar Alterações' : 'Salvar'}
        </Button>
        <Button className="flex-fill" variant="inverse-dark" onClick={onToggleForm}>
          Cancelar
        </Button>
      </StyledFooter>
    ),
    [onToggleForm, contact, isLoading],
  );

  const handleCompanySearch = useCallback(
    (values, setFieldValue) => {
      onClearSearchedCompany();

      onSearchCompanyByCnpj(values.document_number, searchedCompany => {
        onFetchCities(searchedCompany.address_state_ibge, () => {
          setFieldValue('name', searchedCompany.company_name);
          setFieldValue('phone_number', searchedCompany.phone_number);
          setFieldValue('email', searchedCompany.email);
          setFieldValue('address_zip_code', searchedCompany.address_zip_code);
          setFieldValue('address_street', searchedCompany.address_street);
          setFieldValue('address_number', searchedCompany.address_number);
          setFieldValue('address_district', searchedCompany.address_district);
          setFieldValue('address_complement', searchedCompany.complement);
          setFieldValue('address_city', searchedCompany.address_city);
          setFieldValue('address_city_ibge', Number(searchedCompany.address_city_ibge));
          setFieldValue('address_state', searchedCompany.address_state);
          setFieldValue('address_state_ibge', Number(searchedCompany.address_state_ibge));
        });
      });
    },
    [onSearchCompanyByCnpj, onFetchCities, onClearSearchedCompany],
  );

  const renderDeleteButton = useCallback(() => {
    if (!contact.id) {
      return null;
    }

    if (activeTab !== 'MAIN') {
      return null;
    }

    return (
      <div className="d-flex align-items-center justify-content-between mb-1 mt-1">
        <small className="ml-3 text-muted">
          {contact &&
            contact.id &&
            `Criado em: ${FORMATTERS.DATE_DDMMYYYYHHMMSS(contact.created_at)}`}
          {contact && !contact.id && 'Este item ainda não foi salvo.'}
        </small>
        {contact && contact.id && (
          <Button
            variant="link"
            className="text-danger"
            type="submit"
            onClick={handleDeleteContact}
            id="btn-delete-contact"
            tabIndex={-1}
          >
            Excluir
          </Button>
        )}
      </div>
    );
  }, [contact, handleDeleteContact, activeTab]);

  const initialValues = useMemo(
    () => ({
      id: contact.id,
      document_number: contact.document_number || '',
      document_type: contact.document_type,
      name: contact.name || '',
      email: contact.email || '',
      phone_number: contact.phone_number || '',
      address_zip_code: contact.address_zip_code || '',
      address_street: contact.address_street || '',
      address_number: contact.address_number || '',
      address_district: contact.address_district || '',
      address_complement: contact.address_complement || '',
      address_city: contact.address_city || '',
      address_city_ibge: contact.address_city_ibge || '',
      address_state: contact.address_state || '',
      address_state_ibge: contact.address_state_ibge || '',
      type: contact.type,
    }),
    [contact],
  );

  const handleFilesSelected = useCallback(files => {
    setTempFiles(files);
  }, []);

  const getTabs = useCallback(
    ({ handleSubmit, setFieldValue, values }) => {
      const props = {
        values,
        setFieldValue,
        handleSubmit,
        handleCompanySearch,
        isSearching,
        contact,
        tempFiles,
        onFilesSelected: handleFilesSelected,
      };

      const tabs = [
        {
          id: 'MAIN',
          title: 'Detalhes',
          content: activeTab === 'MAIN' && <ContactDetails {...props} />,
        },
      ];

      if (contact.id && isFeatureEnabled('contact_files', user.flags)) {
        tabs.push({
          id: 'FILES',
          title: 'Arquivos',
          content: activeTab === 'FILES' && (
            <ContactFiles contact={contact} tempFiles={tempFiles} />
          ),
        });
      }

      return tabs;
    },
    [
      activeTab,
      contact,
      isSearching,
      handleCompanySearch,
      tempFiles,
      handleFilesSelected,
      user,
    ],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={FormSchema}
    >
      {({ handleSubmit, values, setFieldValue, isValid }) => (
        <>
          <FloatingCard
            title={!isEmpty(contact) ? 'Editar contato' : 'Novo contato'}
            isVisible={isOpen}
            onToggleVisibility={onToggleForm}
            footerContent={renderFooter(handleSubmit, isValid)}
            side="right"
            fullHeight
            bodyClassName="p-0"
            beforeFooterContent={renderDeleteButton()}
            withCloseButton
          >
            {isLoading && (
              <div className="h-100 d-flex justify-content-center">
                <LoadingIcon text="Aguarde ..." />
              </div>
            )}
            {!isLoading && (
              <>
                <Tabs
                  variant="secondary"
                  tabs={getTabs({
                    handleSubmit,
                    setFieldValue,
                    values,
                  })}
                  activeTab={activeTab}
                  onTabChange={tab => {
                    setActiveTab(tab);
                  }}
                />
              </>
            )}
          </FloatingCard>
        </>
      )}
    </Formik>
  );
}

ContactForm.defaultProps = {
  contact: {},
  contact_id: null,
  onBeforeSaveCallback: null,
  onAfterSaveCallback: null,
  onAfterSaveCallbackWithReset: null,
  onAfterDeleteCallback: null,
  user: {},
};

ContactForm.propTypes = {
  isOpen: PropTypes.bool,
  isSearching: PropTypes.bool,
  contact: PropTypes.object,
  contact_id: PropTypes.string,
  onCreateContact: PropTypes.func,
  onUpdateContact: PropTypes.func,
  onToggleForm: PropTypes.func,
  onSearchCompanyByCnpj: PropTypes.func,
  onClearSearchedCompany: PropTypes.func,
  onFetchCities: PropTypes.func,
  onFetchContact: PropTypes.func.isRequired,
  onBeforeSaveCallback: PropTypes.func,
  onAfterSaveCallback: PropTypes.func,
  onAfterSaveCallbackWithReset: PropTypes.func,
  onAfterDeleteCallback: PropTypes.func,
  user: PropTypes.object,
};

export default ContactForm;
