import styled, { css } from 'styled-components';

import { Dropdown } from 'react-bootstrap';

import { Card, Button } from '_components/_core';

export const CustomCardHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #F1F1F4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    color: #071437;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
    cursor: pointer;

    :hover {
      background-color: #F9F9FB;
    }
  `}
`;

export const CustomCard = styled(Card)`
  border: 1px solid #F1F1F4;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding-bottom: 0;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`;

export const CustomCardBody = styled.div`
  padding: 16px;

  ${({ noPadding }) =>
    noPadding &&
    css`
    padding: 0;
  `}
`;

export const NotificationVariantDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  
  ${({ variant }) => {
    switch (variant) {
      case 'info':
        return 'background-color: #2684FF;';
      case 'success':
        return 'background-color: #38A915;';
      case 'warning':
        return 'background-color: #fcca00;';
      case 'error':
        return 'background-color: #e64d43;';
      default:
        return 'background-color: #383d41;';
    }
  }}
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background: #f5f5f5;
  }

  tbody tr {    
    &:hover {
      .download-button {
        display: flex;
      }
    }

    td {
      height: 50px;
      line-height: 20px;
      padding: 9px !important;
      vertical-align: middle;
      border-top: 1px solid #F1F1F4;
      cursor: pointer !important;
      font-weight: 400;
      cursor: default;
    }
  }
`;

export const NotificationButton = styled(Button)`
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const DownloadButton = styled(Button)`
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const FileButton = styled(Button)`
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const StyledFileList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledFileItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #fff;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const StyledFileName = styled.div`
  flex: 1;
  padding-left: 8px;
  font-size: 0.8rem;
  span {
    font-weight: 500;
  }

  small {
    color: #6c757d;
  }

  :hover {
    cursor: pointer;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
`;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  
  button {
    border-radius: 0px;
    height: 65px;
    background: transparent;
    border: 1px solid #ebe9e9;

    :focus {
      box-shadow: none;
    }
  }

  button:first-child {
    flex: 1 !important;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: none !important;
  }
`;

export const StyledFile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
`;

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
`;
