import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { ListGroup } from 'react-bootstrap';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { useDispatch } from 'react-redux';

import alertActions from '_store/_actions/alert.actions';
import FORMATTERS from 'helpers/formatters';
import checkBlockedPeriod from 'helpers/checkBlockedPeriod';

import { StyledListGroupItem } from './styles';

function TransactionsList({
  transactions,
  emptyMessage,
  incomesTotalMessage,
  expensesTotalMessage,
  transfersTotalMessage,
  onTogglePaid,
  onEditTransaction,
  selectedItems,
  blockedPeriod,
}) {
  const dispatch = useDispatch();

  const totals = useMemo(() => {
    const incomes = transactions.filter(transaction => transaction.type === 'INCOME');
    const expenses = transactions.filter(transaction => transaction.type === 'EXPENSE');
    const transfers = transactions.filter(transaction => transaction.type === 'TRANSFER');

    const totalIncomes = incomes.reduce((acc, curr) => acc + curr.amount, 0);
    const totalExpenses = expenses.reduce((acc, curr) => acc + curr.amount, 0);
    const totalTransfers = transfers.reduce((acc, curr) => acc + curr.amount, 0);

    return {
      incomes: totalIncomes,
      expenses: totalExpenses,
      transfers: totalTransfers,
    };
  }, [transactions]);

  const getItemDescription = useCallback(transaction => {
    if (!transaction) {
      return null;
    }

    if (transaction.payment_plan === 'INSTALMENT') {
      return `${transaction.description} (${transaction.frequency_number}/${transaction.frequency_total})`;
    }

    return transaction.description;
  }, []);

  const handleEditTransaction = useCallback(
    transaction => {
      const isPeriodAllowed = checkBlockedPeriod(
        blockedPeriod,
        transaction.event_date,
        transaction.paid,
      );

      if (!isPeriodAllowed) {
        dispatch(
          alertActions.error(
            'Não é possível editar um lançamento que está em um período bloqueado',
          ),
        );

        return;
      }

      onEditTransaction(transaction);
    },
    [dispatch, onEditTransaction, blockedPeriod],
  );

  const handleTogglePaid = useCallback(
    (e, transaction) => {
      const isPeriodAllowed = checkBlockedPeriod(blockedPeriod, transaction.event_date);

      if (!isPeriodAllowed) {
        dispatch(
          alertActions.error(
            'Não é possível editar um lançamento que está em um período bloqueado',
          ),
        );

        e.preventDefault();
        e.stopPropagation();

        return;
      }

      onTogglePaid(e, transaction);
    },
    [dispatch, blockedPeriod, onTogglePaid],
  );

  if (isEmpty(transactions)) {
    return <small className="no-transactions text-muted">{emptyMessage}</small>;
  }

  return (
    <>
      <ListGroup>
        {transactions.map(transaction => {
          let classColor = '';

          const prefix = transaction.type === 'EXPENSE' ? 'para:' : 'de:';

          switch (transaction.type) {
            case 'INCOME':
              classColor = 'text-success financial-value';
              break;
            case 'EXPENSE':
              classColor = 'text-danger financial-value';
              break;
            case 'TRANSFER':
              classColor = 'text-info financial-value';
              break;
            default:
              classColor = 'financial-value';
              break;
          }

          return (
            <StyledListGroupItem
              key={transaction.id}
              onClick={() => handleEditTransaction(transaction)}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              active={selectedItems.includes(transaction.id)}
              className={'transaction-list-item'}
            >
              <div className="d-flex flex-row align-items-center">
                <div>
                  <small className={!transaction.description && 'text-muted'}>
                    {getItemDescription(transaction) || 'Sem descrição'} -{' '}
                    <strong
                      className={classColor}
                    >{`${FORMATTERS.NUMBER(transaction.amount)}`}</strong>
                  </small>
                  <br />
                  <small className="text-muted">
                    <small>{FORMATTERS.DATE_DDMMYYYY(transaction.event_date)}</small>
                    {transaction.type !== 'TRANSFER' && (
                      <small>
                        {`, ${prefix} ${transaction.recipient.name || 'Não informado'}`}
                      </small>
                    )}
                  </small>
                </div>
              </div>
              <div className="ml-auto d-flex align-items-center justify-content-center">
                {!transaction.paid && (
                  <BsToggleOff
                    size="1.8em"
                    className="text-muted btn-toggle-paid"
                    onClick={e => handleTogglePaid(e, transaction)}
                  />
                )}
                {transaction.paid && (
                  <BsToggleOn
                    size="1.8em"
                    className="text-success btn-toggle-paid toggle-has-paid"
                    onClick={e => handleTogglePaid(e, transaction)}
                  />
                )}
              </div>
            </StyledListGroupItem>
          );
        })}
      </ListGroup>
      <div className="mt-3 d-flex flex-column">
        {totals.incomes > 0 && (
          <small>
            {incomesTotalMessage}&nbsp;&nbsp;
            <strong>
              <span className="text-success financial-value">
                {FORMATTERS.NUMBER(totals.incomes)}
              </span>
            </strong>
          </small>
        )}
        {totals.expenses > 0 && (
          <>
            <small>
              {expensesTotalMessage}&nbsp;&nbsp;
              <strong>
                <span className="text-danger financial-value">
                  {FORMATTERS.NUMBER(totals.expenses)}
                </span>
              </strong>
            </small>
          </>
        )}
        {totals.transfers > 0 && (
          <>
            <small>
              {transfersTotalMessage}&nbsp;&nbsp;
              <strong>
                <span className="text-info financial-value">
                  {FORMATTERS.NUMBER(totals.transfers)}
                </span>
              </strong>
            </small>
          </>
        )}
      </div>
    </>
  );
}

TransactionsList.defaultProps = {
  transactions: [],
  emptyMessage: 'Nenhuma transação encontrada',
  incomesTotalMessage: 'Recebimentos do dia:',
  expensesTotalMessage: 'Despesas do dia:',
  transfersTotalMessage: 'Transferências do dia:',
  selectedItems: [],
};

TransactionsList.propTypes = {
  transactions: PropTypes.array,
  onEditTransaction: PropTypes.func,
  onTogglePaid: PropTypes.func,
  emptyMessage: PropTypes.string,
  incomesTotalMessage: PropTypes.string,
  expensesTotalMessage: PropTypes.string,
  transfersTotalMessage: PropTypes.string,
  selectedItems: PropTypes.array,
  onFetchBlockedPeriod: PropTypes.func,
  blockedPeriod: PropTypes.object,
};

export default TransactionsList;
