import constants from '../../_constants/notifications.constants';

const fetchNewUserNotification = notification => {
  return dispatch => {
    dispatch(request(notification));
  };

  function request(notification) {
    return {
      type: constants.FETCH_SINGLE_USER_NOTIFICATION_REQUEST,
      payload: notification,
    };
  }
};

export default fetchNewUserNotification;
