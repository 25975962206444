import HttpService from './http.service';

export default {
  getAll,
  getById,
  insert,
  updateTransaction,
  remove,
  removeMultiple,
  togglePaid,
  getAllAccounts,
  insertRecipient,
  insertCategory,
  getAllCategories,
  getAllRecipients,
  getAllRecipientsSimple,
  getPaginatedRecipients,
  fetchCashflow,
  generateTransactionReport,
  uploadZeropaperSpreadsheet,
  fetchBankAccountBalance,
  removeAllFinancialData,
  getFinancialDataForDeletion,
  addTransactionAccount,
  updateTransactionAccount,
  deleteTransactionAccount,
  deleteCategory,
  updateCategory,
  getAllCostsCenter,
  insertCostCenter,
  updateCostCenter,
  deleteCostCenter,
  getAllTags,
  insertTag,
  updateTag,
  deleteTag,
  fetchSemesterOverview,
  setFavoriteBankAccount,
  createTransferTransaction,
  duplicateTransaction,
  duplicateMultipleTransactions,
  createContact,
  updateContact,
  deleteContact,
  deleteMultipleContacts,
  updateMultipleTransactions,
  uploadBankStatementTransactions,
  fetchBankStatementTransactions,
  fetchBankStatementSummary,
  updateTransactionsDescriptions,
  updateTransactionsTransferDetails,
  confirmBankStatementUpload,
  abortBankStatementUpload,
  createRecurringTransaction,
  updateRecurringTransaction,
  fetchTransactionOverview,
  uploadContactsFromInvoices,
  applyTagMultipleTransactions,
  fetchMonthComparison,
  downloadFinancialData,
  uploadContactsSpreadsheet,
  uploadContactsSpreadsheetConfirm,
  moveTransactions,
  fetchInstalmentsTransactions,
  uploadTransactionFiles,
  fetchTransactionFiles,
  deleteTransactionFiles,
  search,
  fetchContactById,
  getConsumedStorage,
  fetchExpiredTransactions,
  getSuggestions,
  uploadTransactionsSpreadsheet,
  uploadTransactionsSpreadsheetConfirm,
  fetchContactsSummary,
  updateMultipleContacts,
  fetchContactsSummaryDetail,
  exportContacts,
  fetchSplitsFromTransaction,
  createSplitTransaction,
  updateSplitTransaction,
  deleteSplitTransaction,
  archiveBankAccount,
  unarchiveBankAccount,
  uploadPayments,
  uploadPaymentsConfirm,
};

async function getAll(params) {
  return HttpService.get('/company-transactions', params);
}

async function getById(id) {
  return HttpService.get(`/company-transactions/${id}`);
}

async function insert(body) {
  return HttpService.post('/company-transactions', body);
}

// CONTACTS

async function getAllRecipients(parsedParams) {
  return HttpService.get('/contacts', parsedParams);
}

async function getAllRecipientsSimple(parsedParams) {
  return HttpService.get('/contacts-simple', parsedParams);
}

async function getPaginatedRecipients(params) {
  return HttpService.get('/contacts-paginated', params);
}

async function insertRecipient(params) {
  return HttpService.post('/contacts', params);
}

async function deleteMultipleContacts(params) {
  return HttpService.delete('/contacts', params);
}

async function exportContacts(params) {
  return HttpService.post('/export-contacts', params);
}

async function fetchContactById(id) {
  return HttpService.get(`/contacts/${id}`);
}

// CATEGORIES START

async function getAllCategories() {
  return HttpService.get('/categories');
}

async function insertCategory(params) {
  return HttpService.post('/categories', params);
}

async function updateCategory(id, params) {
  return HttpService.put(`/categories/${id}`, params);
}

async function deleteCategory(id) {
  return HttpService.delete(`/categories/${id}`);
}

// CATEGORIES END

// COSTCENTER START

async function getAllCostsCenter() {
  return HttpService.get('/cost-centers');
}

async function insertCostCenter(params) {
  return HttpService.post('/cost-centers', params);
}

async function updateCostCenter(id, params) {
  return HttpService.put(`/cost-centers/${id}`, params);
}

async function deleteCostCenter(id) {
  return HttpService.delete(`/cost-centers/${id}`);
}

// COSTCENTER END

// TAGS START

async function getAllTags() {
  return HttpService.get('/tags');
}

async function insertTag(params) {
  return HttpService.post('/tags', params);
}

async function updateTag(id, params) {
  return HttpService.put(`/tags/${id}`, params);
}

async function deleteTag(id) {
  return HttpService.delete(`/tags/${id}`);
}

async function applyTagMultipleTransactions(params) {
  return HttpService.post('/transaction-tag', params);
}

// TAGS END

async function updateTransaction(id, body) {
  return HttpService.put(`/company-transactions/${id}`, body);
}

async function remove(id, params) {
  return HttpService.delete(`/company-transactions/${id}`, params);
}

async function removeMultiple(params) {
  return HttpService.delete('/multiple-company-transactions', params);
}

async function togglePaid(id, paid) {
  return HttpService.put(`/company-transactions-pay/${id}`, {
    paid,
  });
}

// BANK ACCOUNTS

async function getAllAccounts() {
  return HttpService.get('/bank-accounts');
}

async function setFavoriteBankAccount(id) {
  return HttpService.put(`/bank-accounts-principal/${id}`);
}

async function addTransactionAccount(params) {
  return HttpService.post('/bank-accounts', params);
}

async function updateTransactionAccount(id, params) {
  return HttpService.put(`/bank-accounts/${id}`, params);
}

async function deleteTransactionAccount(id) {
  return HttpService.delete(`/bank-accounts/${id}`);
}

async function archiveBankAccount(id) {
  return HttpService.post(`/bank-account-archive/${id}`);
}

async function unarchiveBankAccount(id) {
  return HttpService.delete(`/bank-account-archive/${id}`);
}

// BANK ACCOUNTS END

async function fetchCashflow(params) {
  return HttpService.get('/company-cashflow', params);
}

async function generateTransactionReport(params) {
  return HttpService.post('/company-transactions-reports', params);
}

async function uploadZeropaperSpreadsheet(formData) {
  return HttpService.upload('/import-spreadsheet', formData);
}

async function fetchBankAccountBalance(params) {
  return HttpService.get('/company-forecast-balance', params);
}

async function getFinancialDataForDeletion() {
  return HttpService.get('/delete-financial-data');
}

async function removeAllFinancialData() {
  return HttpService.delete('/delete-financial-data');
}

async function fetchSemesterOverview(params) {
  return HttpService.get('/semester-overview', params);
}

async function createTransferTransaction(params) {
  return HttpService.post('/transfer', params);
}

async function duplicateTransaction(params) {
  return HttpService.post('/transactions-duplicate', params);
}

async function duplicateMultipleTransactions(params) {
  return HttpService.post('/transactions-duplicate-multiple', params);
}

async function updateTransactionsDescriptions(params) {
  return HttpService.put('/transactions-descriptions', params);
}

async function updateTransactionsTransferDetails(params) {
  return HttpService.put('/transactions-transfer-details', params);
}

async function updateMultipleTransactions(params) {
  return HttpService.put('/transactions-update-multiple', params);
}

async function updateMultipleContacts(params) {
  return HttpService.put('/contacts-update-multiple', params);
}

async function moveTransactions(params) {
  return HttpService.put('/move-transactions', params);
}

async function createContact(params) {
  return HttpService.post('/contacts', params);
}

async function updateContact(id, params) {
  return HttpService.put(`/contacts/${id}`, params);
}

async function deleteContact(id) {
  return HttpService.delete(`/contacts/${id}`);
}

async function uploadBankStatementTransactions(bankStatements) {
  return HttpService.post('/bank-statement-transactions', bankStatements);
}
async function fetchBankStatementTransactions(params) {
  return HttpService.get('/bank-statement-transactions', params);
}

async function fetchBankStatementSummary() {
  return HttpService.get('/bank-statement-summary');
}

async function confirmBankStatementUpload() {
  return HttpService.post('/bank-statement-confirm-upload');
}

async function abortBankStatementUpload() {
  return HttpService.delete('/abort-bank-statement-upload');
}

async function createRecurringTransaction(params) {
  return HttpService.post('/transactions-recurring', params);
}

async function updateRecurringTransaction(params) {
  return HttpService.put('/transactions-recurring', params);
}

async function fetchInstalmentsTransactions(frequency_main_id) {
  return HttpService.get(`/transactions-recurring/${frequency_main_id}`);
}

async function fetchTransactionOverview() {
  return HttpService.get('/transactions-overview');
}

async function uploadContactsFromInvoices(invoices) {
  return HttpService.post('/import-clients', invoices);
}

async function fetchMonthComparison(params) {
  return HttpService.get('/comparison', params);
}

async function downloadFinancialData(params) {
  return HttpService.post('/export-spreadsheet', params);
}

async function uploadContactsSpreadsheet(formData) {
  return HttpService.upload('/import-contacts-summary', formData);
}

async function uploadContactsSpreadsheetConfirm(formData) {
  return HttpService.upload('/import-contacts-confirm', formData);
}

async function uploadTransactionFiles(formData, transaction_id) {
  return HttpService.upload(`/transaction-files/${transaction_id}`, formData);
}

async function fetchTransactionFiles(transaction_id) {
  return HttpService.get(`/transaction-files/${transaction_id}`);
}

async function deleteTransactionFiles(file_id) {
  return HttpService.delete(`/transaction-files/${file_id}`);
}

async function search(params) {
  return HttpService.get('/search-transactions', params);
}

async function getConsumedStorage() {
  return HttpService.get('/transaction-files-consumed');
}

async function fetchExpiredTransactions(params) {
  return HttpService.get('/expired-transactions', params);
}

async function getSuggestions(params) {
  return HttpService.get('/suggestions', params);
}

async function uploadTransactionsSpreadsheet(formData) {
  return HttpService.upload('/import-spreadsheet-summary', formData);
}

async function uploadTransactionsSpreadsheetConfirm(formData) {
  return HttpService.upload('/import-spreadsheet-confirm', formData);
}

async function fetchContactsSummary() {
  return HttpService.get('/contacts-summary');
}

async function fetchContactsSummaryDetail(params) {
  return HttpService.get('/contacts-summary-detail', params);
}

async function fetchSplitsFromTransaction(id) {
  return HttpService.get(`/transaction-splits/${id}`);
}

async function deleteSplitTransaction(id) {
  return HttpService.delete(`/transaction-splits/${id}`);
}

async function updateSplitTransaction(id, params) {
  return HttpService.put(`/transaction-splits/${id}`, params);
}

async function createSplitTransaction(params) {
  return HttpService.post('/transaction-splits', params);
}

async function uploadPayments(params) {
  return HttpService.post('/payments-file', params);
}

async function uploadPaymentsConfirm(params) {
  return HttpService.post('/payments-file-confirm', params);
}
