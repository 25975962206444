import HttpService from './http.service';

export default {
  getAll,
  deleteKey,
  createKey,
  updateKey,
};

async function getAll() {
  return HttpService.get('/api-keys');
}

async function deleteKey(id) {
  return HttpService.delete(`/api-keys/${id}`);
}

async function createKey(values) {
  return HttpService.post('/api-keys', values);
}

async function updateKey(values) {
  return HttpService.put(`/api-keys/${values.id}`, values);
}
