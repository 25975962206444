import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const fetchAllRecipientsSimple = (filters, callback) => {
  return async dispatch => {
    dispatch(request(filters));

    try {
      const response = await service.getAllRecipientsSimple(filters);

      dispatch(success(response.data));

      if (callback) {
        callback(response.data);
      }
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request(filters) {
    return {
      type: constants.FETCH_ALL_RECIPIENTS_REQUEST,
      payload: { filters },
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_ALL_RECIPIENTS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_ALL_RECIPIENTS_FAILURE,
      payload: error,
    };
  }
};

export default fetchAllRecipientsSimple;
