import constants from '../../_constants/files.constants';
import service from '../../../services/files.service';

const getNewConsumedStorage = () => {
  return async dispatch => {
    dispatch(request());

    service
      .getNewConsumedStorage()
      .then(response => {
        dispatch(success(response));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.GET_NEW_CONSUMED_STORAGE_REQUEST,
      payload: {},
    };
  }
  function success(response) {
    return {
      type: constants.GET_NEW_CONSUMED_STORAGE_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.GET_NEW_CONSUMED_STORAGE_FAILURE,
      payload: error,
    };
  }
};

export default getNewConsumedStorage;
