import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { FastFilterSelect } from '_components/_core';
import { isFeatureEnabled } from 'helpers';

function FileTypeField({ user }) {
  const formik = useFormikContext();

  const handlePeriodChange = useCallback(
    option => {
      formik.setFieldValue('file_type', option);
    },
    [formik],
  );

  const options = useMemo(() => {
    const defaultOptions = [{ value: 'transaction', label: 'Arquivos de Transações' }];

    if (isFeatureEnabled('contact_files', user.flags)) {
      defaultOptions.push({ value: 'contact', label: 'Arquivos de Contatos' });
    }

    return defaultOptions;
  }, [user]);

  return (
    <>
      <FastFilterSelect
        id="file_type"
        label={null}
        value={formik.values.file_type || 'transaction'}
        options={options}
        onChange={handlePeriodChange}
        isChanged
        className="mr-3"
      />
    </>
  );
}

export default FileTypeField;
