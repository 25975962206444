import { contactsConstants } from '_store/_constants';

const clearContactFiles = () => {
  return (dispatch) => {
    dispatch({
      type: contactsConstants.CLEAR_CONTACT_FILES,
    });
  };
};

export default clearContactFiles;
