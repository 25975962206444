import constants from '../../_constants/notifications.constants';
import service from '../../../services/notifications.service';

const readManyNotifications = (ids, callback) => {
  return dispatch => {
    dispatch(request(ids));

    service
      .readManyNotifications(ids)
      .then(response => {
        dispatch(success(ids));

        if (callback) {
          callback(ids);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request(ids) {
    return {
      type: constants.READ_MANY_USER_NOTIFICATIONS_REQUEST,
      payload: ids,
    };
  }

  function success(ids) {
    return {
      type: constants.READ_MANY_USER_NOTIFICATIONS_SUCCESS,
      payload: { ids },
    };
  }

  function failure(error) {
    return {
      type: constants.READ_MANY_USER_NOTIFICATIONS_FAILURE,
      payload: error,
    };
  }
};

export default readManyNotifications;