import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { LuMailOpen, LuTrash2 } from 'react-icons/lu';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ItemsPerPage, Pagination } from '_components/_core/Table/components';
import { useTableV2 } from '_components/_core/Table/utils';
import { LoadingIcon } from '_components/_shared';
import { Button, EmptyMessage, Modal } from '_components/_core';

import {
  CustomCardHeader,
  CustomCard,
  CustomCardBody,
  StyledTable,
  ModalFooter,
  NotificationButton,
} from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'due_date', order: 'desc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Notifications({
  onFetchNotifications,
  onReadNotification,
  onArchiveNotification,
  onReadAllNotifications,
  onUnreadNotification,
  onUnreadAllNotifications,
  notifications,
  isLoading,
  total,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    onFetchNotifications();
  }, [onFetchNotifications]);

  const { pagination, onPageChange, onPageSizeChange } = useTableV2({
    ...tableConfig,
    data: notifications,
    onFetchData: onFetchNotifications,
    withSavedFilters: false,
    withInitialLoading: true,
  });

  const groupedByDay = useMemo(() => {
    const grouped = {};

    notifications.forEach(notification => {
      const date = DateTime.fromISO(notification.send_at).toFormat('yyyy-MM-dd');

      if (!grouped[date]) {
        grouped[date] = [];
      }

      grouped[date].push(notification);
    });

    return grouped;
  }, [notifications]);

  const hasUnread = useMemo(
    () => notifications.some(notification => !notification.read_at),
    [notifications],
  );

  const hasRead = useMemo(
    () => notifications.some(notification => notification.read_at),
    [notifications],
  );

  const handleRowClick = notification => {
    if (notification.redirect_url) {
      window.location.href = notification.redirect_url;
    } else {
      setSelectedNotification(notification);
      setIsVisible(true);
    }
  };

  const renderModalFooter = useCallback(
    () => (
      <ModalFooter>
        <Button variant="secondary" onClick={() => setIsVisible(false)}>
          Fechar
        </Button>
      </ModalFooter>
    ),
    [],
  );

  const handleReadArchiveNotification = (notificationId, action, read_at) => {
    if (action === 'read') {
      if (!read_at) {
        onReadNotification(notificationId);
      } else {
        onUnreadNotification(notificationId);
      }
    } else {
      onArchiveNotification(notificationId);
    }
  };

  const handleMarkAllAsRead = notifications => {
    const ids = notifications.map(notification => notification.id);
    onReadAllNotifications(ids, () => onFetchNotifications());
  };

  const handleMarkAllAsUnread = notifications => {
    const ids = notifications.map(notification => notification.id);
    onUnreadAllNotifications(ids, () => onFetchNotifications());
  };

  const NotificationModal = ({ isVisible, toggleModal, notification }) => (
    <Modal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title={notification?.title}
      footer={renderModalFooter()}
    >
      {notification?.content}
    </Modal>
  );

  return (
    <>
      <Container>
        <Row>
          <Col>
            <CustomCard>
              <CustomCardHeader>
                <h3>Notificações</h3>
                <div className="ml-auto d-flex">
                  {!isEmpty(notifications) && hasRead && (
                    <div>
                      <Button
                        variant="white"
                        size="sm"
                        className="notification-button d-flex align-items-center justify-content-center p-0 pl-2 pr-2 pt-1 pb-1"
                        data-tip="Arquivar"
                        data-place="bottom"
                        onClick={() => {
                          handleMarkAllAsUnread(notifications);
                        }}
                        disabled={isLoading}
                      >
                        Marcar {notifications.length} como não lidas
                      </Button>
                    </div>
                  )}
                  {!isEmpty(notifications) && hasUnread && (
                    <div className="ml-2">
                      <Button
                        variant="white"
                        size="sm"
                        className="notification-button d-flex align-items-center justify-content-center p-0 pl-2 pr-2 pt-1 pb-1"
                        data-tip="Arquivar"
                        data-place="bottom"
                        onClick={() => {
                          handleMarkAllAsRead(notifications);
                        }}
                        disabled={isLoading}
                      >
                        Marcar {notifications.length} como lidas
                      </Button>
                    </div>
                  )}
                </div>
              </CustomCardHeader>
              <CustomCardBody noPadding>
                {isEmpty(notifications) && isLoading && (
                  <LoadingIcon text="Carregando..." className="mt-5 mb-5" />
                )}
                {!isEmpty(notifications) && (
                  <StyledTable className="table table-hover">
                    <tbody>
                      {Object.keys(groupedByDay).map(date => (
                        <Fragment key={date}>
                          <tr>
                            <td colSpan={3}>
                              <strong>
                                {DateTime.fromISO(date)
                                  .setLocale('pt-BR')
                                  .toFormat('dd MMMM yyyy')}
                              </strong>
                            </td>
                          </tr>
                          {groupedByDay[date].map(notification => {
                            return (
                              <tr
                                key={notification.id}
                                onClick={() => handleRowClick(notification)}
                              >
                                <td width="75%" className="text-left">
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <span
                                        className={classNames({
                                          'font-weight-bold': !notification.read_at,
                                        })}
                                      >
                                        {notification.title}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td width="15%" className="text-center">
                                  <div className="d-flex justify-content-end">
                                    <NotificationButton
                                      variant="white"
                                      size="sm"
                                      className="notification-button"
                                      onClick={e => {
                                        e.stopPropagation();

                                        handleReadArchiveNotification(
                                          notification.id,
                                          'read',
                                          notification.read_at,
                                        );
                                      }}
                                    >
                                      <LuMailOpen />
                                    </NotificationButton>
                                    <NotificationButton
                                      variant="white"
                                      size="sm"
                                      className="notification-button ml-1"
                                      onClick={e => {
                                        e.stopPropagation();

                                        handleReadArchiveNotification(
                                          notification.id,
                                          'archive',
                                        );
                                      }}
                                    >
                                      <LuTrash2 />
                                    </NotificationButton>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ))}
                    </tbody>
                  </StyledTable>
                )}
                {isEmpty(notifications) && !isLoading && (
                  <EmptyMessage title="Nenhuma notificação encontrada" />
                )}
              </CustomCardBody>
            </CustomCard>
          </Col>
        </Row>
        <Row>
          <Col xs={7} className="d-flex justify-content-start">
            <ItemsPerPage
              itemsPerPage={pagination.itemsPerPage}
              onChange={onPageSizeChange}
              noMarginsOnTotals
              className="mr-3"
              total={total}
              totalBeingShown={notifications.length}
              maxItemsPerPage={100}
            />
          </Col>
          <Col xs={5} className="d-flex justify-content-end">
            <Pagination {...pagination} total={total} onPageChange={onPageChange} />
          </Col>
        </Row>
      </Container>
      <NotificationModal
        isVisible={isVisible}
        toggleModal={() => setIsVisible(false)}
        notification={selectedNotification}
      />
    </>
  );
}

Notifications.defaultProps = {
  payments: [],
  activeCompany: {},
  notifications: [],
  isLoading: false,
  withBreadcrumb: false,
};

Notifications.propTypes = {
  payments: PropTypes.array,
  onFetchNotifications: PropTypes.func,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  subscriptionInvoices: PropTypes.object,
  isLoading: PropTypes.bool,
  withBreadcrumb: PropTypes.bool,
  originView: PropTypes.string,
  total: PropTypes.number,
};

export default Notifications;
