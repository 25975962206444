import confirmDialog from 'helpers/confirmDialog';

import constants from '../../../_constants/company-transactions.constants';
import service from '../../../../services/company-transactions.service';

const exportContacts = (filters, callback) => {
  return async dispatch => {
    confirmDialog.warning({
      title: 'Exportar Contatos',
      message:
        'Ao confirmar, todos os contatos serão exportados. Este processo pode levar alguns minutos. Deseja continuar?',
      confirmButtonText: 'Sim, exportar!',
      confirmButtonColor: '#4CCEAC',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(filters));

        service
          .exportContacts(filters)
          .then(response => {
            const { data } = response;

            dispatch(success(data));

            if (callback) {
              callback(data);
            }
          })
          .catch(error => {
            dispatch(failure(error));
          });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.EXPORT_CONTACTS_SPREADSHEET_REQUEST,
      payload: { filters },
    };
  }
  function success(payload) {
    return {
      type: constants.EXPORT_CONTACTS_SPREADSHEET_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: constants.EXPORT_CONTACTS_SPREADSHEET_FAILURE,
      payload: error,
    };
  }
};

export default exportContacts;
