import { connect } from 'react-redux';
import { compose } from 'redux';

import Admin from './Admin';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  user: state.auth.user,
});

export default compose(connect(mapStateToProps, null))(Admin);
