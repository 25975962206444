import { isEmpty, round } from 'lodash';
import { useState, useMemo } from 'react';

import { getSimpleUuid as uuid } from 'helpers';

const useSplitTransaction = ({
  total_amount,
  transaction_id,
  recipient_id,
  category_id,
  account_id,
}) => {
  const [splits, setSplits] = useState([]);
  const [forceNewAmount, setForceNewAmount] = useState(false);

  const splits_total_amount = useMemo(() => {
    const total = splits.reduce((acc, split) => acc + split.amount, 0);

    return round(total, 2);
  }, [splits]);

  const splits_total_amount_difference = useMemo(
    () => total_amount - splits_total_amount,
    [total_amount, splits_total_amount],
  );

  const handleCreateDefaultSplits = (
    event_date,
    description = '',
    setFieldValue = () => {},
  ) => {
    if (isEmpty(splits)) {
      const created_splits = [];

      for (let i = 1; i <= 2; i++) {
        created_splits.push({
          id: uuid(),
          amount: 0,
          event_date,
          paid: false,
          description,
          transaction_id,
          recipient_id,
          category_id,
          account_id,
          frequency_number: i,
        });
      }

      setSplits(created_splits);
      setFieldValue('splits', created_splits);

      return;
    }

    const copy = [...splits];

    copy.push({
      id: uuid(),
      amount: 0,
      event_date,
      paid: false,
      description,
      transaction_id,
      recipient_id,
      category_id,
      account_id,
      frequency_number: splits.length + 1,
    });

    setSplits(copy);
    setFieldValue('splits', copy);
  };

  const handleCreateSplit = ({
    event_date,
    description,
    amount,
    recipient_id,
    category_id,
    account_id,
  }) => {
    const copy = [...splits];

    const splitWithZeroAmount = splits.find(split => split.amount === 0 || !split.amount);

    if (splitWithZeroAmount) {
      const updated_split = {
        ...splitWithZeroAmount,
        amount,
      };

      const updated_splits = splits.map(split => {
        if (split.id === updated_split.id) {
          return updated_split;
        }

        return split;
      });

      setSplits(updated_splits);

      return;
    }

    copy.push({
      id: uuid(),
      amount,
      event_date,
      description,
      paid: false,
      transaction_id,
      recipient_id,
      category_id,
      account_id,
      frequency_number: splits.length + 1,
    });

    setSplits(copy);
  };

  const handleRemoveSplit = id => {
    const updated_splits = splits
      .filter(split => split.id !== id)
      .map((split, index) => ({
        ...split,
        frequency_number: index + 1,
      }));

    setSplits(updated_splits);
  };

  const handleUpdateSplits = updated_splits => {
    setSplits(updated_splits);
  };

  const handleClearSplits = () => {
    setSplits([]);
  };

  return {
    splits,
    splits_total_amount,
    splits_total_amount_difference,
    forceNewAmount,
    onCreateSplits: handleCreateDefaultSplits,
    onCreateSplit: handleCreateSplit,
    onRemoveSplit: handleRemoveSplit,
    onUpdateSplits: handleUpdateSplits,
    onForceNewAmount: setForceNewAmount,
    onClearSplits: handleClearSplits,
  };
};

export default useSplitTransaction;
