import { connect } from 'react-redux';

import contactFilesActions from '_store/_actions/contactFiles';

import ContactFiles from './ContactFiles';

const mapStateToProps = state => ({
  files: state.contacts.contactFiles,
  isFetchingFiles: state.contacts.isFetchingContactFiles,
});

const mapDispatchToProps = {
  onFetchContactFiles: contactFilesActions.fetchContactFiles,
  onFilesUpload: contactFilesActions.uploadContactFiles,
  onFilesDelete: contactFilesActions.deleteContactFiles,
  onClearContactFiles: contactFilesActions.clearContactFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFiles);
