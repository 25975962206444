import React, { useMemo, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaArrowCircleRight, FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody } from '_components/_core';
import { Report } from '_components/_shared';

import { REPORT_FRIENDLY_NAMES } from '../utilities';
import { ReportContext } from './ReportContext';
import PrintHeader from './PrintHeaderContainer';

import { StyledDRETable } from '../styles';

function DRE({ name, reports, formattedPeriod, account_ids, cost_center_ids }) {
  const { setValue } = useContext(ReportContext);

  const reportRef = useRef();

  useEffect(() => {
    setValue(reportRef);
  }, [setValue]);

  const data = useMemo(() => {
    if (!reports) {
      return {};
    }

    const report = reports[name] || {};
    const { results = {} } = report;

    return results;
  }, [reports, name]);

  const gross_profit_className = useMemo(() => {
    if (data.gross_profit < 0) {
      return 'text-danger';
    }

    return 'text-success';
  }, [data.gross_profit]);

  const operational_profit_className = useMemo(() => {
    if (data.operational_profit < 0) {
      return 'text-danger';
    }

    return 'text-success';
  }, [data.operational_profit]);

  const net_profit_className = useMemo(() => {
    if (data.net_profit < 0) {
      return 'text-danger';
    }

    return 'text-success';
  }, [data.net_profit]);

  const net_profit_label = useMemo(() => {
    if (data.net_profit < 0) {
      return 'Prejuízo Líquido do Exercício';
    }

    return 'Lucro Líquido do Exercício';
  }, [data.net_profit]);

  return (
    <>
      <Report ref={reportRef}>
        <Card>
          <PrintHeader
            title={REPORT_FRIENDLY_NAMES[name].toUpperCase()}
            description={formattedPeriod}
            formattedPeriod={formattedPeriod}
            account_ids={account_ids}
            cost_center_ids={cost_center_ids}
          />
          <CardBody className="p-0 mt-0">
            <StyledDRETable className="table table-hover table-dre">
              <tbody>
                <tr>
                  <td>
                    <FaPlusCircle size="1.1em" className="ml-2 mr-2" />
                    Receita Bruta
                  </td>
                  <td className="text-right text-success font-weight-bold financial-value">
                    {FORMATTERS.NUMBER(data.gross_income)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaMinusCircle size="1.1em" className="ml-2 mr-2" />
                    Impostos
                  </td>
                  <td className="text-right text-danger font-weight-bold financial-value">
                    {FORMATTERS.NUMBER(data.total_taxes * -1)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaArrowCircleRight size="1.1em" className="ml-2 ml-5 mr-2" />
                    Lucro Bruto
                  </td>
                  <td
                    className={`text-right ${gross_profit_className} font-weight-bold financial-value`}
                  >
                    {FORMATTERS.NUMBER(data.gross_profit)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaMinusCircle size="1.1em" className="ml-2 mr-2" />
                    Total de Despesas Variáveis
                  </td>
                  <td className="text-right text-danger font-weight-bold financial-value">
                    {FORMATTERS.NUMBER(data.variable_expenses * -1)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaArrowCircleRight size="1.1em" className="ml-2 ml-5 mr-2" />
                    Lucro Operacional
                  </td>
                  <td
                    className={`text-right ${operational_profit_className} font-weight-bold financial-value`}
                  >
                    {FORMATTERS.NUMBER(data.operational_profit)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaMinusCircle size="1.1em" className="ml-2 mr-2" />
                    Total de Despesas Fixas
                  </td>
                  <td className="text-right text-danger font-weight-bold financial-value">
                    {FORMATTERS.NUMBER(data.fixed_expenses * -1)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FaMinusCircle size="1.1em" className="ml-2 mr-2" />
                    Gastos com Pessoal
                  </td>
                  <td className="text-right text-danger font-weight-bold financial-value">
                    {FORMATTERS.NUMBER(data.people_expenses * -1)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong className={net_profit_className}>{net_profit_label}</strong>
                  </td>
                  <td
                    className={`text-right ${net_profit_className} font-weight-bold financial-value`}
                  >
                    <strong>{FORMATTERS.NUMBER(data.net_profit)}</strong>
                  </td>
                </tr>
              </tbody>
            </StyledDRETable>
          </CardBody>
        </Card>
      </Report>
    </>
  );
}

DRE.defaultProps = {
  reports: {},
  formattedPeriod: '',
};

DRE.propTypes = {
  name: PropTypes.string.isRequired,
  reports: PropTypes.object,
  formattedPeriod: PropTypes.string,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
};

export default DRE;
