import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Row, Col } from 'react-bootstrap';
import { FaChevronLeft, FaPencilAlt } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import { Button, Card, CopyToClipboard, Tag } from '_components/_core';
import { LoadingIcon } from '_components/_shared';

import { TYPES_2, TYPES_VARIANT } from '../utilities/constants';
import { StyledLabel, StyledHr } from '../styles';

const ContactDetail = ({
  selectedContact,
  isLoadingContact,
  fullAddress,
  handleEditContact,
  setSelectedContact,
}) => {
  if (isLoadingContact) {
    return <LoadingIcon />;
  }

  if (isEmpty(selectedContact)) {
    return null;
  }

  return (
    <>
      <div className="w-100 d-flex align-items-center justify-content-start mb-3">
        <Button
          variant="link"
          className="p-0 m-0 d-flex align-items-center justify-content-center"
          onClick={() => setSelectedContact(null)}
        >
          <FaChevronLeft className="mr-2" />
          Voltar
        </Button>
      </div>
      <Card className="p-3">
        <Row>
          <Col>
            <div>
              <div className="d-flex flex-column justify-content-center align-items-start">
                <div className="d-flex flex-row align-items-center justify-content-between w-100 mb-3">
                  <div>
                    <Tag
                      className="p-0 pl-2 pr-2 pt-1 pb-1"
                      variant={TYPES_VARIANT[selectedContact.type]}
                    >
                      {TYPES_2[selectedContact.type] || '--'}
                    </Tag>
                    <h3 className="mt-3" style={{ lineBreak: 'anywhere' }}>
                      {selectedContact.name}
                    </h3>
                  </div>
                  <div>
                    <Button
                      variant="inverse-dark"
                      icon={<FaPencilAlt size="1.2em" />}
                      iconPosition="left"
                      onClick={e => handleEditContact(e, selectedContact.id)}
                      className="mr-2"
                      size="sm"
                    >
                      Editar
                    </Button>
                  </div>
                </div>
                <StyledLabel>Detalhes</StyledLabel>
                <StyledHr />
                <div>
                  <div className="mt-2">
                    <StyledLabel>
                      {selectedContact.document_type || 'CPF/CNPJ '}
                    </StyledLabel>
                    <p className="text-muted">
                      <CopyToClipboard
                        text={selectedContact.document_number}
                        emptyText="Sem documento"
                      >
                        {FORMATTERS.CPF_CNPJ(selectedContact.document_number)}
                      </CopyToClipboard>
                    </p>
                  </div>
                  <div>
                    <StyledLabel>E-mail</StyledLabel>
                    <p className="text-muted">
                      <CopyToClipboard
                        text={selectedContact.email}
                        emptyText="Sem e-mail"
                      >
                        <a
                          href={`mailto:${selectedContact.email}`}
                          className="text-muted text-hover-primary"
                        >
                          {selectedContact.email}
                        </a>
                      </CopyToClipboard>
                    </p>
                  </div>
                  <div>
                    <StyledLabel>Telefone</StyledLabel>
                    <p className="text-muted">
                      <CopyToClipboard
                        text={selectedContact.phone_number}
                        emptyText="Sem telefone"
                      >
                        <a
                          href={`tel:${selectedContact.phone_number}`}
                          className="text-muted text-hover-primary"
                        >
                          {selectedContact.phone_number}
                        </a>
                      </CopyToClipboard>
                    </p>
                  </div>
                  <div>
                    <StyledLabel>Endereço</StyledLabel>
                    {selectedContact.address_street && (
                      <address className="mb-2 text-muted">
                        <p className="text-muted d-flex align-items-center">
                          <CopyToClipboard text={fullAddress} emptyText="Sem endereço">
                            {`${selectedContact.address_street} ${selectedContact.address_number} ${selectedContact.address_complement || ''}`}
                          </CopyToClipboard>
                        </p>
                        <p className="text-muted d-flex align-items-center">
                          {`${selectedContact.address_district}`}
                        </p>
                        <p className="text-muted d-flex align-items-center">
                          {`${selectedContact.address_city} - ${selectedContact.address_state}`}
                        </p>
                        <p className="text-muted d-flex align-items-center">
                          {selectedContact.address_zip_code}
                        </p>
                      </address>
                    )}
                  </div>
                  <p className="text-muted">
                    {!selectedContact.address_street && 'Sem endereço'}
                  </p>
                  <div>
                    <StyledLabel>Criado em</StyledLabel>
                    <p className="text-muted">
                      {FORMATTERS.DATE_DDMMYYYYHHMM(selectedContact.created_at)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

ContactDetail.propTypes = {
  selectedContact: PropTypes.object.isRequired,
  isLoadingContact: PropTypes.bool.isRequired,
  fullAddress: PropTypes.string.isRequired,
  handleEditContact: PropTypes.func.isRequired,
  setSelectedContact: PropTypes.func.isRequired,
};

export default ContactDetail;
