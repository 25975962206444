import uploadContactFiles from './uploadContactFiles';
import fetchContactFiles from './fetchContactFiles';
import deleteContactFiles from './deleteContactFiles';
import clearContactFiles from './clearContactFiles';

export default {
  uploadContactFiles,
  fetchContactFiles,
  deleteContactFiles,
  clearContactFiles,
};
