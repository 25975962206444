const DOCUMENT_TYPE_OPTIONS = [
  { value: '', label: 'Qualquer' },
  { value: 'CPF', label: 'CPF' },
  { value: 'CNPJ', label: 'CNPJ' },
];

const CONTACT_TYPE_OPTIONS = [
  {
    value: '',
    label: 'Qualquer',
  },
  {
    value: 'CUSTOMER',
    label: 'Cliente',
  },
  {
    value: 'SUPPLIER',
    label: 'Fornecedor',
  },
  {
    value: 'EMPLOYEE',
    label: 'Funcionário',
  },
];

export { DOCUMENT_TYPE_OPTIONS, CONTACT_TYPE_OPTIONS };
