import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const QuestionContainer = styled.h4`

`;

export const Question = styled.h4`
  
`;

export const StyledTable = styled.table`  
  &&& {
    font-size: 13px;
    font-weight: 400;

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .8rem .75rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: .8rem .75rem;
      vertical-align: middle;
      border-top: 1px solid #e7eaef;
      font-weight: 400;
    }

    tbody tr:first-child td {
      border-top: none;
    }

    tfoot tr td {
      padding: .8rem .75rem;
      vertical-align: middle;
      font-weight: 600;
      border-top: 1px solid #e7eaef;
    }
  }
`;

export const QuestionHeader = styled.div`
  padding: 8px 0;

  h5 {
    margin: 0;
    color: #202427;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const QuestionBody = styled.p`
  font-size: 0.8em;
  line-height: 1.6em;
  margin-top: 8px;
  display: block;
`;

export const TypeItemContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TypeItem = styled.div`
  width: auto;
  padding: 32px 24px;
  background-color: rgb(246, 248, 250);
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: all .26s cubic-bezier(.645,.045,.355,1);

  &:hover {
    cursor: pointer;
    background-color: rgb(57 79 112);
    
    h4, p {
      color: #fff;
    }
  }

  &:not(:first-child) {
    /* margin-left: 16px; */
  }
  
  @media (max-width: 1024px) {
    /* &:not(:first-child) {
      margin-top: 16px;
      margin-left: 0;
    } */
  }
`;

export const TypeItemIcon = styled.img`
  margin-right: 24px;
`;

export const TypeItemContent = styled.div`
  h4 {
    margin: 0;
    font-size: 1em;
  }

  p {
    padding-top: 4px;
    margin: 0;
    color: rgb(91, 112, 139);
    font-size: 0.8em;
  }
`;

export const NumberIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(57 79 112);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const Summary = styled.ul`
  font-weight: 400;
`;

export const TotalFound = styled.h4`
  font-size: 1.5em;
`;

export const StyledUl = styled.ul`
  /* li {
    padding: 8px 0;
  } */

`;
