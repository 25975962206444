import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { FastFilterSelect } from '_components/_core';

function DateTypeField() {
  const formik = useFormikContext();

  const handlePeriodChange = useCallback(
    option => {
      formik.setFieldValue('date_type', option);
    },
    [formik],
  );

  const options = [
    { value: 'upload_date', label: 'Data de Upload' },
    { value: 'transaction_date', label: 'Data da Transação' },
  ];

  return (
    <>
      <FastFilterSelect
        id="date_type"
        label="Pesquisar por:"
        value={formik.values.date_type || 'transaction_date'}
        options={options}
        onChange={handlePeriodChange}
        renderDoubleDots={false}
        renderEmptyText={false}
        triggerStyle={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        hideLabelOnSelect
        isChanged={formik.values.date_type !== 'transaction_date'}
      />
    </>
  );
}

export default DateTypeField;
