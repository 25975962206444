import axios from 'axios';

import { apiConstants } from '../_store/_constants';
import alertActions from '../_store/_actions/alert.actions';
import authService from './auth.service';

class HttpService {
  static redirect = null;
  static alertOptions = {};

  constructor() {
    this.setUpToken();
  }

  setRedirectFunction(redirectFunction) {
    HttpService.redirect = redirectFunction;
  }

  setUpToken() {
    const token = localStorage.getItem('token');
    const product = process.env.REACT_APP_CONTABILLS_APP;

    const service = axios.create({
      baseURL: apiConstants.API_URL,
      headers: { Authorization: `Bearer ${token}`, product },
    });

    service.interceptors.response.use(this.handleSuccess, this.handleError);

    this.service = service;
  }

  setupAlertOptions(options) {
    HttpService.alertOptions = options;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = error => {
    switch (error.response.status) {
      case 401:
        authService.logout();

        window.location.href = '/entrar';
        break;
      case 404:
        // this.redirectTo(document, "/404");
        break;
      case 422: {
        const { message } = error.response.data;

        alertActions.error(message, HttpService.alertOptions);
        break;
      }
      case 303: {
        const { message } = error.response.data;

        alertActions.error(message);

        if (HttpService.redirect) {
          HttpService.redirect('/configuracoes/assinatura');
        }
        break;
      }
      case 429: {
        const { message } = error.response.data;

        alertActions.error(message);
        break;
      }
      default:
        // this.redirectTo(document, "/500");
        break;
    }
    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  async get(path, params) {
    this.setUpToken();

    const response = await this.service.get(path, {
      params,
    });

    return response;
  }

  async post(path, payload) {
    this.setUpToken();
    const response = await this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    });
    return response;
  }

  async put(path, payload) {
    this.setUpToken();
    const response = await this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
    });
    return response;
  }

  async delete(path, payload) {
    this.setUpToken();
    const response = await this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload,
    });
    return response;
  }

  async patch(path, payload, callback) {
    this.setUpToken();
    const response = await this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    });
    return callback(response.status, response.data);
  }

  async upload(path, formData) {
    this.setUpToken();

    const response = await this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: formData,
      headers: {
        ...this.service.headers,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }

  async download(path, params) {
    this.setUpToken();

    const response = await this.service.request({
      method: 'POST',
      url: path,
      responseType: 'blob',
      headers: {
        ...this.service.headers,
      },
      data: params,
    });
    return response;
  }
}

export default new HttpService();
