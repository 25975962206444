import { connect } from 'react-redux';

import notificationsActionCreators from '_store/_actions/notifications';

import Notifications from './Notifications';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  user: state.auth.user,
  isLoading: state.notifications.isLoading,
});

const mapDispatchToProps = {
  onCreateSingleNotification: notificationsActionCreators.createSingleNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
