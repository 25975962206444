import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

function FormTextAreaField({ rows, placeholder, highlightFilled, ...props }) {
  const [field, meta, { setTouched }] = useField(props);

  const error = useMemo(
    () => (meta.touched && meta.error ? meta.error : ''),
    [meta.error, meta.touched],
  );

  const value = useMemo(() => field.value || '', [field.value]);

  const highlightStyle = useMemo(() => {
    const baseStyle = { resize: 'auto' };

    if (highlightFilled && value) {
      return { ...baseStyle, backgroundColor: '#FFFBE6' };
    }

    return baseStyle;
  }, [highlightFilled, value]);

  return (
    <>
      <Form.Control
        as="textarea"
        rows={rows}
        placeholder={placeholder}
        onBlur={setTouched}
        {...field}
        style={highlightStyle}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormTextAreaField.defaultProps = {
  rows: 8,
  highlightFilled: false,
};

FormTextAreaField.propTypes = {
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  highlightFilled: PropTypes.bool,
};

export default FormTextAreaField;
