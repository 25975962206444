import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { IoNotificationsOutline } from 'react-icons/io5';
import { LuMailOpen, LuTrash2 } from 'react-icons/lu';
import { DateTime } from 'luxon';

import { Button } from '_components/_core';

import {
  StyledWrapper,
  StyledOverlayTrigger,
  StyledPopover,
  StyledMenuText,
  NotificationDescription,
  NotificationWrapper,
  NotificationDate,
  NotificationMenuTitle,
  TitleWrapper,
  NotificationFooter,
  StyledCounter,
  NotificationSeparator,
  ActionButton,
  ActionMenu,
  ActionMenuItem,
} from './styles';

function NotificationsDropdown({
  notifications,
  onMarkAsRead,
  onArchive,
  onFetchNotifications,
  total,
  onMarkAsUnread,
}) {
  useEffect(() => {
    onFetchNotifications();
  }, [onFetchNotifications]);

  const history = useHistory();

  const hasUnread = useMemo(
    () => notifications.some(notification => !notification.read_at),
    [notifications],
  );

  const notificationsToDisplay = useMemo(() => {
    const filteredNotifications = notifications.filter(
      notification => !notification.archived_at,
    );
    return filteredNotifications.slice(0, 5);
  }, [notifications]);

  const handleRedirect = useCallback(
    path => {
      history.push(path);

      document.body.click();
    },
    [history],
  );

  // const handleChangeReadStatus = useCallback(id => onMarkAsRead(id), [onMarkAsRead]);

  const handleChangeReadStatus = useCallback(
    (id, read_at) => {
      if (!read_at) {
        onMarkAsRead(id);
      } else {
        onMarkAsUnread(id);
      }
    },
    [onMarkAsRead, onMarkAsUnread],
  );

  const handleArchive = useCallback(id => onArchive(id), [onArchive]);

  const handleViewDetails = useCallback(
    id => {
      onMarkAsRead(id);

      handleRedirect(`/configuracoes/notificacoes?id=${id}&redirect_url=null`);
    },
    [onMarkAsRead, handleRedirect],
  );

  // const handleMarkAllAsRead = useCallback(() => {
  //   const ids = notifications.map(notification => notification.id) || [];

  //   onMarkAllAsRead(ids);
  // }, [notifications, onMarkAllAsRead]);

  // const handleArchiveAll = useCallback(() => {
  //   const ids = notifications.map(notification => notification.id) || [];

  //   onArchiveMany(ids);
  // }, [notifications, onArchiveMany]);

  const popover = useMemo(
    () => (
      <StyledPopover>
        <Popover.Content>
          <TitleWrapper className="mb-2">
            <NotificationMenuTitle>Notificações</NotificationMenuTitle>
          </TitleWrapper>
          {!isEmpty(notificationsToDisplay) && (
            <>
              {notificationsToDisplay.map(notification => (
                <React.Fragment key={notification.id}>
                  <NotificationWrapper>
                    <ActionMenuItem className="d-flex justify-content-start align-items-center w-100">
                      <div style={{ flex: 1 }}>
                        <NotificationDescription isBold={!notification.read_at}>
                          {notification.title}
                        </NotificationDescription>
                        <div
                          style={{ height: '32px' }}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <NotificationDate>
                            {DateTime.fromISO(notification.created_at)
                              .setLocale('pt-BR')
                              .toRelative()}
                          </NotificationDate>
                          <ActionMenu className="d-flex justify-content-end">
                            <ActionButton
                              size="sm"
                              onClick={e => {
                                e.stopPropagation();

                                handleViewDetails(notification.id);
                              }}
                              variant="white"
                            >
                              Ver detalhes
                            </ActionButton>
                            <ActionButton
                              size="sm"
                              className="ml-1"
                              onClick={e => {
                                e.stopPropagation();

                                handleChangeReadStatus(
                                  notification.id,
                                  notification.read_at,
                                );
                              }}
                              variant="white"
                            >
                              <LuMailOpen />
                            </ActionButton>
                            <ActionButton
                              size="sm"
                              className="ml-1"
                              onClick={e => {
                                e.stopPropagation();

                                handleArchive(notification.id);
                              }}
                              variant="white"
                            >
                              <LuTrash2 />
                            </ActionButton>
                          </ActionMenu>
                        </div>
                      </div>
                    </ActionMenuItem>
                  </NotificationWrapper>
                  <NotificationSeparator />
                </React.Fragment>
              ))}
            </>
          )}
          <NotificationFooter>
            {notificationsToDisplay.length === 0 && total === 0 ? (
              <StyledMenuText className="text-muted">Nenhuma notificação</StyledMenuText>
            ) : (
              <StyledCounter>{`${notificationsToDisplay.length} de ${total} notificações`}</StyledCounter>
            )}
            <Button
              variant="link"
              className="m-0 p-0"
              onClick={() => handleRedirect('/configuracoes/notificacoes')}
            >
              Ver tudo
            </Button>
          </NotificationFooter>
        </Popover.Content>
      </StyledPopover>
    ),
    [
      handleRedirect,
      handleChangeReadStatus,
      handleArchive,
      handleViewDetails,
      notificationsToDisplay,
      total,
    ],
  );

  return (
    <StyledOverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
      <StyledWrapper hasUnread={hasUnread}>
        <IoNotificationsOutline size="1.4em" />
      </StyledWrapper>
    </StyledOverlayTrigger>
  );
}

NotificationsDropdown.defaultProps = {
  notifications: [],
};

NotificationsDropdown.propTypes = {
  notifications: PropTypes.array,
  onMarkAsRead: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
};

export default NotificationsDropdown;
