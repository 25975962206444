import React from 'react';
import PropTypes from 'prop-types';

import { FormDateFromToField } from '_components/_core';
import { DateTypeField } from '.';

function FiltersForm({ fileType }) {
  if (fileType === 'contact') {
    return (
      <div className="d-flex align-items-center">
        <FormDateFromToField
          label={null}
          fromName="start_date"
          fromPlaceholder="De:"
          fromStyle={{
            width: '108px',
            textAlign: 'center',
          }}
          fromPlacement="bottom-start"
          toStyle={{
            width: '108px',
            textAlign: 'center',
          }}
          toName="end_date"
          toPlaceholder="Até:"
          toPlacement="bottom-end"
        />
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <FormDateFromToField
        label={null}
        leftContent={<DateTypeField />}
        fromName="start_date"
        fromPlaceholder="De:"
        fromStyle={{
          width: '108px',
          textAlign: 'center',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderLeft: '0px',
        }}
        fromPlacement="bottom-start"
        toStyle={{
          width: '108px',
          textAlign: 'center',
        }}
        toName="end_date"
        toPlaceholder="Até:"
        toPlacement="bottom-end"
      />
    </div>
  );
}

FiltersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showDateTypeField: PropTypes.bool,
  fileType: PropTypes.string.isRequired,
};

export default FiltersForm;
