export const filesConstants = {
  FILES_GET_REQUEST: 'FILES_GET_REQUEST',
  FILES_GET_SUCCESS: 'FILES_GET_SUCCESS',
  FILES_GET_FAILURE: 'FILES_GET_FAILURE',

  GET_NEW_CONSUMED_STORAGE_REQUEST: 'GET_NEW_CONSUMED_STORAGE_REQUEST',
  GET_NEW_CONSUMED_STORAGE_SUCCESS: 'GET_NEW_CONSUMED_STORAGE_SUCCESS',
  GET_NEW_CONSUMED_STORAGE_FAILURE: 'GET_NEW_CONSUMED_STORAGE_FAILURE',
};

export default filesConstants;
