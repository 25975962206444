import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'react-bootstrap';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import {
  AGE_COLOR_INCOME,
  AGE_COLOR_EXPENSE,
  AGE_TRANSLATION,
  SUMMARY_CONFIG_INCOME,
  SUMMARY_CONFIG_EXPENSE,
} from '../utilities/constants';

import { Circle, StyledProgressBar, SummaryTable, SummaryTable2 } from '../styles';

const ContactSummary = ({
  summary,
  selectedFilter,
  pendingTransactions,
  CARDS_COLUMN_SIZES,
  SUMMARY_TOTAL_INCOME,
  SUMMARY_TOTAL_EXPENSE,
  getItemTotalIncome,
  getItemTotalExpense,
  handleViewTransactions,
}) => {
  return (
    <>
      <Row>
        <Col sm={CARDS_COLUMN_SIZES.LEFT}>
          <Card className="p-3 mb-3">
            <div className="w-100 flex-column d-flex justify-content-between align-items-start">
              <p className="text-success p-0 m-0">Quem está me devendo?</p>
              <h3 className="m-0 mt-2 financial-value">
                {FORMATTERS.NUMBER(SUMMARY_TOTAL_INCOME)}
              </h3>
            </div>
            <StyledProgressBar className="mt-2">
              {summary.incomes.map((item, index) => (
                <StyledProgressBar
                  key={`income${index}`}
                  id={SUMMARY_CONFIG_INCOME[item.age_bracket].id}
                  now={getItemTotalIncome(item)}
                />
              ))}
            </StyledProgressBar>
            <SummaryTable className="mt-3 table table-hover">
              <thead>
                <tr>
                  <th className="text-left">VENCIDO HÁ</th>
                  <th className="text-right">QTDE</th>
                  <th className="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                {summary.incomes.map((summaryItem, index) => (
                  <tr
                    key={`income_${index}`}
                    className={classNames({
                      active:
                        selectedFilter.type === 'INCOME' &&
                        selectedFilter.age_bracket === summaryItem.age_bracket,
                    })}
                    onClick={() => handleViewTransactions(summaryItem, 'INCOME')}
                  >
                    <td className="text-left">
                      <span className="d-flex align-items-center">
                        <Circle color={AGE_COLOR_INCOME[summaryItem.age_bracket]} />
                        {AGE_TRANSLATION[summaryItem.age_bracket]}
                      </span>
                    </td>
                    <td className="text-right">{summaryItem.contacts_count}</td>
                    <td className="text-right financial-value">
                      {FORMATTERS.NUMBER(summaryItem.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </SummaryTable>
          </Card>
        </Col>
        <Col sm={CARDS_COLUMN_SIZES.RIGHT}>
          <Card className="p-3 mb-3">
            <div className="w-100 flex-column d-flex justify-content-between align-items-start">
              <p className="text-danger p-0 m-0">Quem estou devendo?</p>
              <h3 className="m-0 mt-2 financial-value">
                {FORMATTERS.NUMBER(SUMMARY_TOTAL_EXPENSE)}
              </h3>
            </div>
            <StyledProgressBar className="mt-2">
              {summary.expenses.map((item, index) => (
                <StyledProgressBar
                  key={`expense_${index}`}
                  id={SUMMARY_CONFIG_EXPENSE[item.age_bracket].id}
                  now={getItemTotalExpense(item)}
                />
              ))}
            </StyledProgressBar>
            <SummaryTable className="mt-3 table table-hover">
              <thead>
                <tr>
                  <th className="text-left">VENCIDO HÁ</th>
                  <th className="text-right">QTDE</th>
                  <th className="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                {summary.expenses.map((summaryItem, index) => (
                  <tr
                    key={`expense_${index}`}
                    className={classNames({
                      active:
                        selectedFilter.type === 'EXPENSE' &&
                        selectedFilter.age_bracket === summaryItem.age_bracket,
                    })}
                    onClick={() => handleViewTransactions(summaryItem, 'EXPENSE')}
                  >
                    <td className="text-left">
                      <span className="d-flex align-items-center">
                        <Circle color={AGE_COLOR_EXPENSE[summaryItem.age_bracket]} />
                        {AGE_TRANSLATION[summaryItem.age_bracket]}
                      </span>
                    </td>
                    <td className="text-right">{summaryItem.contacts_count}</td>
                    <td className="text-right financial-value">
                      {FORMATTERS.NUMBER(summaryItem.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </SummaryTable>
          </Card>
        </Col>
      </Row>
      {!isEmpty(selectedFilter) && (
        <Row>
          <Col>
            <Card className="p-3 mb-5">
              {selectedFilter && selectedFilter.type === 'INCOME' && (
                <div className="d-flex">
                  <p className="text-success p-0 m-0 mr-1">Quem está me devendo:</p>
                  <p className="p-0 m-0 ml-1 font-weight-bold">
                    {AGE_TRANSLATION[selectedFilter.age_bracket]}
                  </p>
                </div>
              )}
              {selectedFilter && selectedFilter.type === 'EXPENSE' && (
                <div className="d-flex">
                  <p className="text-danger p-0 m-0 mr-1">Quem estou devendo:</p>
                  <p className="p-0 m-0 ml-1 font-weight-bold">
                    {AGE_TRANSLATION[selectedFilter.age_bracket]}
                  </p>
                </div>
              )}
              {isEmpty(pendingTransactions) && (
                <p className="text-muted mt-3">Nenhum contato encontrado</p>
              )}
              {!isEmpty(pendingTransactions) && (
                <SummaryTable2 className="mt-3 table table-hover">
                  <thead>
                    <tr>
                      <th className="text-left">Nome do contato</th>
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingTransactions.map((pendingTransaction, index) => (
                      <tr key={`pending_${index}`}>
                        <td className="text-left">
                          <span className="d-flex align-items-center">
                            {pendingTransaction.name}
                          </span>
                        </td>
                        <td className="text-right financial-value">
                          <b>{FORMATTERS.NUMBER(pendingTransaction.amount)}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="text-left">
                        <strong>Total</strong>
                      </td>
                      <td className="text-right financial-value">
                        <strong>
                          {FORMATTERS.NUMBER(
                            pendingTransactions.reduce(
                              (acc, curr) => acc + curr.amount,
                              0,
                            ),
                          )}
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </SummaryTable2>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

ContactSummary.propTypes = {
  summary: PropTypes.shape({
    incomes: PropTypes.array.isRequired,
    expenses: PropTypes.array.isRequired,
  }).isRequired,
  selectedFilter: PropTypes.object.isRequired,
  pendingTransactions: PropTypes.array.isRequired,
  CARDS_COLUMN_SIZES: PropTypes.object.isRequired,
  SUMMARY_TOTAL_INCOME: PropTypes.number.isRequired,
  SUMMARY_TOTAL_EXPENSE: PropTypes.number.isRequired,
  getItemTotalIncome: PropTypes.func.isRequired,
  getItemTotalExpense: PropTypes.func.isRequired,
  handleViewTransactions: PropTypes.func.isRequired,
};

export default ContactSummary;
