import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';

function FormTextField({
  placeholder,
  disabled,
  autoFocus,
  innerRef,
  onKeyPress,
  onFocus,
  type,
  inputStyleProps,
  className,
  id,
  highlightFilled,
  ...props
}) {
  const [field, meta, { setTouched }] = useField(props);

  const error = useMemo(
    () => (meta.touched && meta.error ? meta.error : ''),
    [meta.error, meta.touched],
  );

  const value = useMemo(() => field.value || '', [field.value]);

  const highlightStyle = useMemo(() => {
    if (highlightFilled && value) {
      return { backgroundColor: '#FFFBE6', ...inputStyleProps };
    }

    return inputStyleProps;
  }, [highlightFilled, value, inputStyleProps]);

  return (
    <>
      <Form.Control
        id={id}
        type={type}
        value={value}
        ref={innerRef}
        placeholder={placeholder}
        onBlur={setTouched}
        disabled={disabled}
        autoComplete="off"
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
        style={highlightStyle}
        onFocus={onFocus}
        className={className}
        {...field}
      />
      {error && <small className="text-danger">{error}</small>}
    </>
  );
}

FormTextField.defaultProps = {
  autoFocus: false,
  innerRef: null,
  type: 'text',
  inputStyleProps: {},
  onKeyPress: null,
  onFocus: null,
  className: null,
  id: null,
  highlightFilled: false,
};

FormTextField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  innerRef: PropTypes.object,
  inputStyleProps: PropTypes.object,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  id: PropTypes.string,
  highlightFilled: PropTypes.bool,
};

export default FormTextField;
