import { filesConstants } from '_store/_constants';
import { companyTransactionsConstants, contactsConstants } from '../_constants';

const INITIAL_STATE = {
  loading: false,
  items: [],
  total: 0,
  newConsumedStorage: {
    limit: {
      bytes: 0,
      kb: 0,
      mb: 0,
      gb: 0,
    },
    consumed: {
      bytes: 0,
      kb: 0,
      mb: 0,
      gb: 0,
    },
    remaining: {
      bytes: 0,
      kb: 0,
      mb: 0,
      gb: 0,
    },
    percentage_used: 0,
  },
};

export function files(state = INITIAL_STATE, action) {
  switch (action.type) {
    case filesConstants.FILES_GET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case filesConstants.FILES_GET_SUCCESS: {
      const { total, data } = action.payload || {};

      return {
        ...state,
        loading: false,
        total,
        items: data,
      };
    }

    case filesConstants.FILES_GET_FAILURE: {
      return {
        ...state,
        items: [],
        loading: false,
      };
    }

    case companyTransactionsConstants.DELETE_TRANSACTION_FILES_SUCCESS: {
      const id = action.payload;
      const items = state.items.filter(file => file.id !== id);

      return {
        ...state,
        items,
        total: state.total - 1,
      };
    }

    case contactsConstants.DELETE_CONTACT_FILES_SUCCESS: {
      const id = action.payload;

      const items = state.items.filter(file => file.id !== id);

      return {
        ...state,
        items,
        total: state.total - 1,
      };
    }

    case filesConstants.GET_NEW_CONSUMED_STORAGE_SUCCESS: {
      return {
        ...state,
        newConsumedStorage: action.payload,
      };
    }

    default:
      return state;
  }
}
