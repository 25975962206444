import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Dropdown } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa';
import { LuDownload, LuTrash, LuView } from 'react-icons/lu';

import FORMATTERS from 'helpers/formatters';
import PermissionsGate from '_components/_shared/PermissionsGate/PermissionsGateContainer';

import FileIcon from './FileIcon';

import {
  IconArea,
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownToggle,
  StyledFile,
  StyledFileList,
  StyledFileName,
} from '../styles';

function FileList({
  isFetching,
  files,
  tempFiles,
  onPreviewFile,
  onDownloadFile,
  onDeleteFile,
  emptyMessage,
  showUploadDate,
  noMarginTop,
}) {
  return (
    <StyledFileList noMarginTop={noMarginTop}>
      {!isEmpty(tempFiles) &&
        tempFiles.map(file => (
          <StyledDropdown className="mt-3" key={file.path}>
            <StyledFile variant="default">
              <FileIcon fileName={file.path} />
              <StyledFileName className="d-flex flex-column">
                <span>{file.path}</span>
                <small className="text-muted mt-1">Não enviado</small>
              </StyledFileName>
            </StyledFile>
            <StyledDropdownToggle
              disabled
              variant="default"
              split
              id={`dropdown-custom-${file.index}`}
            >
              <FaCaretDown />
            </StyledDropdownToggle>
            <Dropdown.Menu className="p-0">
              <StyledDropdownItem eventKey="1" onClick={() => onPreviewFile(file)}>
                <IconArea>
                  <LuView size="1.2em" />
                </IconArea>
                Visualizar
              </StyledDropdownItem>
              <StyledDropdownItem eventKey="2" onClick={() => onDownloadFile(file)}>
                <IconArea>
                  <LuDownload size="1.2em" />
                </IconArea>
                Download
              </StyledDropdownItem>
            </Dropdown.Menu>
          </StyledDropdown>
        ))}

      {!isEmpty(files) &&
        files.map(file => (
          <StyledDropdown key={file.id}>
            <StyledFile variant="white" onClick={() => onPreviewFile(file)}>
              <FileIcon fileName={file.file_name} />
              <StyledFileName className="d-flex flex-column">
                <div className="file-name">{file.file_name}</div>
                <small className="text-muted mt-1 d-flex justify-content-start">
                  <span>
                    {file.file_size && `${(file.file_size / 1000000).toFixed(2)} MB`}
                  </span>
                  {showUploadDate && (
                    <>
                      &nbsp;|&nbsp;
                      <span>
                        Enviado em:&nbsp;{FORMATTERS.DATE_DDMMYYYYHHMMSS(file.created_at)}
                      </span>
                    </>
                  )}
                </small>
              </StyledFileName>
            </StyledFile>
            <StyledDropdownToggle
              variant="default"
              split
              id={`dropdown-custom-${file.id}`}
            >
              <FaCaretDown />
            </StyledDropdownToggle>
            <Dropdown.Menu className="p-0">
              <StyledDropdownItem eventKey="1" onClick={() => onPreviewFile(file)}>
                <IconArea>
                  <LuView size="1.2em" />
                </IconArea>
                Visualizar
              </StyledDropdownItem>
              <StyledDropdownItem eventKey="2" onClick={() => onDownloadFile(file)}>
                <IconArea>
                  <LuDownload size="1.2em" />
                </IconArea>
                Download
              </StyledDropdownItem>
              <PermissionsGate permissions={['file_delete']} type="all">
                <StyledDropdownItem
                  eventKey="3"
                  onClick={() => onDeleteFile(file.id)}
                  className="text-danger"
                >
                  <IconArea>
                    <LuTrash size="1.2em" />
                  </IconArea>
                  Excluir
                </StyledDropdownItem>
              </PermissionsGate>
            </Dropdown.Menu>
          </StyledDropdown>
        ))}

      {isEmpty(files) && isEmpty(tempFiles) && !isFetching && (
        <div className="mt-3 text-center">
          <span className="text-muted">{emptyMessage}</span>
        </div>
      )}
    </StyledFileList>
  );
}

FileList.propTypes = {
  files: PropTypes.array,
  tempFiles: PropTypes.array,
  onPreviewFile: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string,
  showUploadDate: PropTypes.bool,
  isFetching: PropTypes.bool,
  noMarginTop: PropTypes.bool,
};

FileList.defaultProps = {
  files: [],
  tempFiles: [],
  emptyMessage: 'Nenhum arquivo anexado.',
  showUploadDate: true,
  isFetching: false,
  noMarginTop: false,
};

export default FileList;
