import React, { useMemo, useRef, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaCheck, FaExclamationTriangle } from 'react-icons/fa';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody } from '_components/_core';
import { Report } from '_components/_shared';

import { REPORT_FRIENDLY_NAMES } from '../../utilities';
import { ReportContext } from '../ReportContext';
import PieChart from '../PieChartReport/PieChart';
import PrintHeader from '../PrintHeaderContainer';

import { OverviewSection } from './components';
import { StyledTable } from '../../styles';

function PerformanceReport({
  name,
  formattedPeriod,
  reports,
  account_ids,
  cost_center_ids,
  accounts,
  balance,
  onFetchBankAccountBalance,
  isMobile,
}) {
  const { setValue } = useContext(ReportContext);

  const reportRef = useRef();

  useEffect(() => {
    setValue(reportRef);
  }, [setValue]);

  useEffect(() => {
    if (!accounts || isEmpty(accounts)) {
      return;
    }

    onFetchBankAccountBalance({
      account_ids: accounts.map(account => account.id),
    });
  }, [account_ids, accounts, onFetchBankAccountBalance]);

  const data = useMemo(() => {
    if (!reports) {
      return {};
    }

    const report = reports[name] || {};
    const {
      results = {
        comparison: {
          CURRENT: {
            INCOME: 0,
            EXPENSE: 0,
            FIXED_EXPENSE: 0,
            VARIABLE_EXPENSE: 0,
            PEOPLE: 0,
            TAXES: 0,
          },
          PREVIOUS: {
            INCOME: 0,
            EXPENSE: 0,
            FIXED_EXPENSE: 0,
            VARIABLE_EXPENSE: 0,
            PEOPLE: 0,
            TAXES: 0,
          },
          DIFFERENCE: {
            INCOME: 0,
            EXPENSE: 0,
            FIXED_EXPENSE: 0,
            VARIABLE_EXPENSE: 0,
            PEOPLE: 0,
            TAXES: 0,
          },
        },
      },
    } = report;

    return results;
  }, [reports, name]);

  const getPercentageComparison = useCallback(
    key => {
      if (!data) {
        return null;
      }

      if (!data.comparison) {
        return null;
      }

      const { PREVIOUS, DIFFERENCE } = data.comparison;

      const current = DIFFERENCE[key];

      let label = null;
      let className = null;
      let percentage = null;
      let icon = null;

      if (current === 0) {
        label = 'Manteve';
        className = 'text-success';
        icon = <FaCheck className="mr-2" />;
      } else {
        label = current > 0 ? 'Aumentou' : 'Reduziu';

        if (key === 'INCOME') {
          className = current > 0 ? 'text-success' : 'text-yellow';
          icon =
            current > 0 ? (
              <FaCheck className="mr-2" />
            ) : (
              <FaExclamationTriangle className="mr-2" />
            );
        } else {
          className = current > 0 ? 'text-yellow' : 'text-success';
          icon =
            current > 0 ? (
              <FaExclamationTriangle className="mr-2" />
            ) : (
              <FaCheck className="mr-2" />
            );
        }

        const calculated = PREVIOUS[key] === 0 ? 1 : current / PREVIOUS[key];

        percentage = FORMATTERS.PERCENTAGE_CALCULATED(
          calculated < 0 ? calculated * -1 : calculated,
        );
      }

      return (
        <span className={`${className} d-flex justify-content-start align-items-center`}>
          {icon}
          {`${label} ${percentage || ''}`}
        </span>
      );
    },
    [data],
  );

  const expensesByType = useMemo(() => {
    if (!data) {
      return [];
    }

    const report = data.expenses_by_type || {};
    const { result = [], result_other = [] } = report;

    const series = result.map(item => [item.description, item.amount]);
    const other_total = result_other.reduce((acc, item) => acc + item.amount, 0);

    if (other_total > 0) {
      series.push(['Outros', other_total]);
    }

    return series;
  }, [data]);

  const incomesByCategory = useMemo(() => {
    if (!data) {
      return [];
    }

    const report = data.incomes_by_category || {};
    const { result = [], result_other = [] } = report;

    const series = result.map(item => [item.description, item.amount]);
    const other_total = result_other.reduce((acc, item) => acc + item.amount, 0);

    if (other_total > 0) {
      series.push(['Outros', other_total]);
    }

    return series;
  }, [data]);

  const accountsWithBalance = useMemo(() => {
    if (!accounts || isEmpty(accounts)) {
      return [];
    }

    return accounts.map(account => ({
      ...account,
      balance: balance[account.id] ? balance[account.id].balance : 0,
    }));
  }, [accounts, balance]);

  const totalBalance = useMemo(
    () => accountsWithBalance.reduce((acc, account) => acc + account.balance, 0),
    [accountsWithBalance],
  );

  if (!data) {
    return null;
  }

  return (
    <Report ref={reportRef}>
      <Card>
        <PrintHeader
          title={REPORT_FRIENDLY_NAMES[name].toUpperCase()}
          description={formattedPeriod}
          formattedPeriod={formattedPeriod}
          account_ids={account_ids}
          cost_center_ids={cost_center_ids}
        />
        <CardBody className="p-3 mt-0 h-100 w-100">
          <OverviewSection data={data} isMobile={isMobile} />
          <h4 className="mt-3 ml-2">Receitas por categoria e despesas por tipo:</h4>
          <Row>
            <Col xs={12} sm={6} xl={6}>
              <PieChart
                data={incomesByCategory}
                colorType="INCOME"
                size="SMALL"
                isMobile={isMobile}
              />
            </Col>
            <Col xs={12} sm={6} xl={6}>
              <PieChart
                data={expensesByType}
                colorType="EXPENSE"
                size="SMALL"
                isMobile={isMobile}
              />
            </Col>
          </Row>
          {/* <div className="d-flex justify-content-between align-items-center"> */}
          <h4 className="ml-2 mb-0">
            Movimentações em {FORMATTERS.NORMAL_CASE(data.comparison.CURRENT_PERIOD_NAME)}
          </h4>

          {/*  */}
          {/* </div> */}
          <StyledTable className="table mt-3">
            <thead>
              <tr>
                <th width="55%">
                  <span>Recebimentos</span>
                </th>
                <th>Valor</th>
                <th>Progresso*</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-success">Receitas</span>
                </td>
                <td>
                  <span className="text-success financial-value">
                    {FORMATTERS.NUMBER(data.comparison.CURRENT.INCOME)}
                  </span>
                </td>
                <td className="financial-value">{getPercentageComparison('INCOME')}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>
                  <span>Despesas por Tipo</span>
                </th>
                <th>Valor</th>
                <th>Progresso*</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-danger">Despesas Fixas</span>
                </td>
                <td>
                  <span className="text-danger financial-value">
                    {FORMATTERS.NUMBER(data.comparison.CURRENT.FIXED_EXPENSE)}
                  </span>
                </td>
                <td className="financial-value">
                  {getPercentageComparison('FIXED_EXPENSE')}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-danger">Despesas variáveis</span>
                </td>
                <td>
                  <span className="text-danger financial-value">
                    {FORMATTERS.NUMBER(data.comparison.CURRENT.VARIABLE_EXPENSE)}
                  </span>
                </td>
                <td className="financial-value">
                  {getPercentageComparison('VARIABLE_EXPENSE')}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-danger">Pessoal</span>
                </td>
                <td>
                  <span className="text-danger financial-value">
                    {FORMATTERS.NUMBER(data.comparison.CURRENT.PEOPLE)}
                  </span>
                </td>
                <td className="financial-value">{getPercentageComparison('PEOPLE')}</td>
              </tr>
              <tr>
                <td>
                  <span className="text-danger">Impostos</span>
                </td>
                <td>
                  <span className="text-danger financial-value">
                    {FORMATTERS.NUMBER(data.comparison.CURRENT.TAXES)}
                  </span>
                </td>
                <td className="financial-value">{getPercentageComparison('TAXES')}</td>
              </tr>
            </tbody>
          </StyledTable>
          <small className="mt-2 text-muted float-right">
            * Progresso calculado em relação ao{' '}
            {name === 'yearly_performance' ? 'ano' : 'mês'} anterior
          </small>
          <h4 className="ml-2 mt-4">Saldo das contas</h4>
          <StyledTable className="table mt-3">
            <thead>
              <tr>
                <th width="80%">
                  <span>Conta</span>
                </th>
                <th width="20%">Saldo</th>
              </tr>
            </thead>
            <tbody>
              {orderBy(accountsWithBalance, ['description'], ['asc']).map(account => (
                <tr>
                  <td>
                    <span
                      className={account.balance >= 0 ? 'text-success' : 'text-danger'}
                    >
                      {account.description}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        account.balance === 0
                          ? 'text-success financial-value'
                          : 'financial-value'
                      }
                    >
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(account.balance)}
                    </span>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <span className={totalBalance >= 0 ? 'text-success' : 'text-danger'}>
                    <strong>Saldo total</strong>
                  </span>
                </td>
                <td>
                  <span>
                    <strong className="financial-value">
                      {FORMATTERS.REPORT_BALANCE_AMOUNT(totalBalance)}
                    </strong>
                  </span>
                </td>
              </tr>
            </tbody>
          </StyledTable>
          <small className="mt-2 text-muted float-right">
            Posição das contas em {DateTime.local().toFormat('dd/MM/yyyy HH:mm')}
          </small>
        </CardBody>
      </Card>
    </Report>
  );
}
PerformanceReport.defaultProps = {
  reports: {},
  formattedPeriod: '',
  accounts: [],
  balance: {},
  isMobile: false,
};

PerformanceReport.propTypes = {
  formattedPeriod: PropTypes.string,
  name: PropTypes.string.isRequired,
  reports: PropTypes.object,
  account_ids: PropTypes.array,
  cost_center_ids: PropTypes.array,
  accounts: PropTypes.array,
  balance: PropTypes.object,
  onFetchBankAccountBalance: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default PerformanceReport;
