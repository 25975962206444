import styled, { css } from 'styled-components';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Dropdown, Form, ListGroup } from 'react-bootstrap';

import { Button } from '_components/_core';

export const StyledTable = styled.table`
  &&& {
    thead th {
      font-size: 13px  !important;
      line-height: 13px  !important;
      padding: 15px 25px  !important;
      position: sticky  !important; 
      top: 0  !important; 
      z-index: 1  !important;
      background-color: var(--th-bg-color)  !important;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        box-shadow: inset 0px 25px 1px -10px #d5dcec;
      }
    }

    tbody {
      background-color: var(--th-bg-color);
    }  
    
    tbody tr td {
      vertical-align: middle;
      line-height: 1.4em;
      height: 25px;
      padding: 15px 25px  !important;
      border-top: 1px solid #D4D7DC;
      font-weight: 400;
    }

    tbody tr.active td {
      background: #eceef1;
    }
  }
`;

export const StyledTransactionsTable = styled.table`  
  &&& {
    thead tr th {
      height: 32px;
      line-height: 32px;
      vertical-align: middle;
      text-align: left;
      /* border-left: 1px solid #D4D7DC; */
      padding: 0 8px 0 9px !important;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #d5dcec;
        /* box-shadow: inset 0px 25px 1px -10px #d5dcec; */
      }
    }

    tbody tr td {
      height: 26px;
      line-height: 26px;
      padding: 9px !important;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      cursor: pointer;
      font-weight: 400;
      cursor: default;
    }

    tfoot tr td {
      height: 26px;
      line-height: 26px;
      padding: 9px !important;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      cursor: pointer;
      font-weight: 600;
      cursor: default;
    }
  }
`;

export const GroupHeader = styled.div`
  padding: 0.5em 1.3em;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: none;
  background-color: #f5f5f5;
  z-index: 980;
  display: flex;
  align-items: center;
  height: 47.5px;

  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #071437;
    margin-bottom: 0;
  }
`;

export const StyledFormCheck = styled(Form.Check)`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8em;

  .form-check-input {
    margin-top: 0;
    margin-left: 0;
    width: 17px !important;
    height: 17px !important;
    z-index: 1;

    &:hover {
      cursor: pointer !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
      background: transparent;
      z-index: 0;
    }
  }
`;

export const StyledListGroup = styled(ListGroup)`

`;

export const StyledListGroupItem = styled(ListGroup.Item)`
  &&& {
    cursor: pointer;
    font-size: 13px;
    left: 13px !important;
    border-top: none !important;
    padding: 0;
    padding-left: 1.5em;
    padding-right: 1.5em;
    

    &.active {
      color: #495057 !important;
      /* font-weight: 600 !important; */
      border-left: 1px solid #2ecc71;
    }

   &:focus {
      color: #495057;
      /* font-weight: 600 !important; */
      border-left: 1px solid #2ecc71;
    }

    &:active {
      color: #495057;
      /* font-weight: 600 !important; */
      border-left: 1px solid #2ecc71;
    }

    &:first-child {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }

    ${({ isSelected }) =>
      isSelected &&
      css`
      background: #eceef1 !important;
    `}

    ${({ isActive }) =>
      isActive &&
      css`
      background: #eceef1 !important;
    `}
  }
`;

export const StyledLabel = styled.div`
  font-weight: 600;
  color: #071437;
  font-size: 14px;
`;

export const TabListGroup = styled(ListGroup)`
  display: grid;
  grid-template-columns: 22% 22% 28% 28%;
  width: 100%;
  // box-shadow only on bottom
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

`;

export const TabListGroupItem = styled(ListGroup.Item)`
  &&& {
    border: none;
    cursor: pointer;
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    font-size: 13px;
    border-bottom: 2px solid #fff;

    &.active {
      color: #495057;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid #2ecc71;
    }

    &:active, &:focus, &:hover {
      color: #495057;
      background-color: transparent;
      border-bottom: 2px solid #2ecc71;
    }

    &:last-child {
      margin-bottom: -1px;
      border-radius: 0px !important;
    }
  }
`;

export const StyledHr = styled.hr`
  margin: 0;
  border: 0;
  border-top: 1px solid rgb(235, 237, 242);
  width: 100%;
  margin-top: 8px;
`;

export const StyledSearchInput = styled(Form.Control)`
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 8px;

  &:focus, &:active, &:hover {
    background-color: transparent;
  }
`;

export const ContactsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 1em;

  li {
    border-bottom: 1px dashed #DBDFE9;   
    color: #4B5675;
    font-weight: 400;
    font-size: 1.025em;
    display: flex;
    padding: 4px 0px;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    span:last-child {
      font-weight: 500;
      color: #071437;
    }
  }
`;

export const SummaryTable = styled.table`
  thead tr th {
    color: #4B5675;
    font-weight: 600;
    font-size: 12.5px;
    text-transform: uppercase;
    padding: 4px 0px;   
  }

  tbody tr td {
    border-bottom: 1px dashed #DBDFE9;   
    color: #4B5675;
    font-weight: 400;
    font-size: 12.5px;
    padding: 8px 0px;
    cursor: pointer;
  }

  tbody tr.active td {
    background: #eceef1;
  }

  tfoot tr td {
    color: #4B5675;
    font-weight: 600;
    font-size: 12.5px;
    text-transform: uppercase;   
    padding-top: 8px;
    border-top: 1px solid #DBDFE9;
  }
`;

export const SummaryTable2 = styled.table`
  thead tr th {
    color: #4B5675;
    font-weight: 600;
    font-size: 12.5px;
    text-transform: uppercase;
    padding: 4px 0px;   
  }

  tbody tr td {
    border-bottom: 1px dashed #DBDFE9;   
    color: #4B5675;
    font-weight: 400;
    font-size: 12.5px;
    padding: 12px 0px;
  }

  tbody tr.active td {
    background: #eceef1;
  }

  tfoot tr td {
    color: #4B5675;
    font-weight: 600;
    font-size: 12.5px;
    text-transform: uppercase;   
    padding: 0px;   
    padding-top: 12px;
    border-top: 1px solid #DBDFE9;
  }
`;

export const ActionBar = styled.div`  
  height: 44.5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 24px;
  color: #071437;
  background-color: #393a3d;
  color: #d4d7dc;

  p {
    font-size: 1em;
    margin: 0;
    padding: 0;

  }
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  border: none;
  background-color: #f8f9fa;
  color: rgb(7, 20, 55) !important;
  
  &:hover {
    color: #fff !important;
  }
`;

export const ProgressContainer = styled.div`

`;

export const StyledProgressBar = styled(ProgressBar)`
  height: 10px;

  & .progress {
  }

  #expense-1 {
    background-color: #FCEDB9;
    border-radius: 0;
  }

  #expense-2 {
    background-color: #FCBD3A;
    border-radius: 0;
  }

  #expense-3 {
    background-color: #FF8F0E;
    border-radius: 0;
  }

  #income-1 {
    background-color: #D7F7C2;
    border-radius: 0;
  }

  #income-2 {
    background-color: #76DF47;
    border-radius: 0;
  }

  #income-3 {
    background-color: #48C404;
    border-radius: 0;
  }
`;

export const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`;

export const StyledTr = styled.tr`
  cursor: pointer;
  background-color: ${props => (props.isActive ? '#eceef1' : 'transparent')};
  
  td {
    color: ${props => (props.isActive ? '#2684FF' : 'inherit')};
    text-decoration: ${props => (props.isActive ? 'dashed underline' : 'none')};
  }

  &:hover {
    background-color: #eceef1;
    td {
      color: #2684FF;
      text-decoration: dashed underline;
    }
  }
`;

export const FiltersCount = styled.span`
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
`;

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  box-shadow: none;
  border: none;
  background-color: #2ecc71;
  border-left: 1px solid #27ae60;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #27ae60;
  }

  &:active {
    background-color: #27ae60 !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: #27ae60 !important;
    box-shadow: none !important;
  }
`;
