import React from 'react';
import PropTypes from 'prop-types';
import { FaEyeSlash, FaEye } from 'react-icons/fa';

import { usePrivacyMode } from 'helpers/PrivacyModeContext';

import { PrivacyButton } from './styles';

const PrivacyModeToggle = ({
  className = '',
  iconSize = 18,
  buttonClassName = 'btn btn-outline-secondary btn-sm',
  ...rest
}) => {
  const { privacyModeEnabled, togglePrivacyMode } = usePrivacyMode();

  return (
    <PrivacyButton
      className={`${buttonClassName} ${className}`}
      onClick={togglePrivacyMode}
      title={privacyModeEnabled ? 'Mostrar valores' : 'Ocultar valores'}
      isActive={privacyModeEnabled}
      {...rest}
    >
      {privacyModeEnabled && (
        <>
          <FaEye size={iconSize} />
        </>
      )}
      {!privacyModeEnabled && (
        <>
          <FaEyeSlash size={iconSize} />
        </>
      )}
    </PrivacyButton>
  );
};

PrivacyModeToggle.propTypes = {
  className: PropTypes.string,
  iconSize: PropTypes.number,
  buttonClassName: PropTypes.string,
  showLabel: PropTypes.bool,
};

export default PrivacyModeToggle;
