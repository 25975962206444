export const notificationConstants = {
  FETCH_USER_NOTIFICATIONS_REQUEST: 'FETCH_USER_NOTIFICATIONS_REQUEST',
  FETCH_USER_NOTIFICATIONS_SUCCESS: 'FETCH_USER_NOTIFICATIONS_SUCCESS',
  FETCH_USER_NOTIFICATIONS_FAILURE: 'FETCH_USER_NOTIFICATIONS_FAILURE',

  READ_USER_NOTIFICATION_REQUEST: 'READ_USER_NOTIFICATION_REQUEST',
  READ_USER_NOTIFICATION_SUCCESS: 'READ_USER_NOTIFICATION_SUCCESS',
  READ_USER_NOTIFICATION_FAILURE: 'READ_USER_NOTIFICATION_FAILURE',

  READ_MANY_USER_NOTIFICATIONS_REQUEST: 'READ_MANY_USER_NOTIFICATIONS_REQUEST',
  READ_MANY_USER_NOTIFICATIONS_SUCCESS: 'READ_MANY_USER_NOTIFICATIONS_SUCCESS',
  READ_MANY_USER_NOTIFICATIONS_FAILURE: 'READ_MANY_USER_NOTIFICATIONS_FAILURE',

  ARCHIVE_USER_NOTIFICATION_REQUEST: 'ARCHIVE_USER_NOTIFICATION_REQUEST',
  ARCHIVE_USER_NOTIFICATION_SUCCESS: 'ARCHIVE_USER_NOTIFICATION_SUCCESS',
  ARCHIVE_USER_NOTIFICATION_FAILURE: 'ARCHIVE_USER_NOTIFICATION_FAILURE',

  ARCHIVE_MANY_USER_NOTIFICATIONS_REQUEST: 'ARCHIVE_MANY_USER_NOTIFICATIONS_REQUEST',
  ARCHIVE_MANY_USER_NOTIFICATIONS_SUCCESS: 'ARCHIVE_MANY_USER_NOTIFICATIONS_SUCCESS',
  ARCHIVE_MANY_USER_NOTIFICATIONS_FAILURE: 'ARCHIVE_MANY_USER_NOTIFICATIONS_FAILURE',

  FETCH_SINGLE_USER_NOTIFICATION_REQUEST: 'FETCH_SINGLE_USER_NOTIFICATION_REQUEST',

  CREATE_SINGLE_USER_NOTIFICATION_REQUEST: 'CREATE_SINGLE_USER_NOTIFICATION_REQUEST',
  CREATE_SINGLE_USER_NOTIFICATION_SUCCESS: 'CREATE_SINGLE_USER_NOTIFICATION_SUCCESS',
  CREATE_SINGLE_USER_NOTIFICATION_FAILURE: 'CREATE_SINGLE_USER_NOTIFICATION_FAILURE',

  UNREAD_USER_NOTIFICATION_REQUEST: 'UNREAD_USER_NOTIFICATION_REQUEST',
  UNREAD_USER_NOTIFICATION_SUCCESS: 'UNREAD_USER_NOTIFICATION_SUCCESS',
  UNREAD_USER_NOTIFICATION_FAILURE: 'UNREAD_USER_NOTIFICATION_FAILURE',

  UNREAD_MANY_USER_NOTIFICATIONS_REQUEST: 'UNREAD_MANY_USER_NOTIFICATIONS_REQUEST',
  UNREAD_MANY_USER_NOTIFICATIONS_SUCCESS: 'UNREAD_MANY_USER_NOTIFICATIONS_SUCCESS',
  UNREAD_MANY_USER_NOTIFICATIONS_FAILURE: 'UNREAD_MANY_USER_NOTIFICATIONS_FAILURE',
};

export default notificationConstants;
