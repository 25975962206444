
import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/files.constants';
import services from '../../../services/files.service';

const fetchFiles = (params = {}) => {
  return (dispatch) => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    services.fetchAllFiles(parsedParams).then(
      (response) => {
        dispatch(success(response.data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  }
};

function request() {
  return {
    type: constants.FILES_GET_REQUEST,
  };
}
function success(data) {
  return {
    type: constants.FILES_GET_SUCCESS,
    payload: data,
  };
}
function failure(error) {
  return {
    type: constants.FILES_GET_FAILURE,
    payload: { error },
  };
}

export default fetchFiles;