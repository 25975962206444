import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const PrivacyButton = styled.button`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  margin-right: 8px;
  background-color: #F9FBFC;
  border: 1px solid #E8E7EA;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 8px !important;

  :hover, :focus {
    background-color: #F9FBFC;
    border: 1px solid #E8E7EA;
    text-decoration: none;
    color: var(--text-primary);

    ${({ isActive }) =>
      isActive &&
      css`
      background-color: ${darken(0.05, '#ffd')};
    `}
  }

  ${({ isActive }) =>
    isActive &&
    css`
    background-color: #ffd;
  `}
`;
