import HttpService from './http.service';

export default {
  fetchUserNotifications,
  readNotification,
  archiveNotification,
  archiveManyNotifications,
  readManyNotifications,
  createSingleNotification,
  unreadNotification,
  unreadManyNotifications,
};

async function fetchUserNotifications(params) {
  return HttpService.get('/notifications', params);
}

async function readNotification(id) {
  return HttpService.put(`/notifications/${id}/read`);
}

async function readManyNotifications(ids) {
  return HttpService.put('/notifications/read-many', ids);
}

async function archiveNotification(id) {
  return HttpService.put(`/notifications/${id}/archive`);
}

async function archiveManyNotifications(ids) {
  return HttpService.put('/notifications/archive-many', ids);
}

async function createSingleNotification(notificationData) {
  return HttpService.post(
    '/admin/notifications/create-single-for-user',
    notificationData,
  );
}

async function unreadNotification(id) {
  return HttpService.put(`/notifications/${id}/unread`);
}

async function unreadManyNotifications(ids) {
  return HttpService.put('/notifications/unread-many', ids);
}
