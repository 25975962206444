import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { LuTrash } from 'react-icons/lu';

import {
  Button,
  FormSelectField,
  FormTextField,
  FloatingCard,
  FormDateFromToField,
} from '_components/_core';
import { FormQuickDateField } from '_components/_shared';

import { DOCUMENT_TYPE_OPTIONS, CONTACT_TYPE_OPTIONS } from './utilities';
import { StyledFooter } from './styles';

const ContactFilters = ({ isOpen, onToggle, onFilter }) => {
  const initialValues = {
    name: '',
    document_type: '',
    document_number: '',
    email: '',
    phone_number: '',
    address_zip_code: '',
    address_street: '',
    address_complement: '',
    address_district: '',
    address_city: '',
    type: '',
    created_at_start: '',
    created_at_end: '',
  };

  const handleSubmit = values => {
    onFilter(values);
    onToggle();
  };

  const handleClear = (resetForm, setValues) => {
    resetForm();
    setValues(initialValues);
    onFilter();
    onToggle();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, values, resetForm, setValues }) => (
        <FloatingCard
          title="Filtrar contatos"
          isVisible={isOpen}
          onToggleVisibility={() => {
            onToggle();
          }}
          footerContent={
            <StyledFooter>
              <div className="d-flex justify-content-center align-items-center">
                <Button type="submit" variant="success-2" onClick={handleSubmit}>
                  Filtrar contatos
                </Button>
                <Button variant="link" className="m-0 p-0 ml-3" onClick={onToggle}>
                  Fechar
                </Button>
              </div>
              <Button
                variant="link"
                className="m-0 p-0 d-flex justify-content-center align-items-center text-danger "
                onClick={() => handleClear(resetForm, setValues)}
              >
                <LuTrash size="1.2em" className="mr-2" />
                Limpar filtros
              </Button>
            </StyledFooter>
          }
          side="right"
          fullHeight
          bodyClassName="p-0"
          withCloseButton
        >
          <Form onSubmit={handleSubmit} className="mr-3 mt-3 ml-3">
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label>Tipo de contato</Form.Label>
                <FormSelectField
                  name="type"
                  options={CONTACT_TYPE_OPTIONS}
                  placeholder="Filtrar pelo tipo de contato"
                  highlightFilled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label>Nome</Form.Label>
                <FormTextField
                  name="name"
                  placeholder="Filtrar pelo nome"
                  highlightFilled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label>Tipo de documento</Form.Label>
                <FormSelectField
                  name="document_type"
                  options={DOCUMENT_TYPE_OPTIONS}
                  placeholder="Filtrar pelo tipo de documento"
                  highlightFilled
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>{values.document_type || 'CPF/CNPJ'}</Form.Label>
                <FormTextField
                  name="document_number"
                  placeholder="Filtrar pelo cpf ou cnpj"
                  highlightFilled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label>E-mail</Form.Label>
                <FormTextField
                  name="email"
                  placeholder="Filtrar pelo e-mail do contato"
                  highlightFilled
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Telefone</Form.Label>
                <FormTextField
                  name="phone_number"
                  placeholder="Filtrar pelo telefone do contato"
                  highlightFilled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label>CEP</Form.Label>
                <FormTextField
                  name="address_zip_code"
                  placeholder="Filtrar pelo CEP do contato"
                  highlightFilled
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Rua</Form.Label>
                <FormTextField
                  name="address_street"
                  placeholder="Filtrar pela rua do contato"
                  highlightFilled
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Complemento</Form.Label>
                <FormTextField
                  name="address_complement"
                  placeholder="Filtrar pelo complemento do contato"
                  highlightFilled
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Bairro</Form.Label>
                <FormTextField
                  name="address_district"
                  placeholder="Filtrar pelo bairro do contato"
                  highlightFilled
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>Cidade</Form.Label>
                <FormTextField
                  name="address_city"
                  placeholder="Filtrar pela cidade do contato"
                  highlightFilled
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FormDateFromToField
                  label="Data de cadastro"
                  fromName="created_at_start"
                  fromPlaceholder="De:"
                  fromId="created_at_start"
                  toName="created_at_end"
                  toPlaceholder="Até:"
                  toId="created_at_end"
                  inputContainerClassName="mt-2"
                  highlightFilled
                  fromStyle={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: 'none',
                  }}
                  leftContent={
                    <FormQuickDateField
                      isChanged={false}
                      startDateFieldName="created_at_start"
                      endDateFieldName="created_at_end"
                      isOptional
                    />
                  }
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </FloatingCard>
      )}
    </Formik>
  );
};

ContactFilters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
};

export default ContactFilters;
