import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LuTrash, LuPencil, LuSettings } from 'react-icons/lu';
import { Col, Row } from 'react-bootstrap';

import { Button, Tag } from '_components/_core';

import { ProviderModal } from './components';
import { CustomCard, CustomCardBody, CustomCardFooter } from '../../../../styles';

function Integrations({
  isLoading,
  bankIntegrationSettings,
  onDeleteProvider,
  onFetchIntegrations,
  activeCompany,
}) {
  const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  useEffect(() => {
    onFetchIntegrations();
  }, [onFetchIntegrations, activeCompany]);

  const handleOpenCreateModal = useCallback(() => {
    setSelectedProvider(null);
    setIsProviderModalOpen(true);
  }, []);

  const handleOpenEditModal = useCallback(integration => {
    setSelectedProvider(integration);
    setIsProviderModalOpen(true);
  }, []);

  const handleDeleteProvider = useCallback(
    provider => {
      onDeleteProvider(provider._id, () => {
        onFetchIntegrations();
      });
    },
    [onDeleteProvider, onFetchIntegrations],
  );

  const AVAILABLE = [
    {
      id: 'ASAAS',
      title: 'Asaas',
      description:
        'Esta integração permite conectar uma conta existente no Asaas para realizar cobranças, parcelamentos, dentro do Zenply.',
      icon_url: '/assets/img/asaas_logo.png',
      features: [
        'Cobranças avulsas',
        'Cobranças parceladas',
        'Cobranças recorrentes',
        'Clientes',
        'Notificações de cobrança',
        'Emissão de notas fiscais',
      ],
    },
  ];

  return (
    <>
      <ProviderModal
        isVisible={isProviderModalOpen}
        onModalToggle={() => setIsProviderModalOpen(!isProviderModalOpen)}
        provider={selectedProvider}
      />
      <h5>Integrações</h5>
      <Row className="mt-3">
        {AVAILABLE.map(({ id, title, description, icon_url }) => {
          const integration = bankIntegrationSettings.find(
            setting => setting.provider_name === id,
          );

          return (
            <Col key={id} md={6} className="mb-3">
              <CustomCard>
                <CustomCardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={icon_url}
                        alt="icon"
                        width={36}
                        style={{ borderRadius: '4px' }}
                      />
                      <h6 className="m-0 ml-2">{title}</h6>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="p-0 m-0">{description}</p>
                  </div>
                </CustomCardBody>
                <CustomCardFooter>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    style={{ flex: 1 }}
                  >
                    {integration ? (
                      <>
                        {integration.enabled && <Tag variant="success">Ativado</Tag>}
                        {!integration.enabled && <Tag variant="danger">Desativado</Tag>}
                      </>
                    ) : (
                      <Tag variant="default">Não configurado</Tag>
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        size="sm"
                        variant="default"
                        className="d-flex justify-content-center align-items-center"
                        onClick={() =>
                          integration
                            ? handleOpenEditModal(integration)
                            : handleOpenCreateModal()
                        }
                        isLoading={isLoading}
                        disabled={isLoading}
                      >
                        {integration && <LuPencil />}
                        {!integration && <LuSettings />}
                      </Button>
                      {integration && (
                        <Button
                          size="sm"
                          variant="inverse-danger"
                          className="ml-2 d-flex justify-content-center align-items-center"
                          onClick={() => handleDeleteProvider(integration)}
                          disabled={isLoading}
                          isLoading={isLoading}
                        >
                          <LuTrash />
                        </Button>
                      )}
                    </div>
                  </span>
                </CustomCardFooter>
              </CustomCard>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

Integrations.defaultProps = {
  bankIntegrationSettings: [],
};

Integrations.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDeleteKey: PropTypes.func.isRequired,
  onFetchKeys: PropTypes.func.isRequired,
  bankIntegrationSettings: PropTypes.array.isRequired,
  activeCompany: PropTypes.object.isRequired,
};

export default Integrations;
