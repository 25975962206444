import { connect } from 'react-redux';
import { compose } from 'redux';

import notificationsActionCreators from '_store/_actions/notifications';

import Notifications from './Notifications';

const MapStateToProps = state => ({
  total: state.notifications.total,
  notifications: state.notifications.notifications,
  isLoading: state.notifications.isLoading,
});

const MapDispatchToProps = {
  onFetchNotifications: notificationsActionCreators.fetchUserNotifications,
  onReadNotification: notificationsActionCreators.readNotification,
  onArchiveNotification: notificationsActionCreators.archiveNotification,
  onReadAllNotifications: notificationsActionCreators.readManyNotifications,
  onUnreadNotification: notificationsActionCreators.unreadNotification,
  onUnreadAllNotifications: notificationsActionCreators.unreadManyNotifications,
};

export default compose(connect(MapStateToProps, MapDispatchToProps))(Notifications);
