import styled, { css } from 'styled-components';

import { Card, Button } from '_components/_core';

export const CustomCardHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #F1F1F4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    color: #071437;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
    cursor: pointer;

    :hover {
      background-color: #F9F9FB;
    }
  `}
`;

export const CustomCard = styled(Card)`
  border: 1px solid #F1F1F4;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding-bottom: 0;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`;

export const CustomCardBody = styled.div`
  padding: 16px;

  ${({ noPadding }) =>
    noPadding &&
    css`
    padding: 0;
  `}

`;

export const NotificationVariantDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  
  ${({ variant }) => {
    switch (variant) {
      case 'info':
        return 'background-color: #2684FF;';
      case 'success':
        return 'background-color: #38A915;';
      case 'warning':
        return 'background-color: #fcca00;';
      case 'error':
        return 'background-color: #e64d43;';
      default:
        return 'background-color: #383d41;';
    }
  }}
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  .notification-button {
    display: none;
  }

  thead {
    background: #f5f5f5;
  }

  tbody tr {    
    &:hover {
      .notification-button {
        display: flex;
      }
    }

    td {
      height: 42px;
      line-height: 20px;
      padding: 8px !important;
      vertical-align: middle;
      border-top: 1px solid #F1F1F4;
      cursor: pointer !important;
      font-weight: 400;
      cursor: default;
    }
  }
`;

export const NotificationButton = styled(Button)`
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
`;
