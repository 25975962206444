import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { DOCUMENT_TYPE_OPTIONS, CONTACT_TYPE_OPTIONS } from 'helpers/constants';
import { AddressFormFields } from '_components/_shared';
import {
  Button,
  FormSelectField,
  FormTextField,
  FormCpfCnpjField,
  FormMaskedInput,
} from '_components/_core';

import { SearchButton } from '../../styles';

function ContactDetails({
  values,
  setFieldValue,
  handleSubmit,
  handleCompanySearch,
  isSearching,
  columnSizes,
}) {
  return (
    <Form onSubmit={handleSubmit} className="mr-3 mt-3 ml-3">
      <Form.Row>
        <Form.Group as={Col} xl="4" className="mb-0">
          <Form.Label>Tipo de contato</Form.Label>
          <br />
          <ButtonGroup>
            {CONTACT_TYPE_OPTIONS.map(option => (
              <Button
                key={option.value}
                variant="inverse-dark"
                onClick={() => setFieldValue('type', option.value)}
                active={values.type === option.value}
                className={values.type === option.value ? 'active' : ''}
              >
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        </Form.Group>
      </Form.Row>
      <hr />
      <Form.Row>
        <Form.Group as={Col} xl="12">
          <Form.Label>Tipo de documento</Form.Label>
          <FormSelectField name="document_type" options={DOCUMENT_TYPE_OPTIONS} />
        </Form.Group>
        <Form.Group as={Col} xs="8" sm="8" md="8">
          <Form.Label>{values.document_type || 'CPF/CNPJ'}</Form.Label>
          <FormCpfCnpjField
            name="document_number"
            placeholder={values.document_type}
            type={values.document_type}
          />
        </Form.Group>
        <Form.Group as={Col} xs="4" sm="4" md="4">
          <SearchButton
            variant="secondary"
            isLoading={isSearching}
            disabled={isSearching || values.document_type !== 'CNPJ'}
            onClick={() => handleCompanySearch(values, setFieldValue)}
          >
            Buscar CNPJ
          </SearchButton>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label>
            {values.document_type === 'CPF' ? 'Nome completo' : 'Razão Social'}
          </Form.Label>
          <FormTextField name="name" placeholder="Digite o nome do Cliente." />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label>E-mail</Form.Label>
          <FormTextField name="email" placeholder="E-mail" autoComplete="off" />
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label>Telefone</Form.Label>
          <FormMaskedInput name="phone_number" placeholder="Telefone" type="PHONE" />
        </Form.Group>
      </Form.Row>
      <hr />
      <AddressFormFields
        columnSizes={
          columnSizes || {
            cep: 6,
            searchButton: 4,
            street: 6,
            number: 6,
            district: 6,
            complement: 6,
            city: 6,
            state: 6,
          }
        }
      />
    </Form>
  );
}

ContactDetails.defaultProps = {
  isSearching: false,
  columnSizes: null,
};

ContactDetails.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCompanySearch: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  columnSizes: PropTypes.object,
};

export default ContactDetails;
