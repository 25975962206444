import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IoAddOutline, IoChevronDown } from 'react-icons/io5';
import { LuSettings, LuChevronRight } from 'react-icons/lu';
import { FaCheckCircle } from 'react-icons/fa';
import { Form, Popover } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';
import { useMediaQuery } from 'helpers';
import { CompanyInvitations, LoadingIcon } from '_components/_shared';
import { ActionSheet, Button, Tag } from '_components/_core';

import {
  Trigger,
  ActiveCompany,
  CompanyAvatar,
  SearchMenuItem,
  CompanyListItem,
  SubscriptionInfo,
  StyledOverlayTrigger,
  StyledPopover,
  PlanName,
  CompanyList,
  AddCompanyButton,
  Footer,
  UserCount,
} from './styles';

function CompanySwitcher({
  isLoading,
  activeCompany,
  companiesList,
  onFetchActiveCompany,
  onActivateCompany,
  onFetchCompanies,
}) {
  const history = useHistory();

  const [isSwitching, setIsSwitching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [actionSheetOpen, setActionSheetOpen] = useState(false);

  const { isMobile, isTablet } = useMediaQuery();

  const handleRedirect = useCallback(
    path => {
      history.push(path);

      document.body.click();
      setActionSheetOpen(false);
    },
    [history],
  );

  const handleClose = useCallback(() => {
    setActionSheetOpen(false);
  }, []);

  useEffect(() => {
    onFetchActiveCompany();
    onFetchCompanies();
  }, [onFetchActiveCompany, onFetchCompanies]);

  useEffect(() => {
    const handleStorageChange = e => {
      if (e.key !== 'active_company_id') return;

      const newActiveCompanyId = e.newValue;

      if (
        newActiveCompanyId &&
        activeCompany &&
        newActiveCompanyId !== activeCompany.id
      ) {
        confirmDialog.open({
          title: 'Nova empresa selecionada',
          message:
            'Uma nova empresa foi selecionada em outra aba, recarregue a página para atualizar os dados da sessão.',
          confirmButtonText: 'Recarregar',
          onConfirm: () => {
            window.location.reload();
          },
          onCancel: () => {
            window.location.reload();
          },
          showCancelButton: false,
          allowOutsideClick: false,
          icon: 'warning',
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [activeCompany]);

  const { trading_name } = activeCompany || {};

  const handleEditCompany = useCallback(() => {
    history.push('/configuracoes/dados-empresa');

    document.body.click();
    setActionSheetOpen(false);
  }, [history]);

  const renderAvatar = useCallback((company, size = 'normal') => {
    if (!company) {
      return null;
    }

    if (company.company_avatar_url) {
      return <CompanyAvatar src={company.company_avatar_url} size={size} />;
    }

    return (
      <div className="avatar">{`${company.trading_name.charAt(0).toUpperCase()}`}</div>
    );
  }, []);

  const handleActivateCompany = useCallback(
    company => {
      if (company.id === activeCompany.id) {
        return;
      }

      setIsSwitching(true);
      setSearchValue('');

      document.body.click();

      setActionSheetOpen(false);

      onActivateCompany(company, () => {
        setIsSwitching(false);

        localStorage.setItem('active_company_id', company.id);

        onFetchActiveCompany();
      });
    },
    [onActivateCompany, onFetchActiveCompany, activeCompany],
  );

  const filteredCompanies = useMemo(() => {
    if (!searchValue) {
      return companiesList;
    }

    return companiesList.filter(
      company =>
        company.trading_name.toLowerCase().includes(searchValue.toLowerCase()) ||
        company.company_name.toLowerCase().includes(searchValue.toLowerCase()) ||
        company.document_number.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [companiesList, searchValue]);

  const renderList = useCallback(
    companies => {
      if (isEmpty(companies)) {
        return (
          <div className="p-2 d-flex justify-content-center align-items-center text-muted">
            <span>Nenhuma empresa encontrada</span>
          </div>
        );
      }

      return (
        <div className="p-1">
          {companies.map(company => (
            <CompanyListItem
              key={company.id}
              className="p-2 d-flex align-items-center"
              onClick={() => handleActivateCompany(company)}
              active={activeCompany?.id === company.id}
              tabIndex={0}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();

                  handleActivateCompany(company);
                }
              }}
            >
              {renderAvatar(company)}
              <div
                className="ml-1 d-flex flex-column align-items-start justify-content-center"
                style={{ flex: 1 }}
              >
                <strong className="d-flex justify-content-center align-items-center">
                  {FORMATTERS.MAX_X_CHARS(company.trading_name.toLowerCase(), 30)}
                </strong>
                <SubscriptionInfo>
                  <PlanName>
                    {company.personal_account
                      ? 'Conta pessoal'
                      : FORMATTERS.CPF_CNPJ(company.document_number)}
                  </PlanName>
                  {company.subscription_status !== 'active' && (
                    <>
                      {/* <Dot>•</Dot> */}
                      <UserCount status={company.subscription_status}>
                        {FORMATTERS.SUBSCRIPTION_STATUS(company.subscription_status)}
                      </UserCount>
                    </>
                  )}
                </SubscriptionInfo>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <Tag
                  className="m-0 p-0 pl-1 pr-1 mr-1"
                  variant={company.is_manager ? 'warning' : 'default'}
                  style={{ fontSize: '0.7em' }}
                >
                  {company.is_manager ? 'admin' : 'convidado'}
                </Tag>
                {activeCompany && activeCompany.id !== company.id && (
                  <LuChevronRight size={16} className="text-muted" tabIndex={-1} />
                )}
                {activeCompany && activeCompany.id === company.id && (
                  <FaCheckCircle
                    size="1.2em"
                    className="text-success"
                    data-tip="Esta é a empresa selecionada no momento"
                    data-place="bottom"
                    tabIndex={-1}
                  />
                )}
              </div>
            </CompanyListItem>
          ))}
        </div>
      );
    },
    [handleActivateCompany, activeCompany, renderAvatar],
  );

  const handleChange = useCallback(e => {
    setSearchValue(e.target.value);
  }, []);

  const renderContent = useCallback(() => {
    return (
      <>
        <ActiveCompany onClick={() => handleEditCompany(activeCompany.id)}>
          {renderAvatar(activeCompany)}
          <div className="ml-2 d-flex flex-column">
            <strong>
              {FORMATTERS.MAX_X_CHARS(activeCompany?.trading_name.toLowerCase(), 30)}
            </strong>
            <small>
              <SubscriptionInfo>
                <PlanName>{FORMATTERS.CPF_CNPJ(activeCompany?.document_number)}</PlanName>
              </SubscriptionInfo>
            </small>
          </div>
          <div className="ml-auto d-flex justify-content-center">
            <LuSettings size={24} />
          </div>
        </ActiveCompany>
        {!isEmpty(companiesList) && (
          <div className="mt-2">
            <SearchMenuItem>
              <small
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleRedirect('/configuracoes/empresas')}
              >{`Minhas empresas (${companiesList.length})`}</small>
              <span>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Buscar ..."
                  className="flex-fill"
                  onChange={handleChange}
                  value={searchValue}
                  autoFocus={!isMobile}
                />
              </span>
            </SearchMenuItem>
            <CompanyList>{renderList(filteredCompanies)}</CompanyList>
          </div>
        )}
        <Footer>
          <AddCompanyButton
            variant="link"
            onClick={() => handleRedirect('/configuracoes/empresas?action=create')}
          >
            <IoAddOutline size={20} className="mr-2" />
            Adicionar outra empresa
          </AddCompanyButton>
        </Footer>
      </>
    );
  }, [
    isMobile,
    activeCompany,
    companiesList,
    handleChange,
    handleRedirect,
    renderList,
    renderAvatar,
    filteredCompanies,
    handleEditCompany,
    searchValue,
  ]);

  const renderPopoverContent = useCallback(() => {
    return (
      <StyledPopover>
        <Popover.Content>{renderContent()}</Popover.Content>
      </StyledPopover>
    );
  }, [renderContent]);

  const renderTrigger = useCallback(
    (withActionSheet = false) => {
      return (
        <div>
          {(isLoading || isSwitching) && (
            <div>
              <LoadingIcon text="" />
            </div>
          )}
          {!isLoading && !isSwitching && (
            <Trigger onClick={() => (withActionSheet ? setActionSheetOpen(true) : null)}>
              {renderAvatar(activeCompany, 'small')}
              <h4>
                {trading_name &&
                  FORMATTERS.MAX_X_CHARS(trading_name.toLowerCase(), isMobile ? 10 : 30)}
              </h4>
              <IoChevronDown
                className="ml-2 mr-1"
                size="0.8em"
                color="var(--color-text-primary)"
              />
            </Trigger>
          )}
        </div>
      );
    },
    [activeCompany, isLoading, isMobile, isSwitching, renderAvatar, trading_name],
  );

  if (isMobile || isTablet) {
    return (
      <>
        {renderTrigger(true)}
        <ActionSheet
          isOpen={actionSheetOpen}
          onToggle={() => setActionSheetOpen(!actionSheetOpen)}
          detent="content-height"
          prefersReducedMotion
        >
          <div>
            <div
              className="p-1"
              style={{
                minHeight: '60vh',
              }}
            >
              {renderContent(false)}
            </div>
            <Button
              onClick={handleClose}
              className="w-100 m-0 p-0 mt-4 mb-4"
              variant="link"
            >
              Fechar
            </Button>
          </div>
        </ActionSheet>
      </>
    );
  }

  return (
    <>
      <StyledOverlayTrigger
        trigger="click"
        placement="bottom-start"
        overlay={renderPopoverContent()}
        rootClose
      >
        {renderTrigger()}
      </StyledOverlayTrigger>
      <CompanyInvitations />
    </>
  );
}

CompanySwitcher.propTypes = {
  isLoading: false,
  companiesList: [],
  activeCompany: {},
};

CompanySwitcher.propTypes = {
  activeCompany: PropTypes.object,
  isLoading: PropTypes.bool,
  companiesList: PropTypes.array,
  onFetchActiveCompany: PropTypes.func.isRequired,
  onActivateCompany: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
};

export default CompanySwitcher;
