import { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { useMediaQuery } from 'helpers';
import alertActions from '_store/_actions/alert.actions';

import { Button } from '_components/_core';
import { useTableV2 } from '_components/_core/Table/utils';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import { FileViewer } from '_components/_shared/FileManagement/components';

import { CustomFileList, FileTypeField, FiltersForm } from './components';
import { CustomCard, CustomCardBody, CustomCardHeader } from './styles';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: {},
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Files({
  files,
  total,
  onFetchFiles,
  onDeleteTransactionFile,
  onDeleteContactFile,
  isLoadingFiles,
  activeCompany,
  onFetchStorage,
  user,
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [fileForPreview, setFileForPreview] = useState(null);

  const dispatch = useDispatch();

  const { isMobile } = useMediaQuery();

  const userPermissions = useSelector(
    state => state.userPermissions.permissions[state.auth.user.id],
  );

  useEffect(() => {
    onFetchStorage();
  }, [onFetchStorage, activeCompany]);

  const canViewFiles = useCallback(
    () =>
      hasPermissions({
        permissions: ['file_view'],
        userPermissions,
        type: 'all',
      }),
    [userPermissions],
  );

  const canDeleteFiles = useCallback(
    () =>
      hasPermissions({
        permissions: ['file_delete'],
        userPermissions,
        type: 'all',
      }),
    [userPermissions],
  );

  const { pagination, onPageChange, onPageSizeChange, onFilter } = useTableV2({
    ...tableConfig,
    data: files,
    onFetchData: onFetchFiles,
    withSavedFilters: false,
    withInitialLoading: true,
    withActiveCompanyRefresh: true,
  });

  const handlePreviewFile = useCallback(
    file => {
      if (!canViewFiles()) {
        dispatch(alertActions.error('Você não tem permissão para visualizar anexos.'));
        return;
      }

      setFileForPreview(file);
      setIsPreviewOpen(true);
    },
    [canViewFiles, dispatch],
  );

  const handleDownloadFile = useCallback(
    file => {
      if (!canViewFiles()) {
        dispatch(alertActions.error('Você não tem permissão para visualizar anexos.'));
        return;
      }

      const link = document.createElement('a');
      link.href = file.signed_url;
      link.target = '_blank';
      link.setAttribute('download', file.file_name || file.path);
      document.body.appendChild(link);

      link.click();
      link.remove();
    },
    [canViewFiles, dispatch],
  );

  const handleDeleteFile = useCallback(
    (fileId, entityType) => {
      if (!canDeleteFiles()) {
        dispatch(alertActions.error('Você não tem permissão para excluir anexos.'));

        return;
      }

      if (entityType === 'contact') {
        onDeleteContactFile(fileId);

        return;
      }

      onDeleteTransactionFile(fileId);
    },
    [canDeleteFiles, dispatch, onDeleteContactFile, onDeleteTransactionFile],
  );

  const handleModalToggle = useCallback(() => {
    setIsPreviewOpen(prevState => !prevState);
  }, []);

  const initialValues = useMemo(
    () => ({
      start_date: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      end_date: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
      date_type: 'transaction_date',
      file_type: 'transaction',
    }),
    [],
  );

  const handleFilter = useCallback(
    values => {
      const { start_date, end_date, date_type, file_type } = values;

      const filters = {};

      if (start_date) {
        filters.start_date = DateTime.fromISO(start_date, {
          zone: 'utc',
        }).toFormat('yyyy-MM-dd');
      }

      if (end_date) {
        filters.end_date = DateTime.fromISO(end_date, { zone: 'utc' }).toFormat(
          'yyyy-MM-dd',
        );
      }

      if (date_type) {
        filters.date_type = date_type;
      }

      if (file_type) {
        filters.file_type = file_type;
      }

      onFilter(filters);
    },
    [onFilter],
  );

  return (
    <Container>
      <FileViewer
        isVisible={isPreviewOpen}
        fileForPreview={fileForPreview}
        onModalToggle={handleModalToggle}
      />
      <div className="d-flex justify-content-between align-items-end mb-3">
        <h4 className="m-0">Arquivos</h4>
        &nbsp;
      </div>
      <Formik initialValues={initialValues} onSubmit={handleFilter}>
        {({ values, handleSubmit }) => (
          <>
            <CustomCard>
              <CustomCardHeader>
                <div
                  className={classNames({
                    'd-flex w-100': true,
                    'align-items-center': !isMobile,
                    'flex-column': isMobile,
                  })}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <FileTypeField user={user} />
                  </div>
                  <div
                    className={classNames({
                      'd-flex align-items-center ml-auto': !isMobile,
                      'flex-column mt-3': isMobile,
                    })}
                  >
                    <FiltersForm fileType={values.file_type} />
                    <Button
                      isLoading={isLoadingFiles}
                      variant="success-2"
                      className={classNames({
                        'ml-3': !isMobile,
                        'w-100 mt-3': isMobile,
                      })}
                      onClick={handleSubmit}
                    >
                      Filtrar
                    </Button>
                  </div>
                </div>
              </CustomCardHeader>
              <CustomCardBody className="p-0">
                <CustomFileList
                  files={files}
                  total={total}
                  isLoadingFiles={isLoadingFiles}
                  values={values}
                  onFilter={onFilter}
                  pagination={pagination}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  onPreviewFile={handlePreviewFile}
                  onDownloadFile={handleDownloadFile}
                  onDeleteFile={file => handleDeleteFile(file, values.file_type)}
                  fileType={values.file_type}
                />
              </CustomCardBody>
            </CustomCard>
          </>
        )}
      </Formik>
    </Container>
  );
}

Files.propTypes = {
  files: PropTypes.array,
  total: PropTypes.number,
  onFetchFiles: PropTypes.func.isRequired,
  onDeleteTransactionFile: PropTypes.func.isRequired,
  onDeleteContactFile: PropTypes.func.isRequired,
  isLoadingFiles: PropTypes.bool,
  activeCompany: PropTypes.object,
  onFetchStorage: PropTypes.func.isRequired,
  user: PropTypes.object,
};

Files.defaultProps = {
  files: [],
  total: 0,
  isLoadingFiles: false,
  user: {},
};

export default Files;
