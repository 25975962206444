import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { ContactForm, AsyncOperations } from '_components/_shared';
import useAsyncOperations from '_components/_shared/AsyncOperations/useAsyncOperations';

import { useMediaQuery } from 'helpers';
import {
  ContactDetail,
  ContactList,
  ContactListHeader,
  ContactSummary,
} from './components';
import { StyledFormCheck } from './styles';

function Contacts({
  isLoadingRecipients,
  allRecipients,
  activeCompany,
  user,
  onFetchContact,
  onFetchContacts,
  onFetchContactsSummary,
  onDeleteContacts,
  onDeleteContact,
  onCreateContact,
  onUpdateMultipleContacts,
  onFetchContactsSummaryDetail,
  onExportContacts,
  totalRecipients,
}) {
  const { isMobile, isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge } =
    useMediaQuery();

  const { hasPending, pendingAsyncOperation, onSetAsyncOperations } = useAsyncOperations({
    type: 'EXPORT_CONTACTS_SPREADSHEET',
    successTitle: 'Back-up concluído!',
    successMessage: 'Seu back-up foi gerado com sucesso.',
    downloadFile: true,
    showConfirmation: false,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedEditContact, setSelectedEditContact] = useState({});
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [type, setType] = useState(null);
  const [textFilter, setTextFilter] = useState('');
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [summary, setSummary] = useState({
    incomes: [],
    expenses: [],
  });

  const handleAddContact = useCallback(() => {
    setSelectedEditContact({});
    setIsOpen(true);
  }, []);

  const handleDeleteContact = useCallback(
    (e, contactId) => {
      e.stopPropagation();

      onDeleteContact(contactId, {}, () => {
        setSelectedContact({});
      });
    },
    [onDeleteContact],
  );

  const handleEditContact = useCallback(
    (e, contactId) => {
      e.stopPropagation();

      if (contactId) {
        setSelectedEditContact({
          id: contactId,
        });

        setIsOpen(true);

        return;
      }

      setSelectedEditContact(selectedContact);

      setIsOpen(true);
    },
    [selectedContact],
  );

  useEffect(() => {
    setSelectedContact({});

    onFetchContacts();

    onFetchContactsSummary(foundSummary => {
      setSummary(foundSummary);
    });
  }, [onFetchContacts, onFetchContactsSummary, activeCompany]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const recipientId = urlParams.get('recipient_id');

    if (recipientId) {
      setSelectedEditContact({
        id: recipientId,
      });

      setIsOpen(true);

      urlParams.delete('recipient_id');

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [onFetchContact, handleEditContact]);

  const handleToggleForm = useCallback(() => {
    const newState = !isOpen;

    if (!newState) {
      setSelectedEditContact({});
    }

    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleTabChange = useCallback(newType => {
    setType(newType);

    setSelectedItems([]);
  }, []);

  const handleItemSelected = useCallback(
    (e, id) => {
      e.stopPropagation();

      if (selectedItems.includes(id)) {
        setSelectedItems(selectedItems.filter(item => item !== id));
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    },
    [selectedItems],
  );

  const handleSelectMultiple = useCallback(
    (e, ids) => {
      e.stopPropagation();

      if (
        selectedItems.length === ids.length &&
        selectedItems.every(item => ids.includes(item))
      ) {
        setSelectedItems([]);
      } else {
        setSelectedItems(ids.filter(item => item !== null));
      }
    },
    [selectedItems],
  );

  const filteredRecipients = useMemo(() => {
    let filtered = allRecipients;

    if (type) {
      filtered = filtered.filter(recipient => recipient.type === type);
    }

    if (textFilter) {
      filtered = filtered.filter(recipient =>
        recipient.name.toLowerCase().includes(textFilter.toLowerCase()),
      );
    }

    const TYPE_ORDER = ['CUSTOMER', 'SUPPLIER', 'EMPLOYEE', null];

    const sortByType = recipient => TYPE_ORDER.indexOf(recipient.type);
    const sortByName = recipient => (recipient.name ? recipient.name.toLowerCase() : '');

    const sorted = sortBy(filtered, [sortByType, sortByName]);

    let counts = {
      CUSTOMER: 0,
      SUPPLIER: 0,
      EMPLOYEE: 0,
      null: 0,
    };

    sorted.forEach(recipient => {
      counts = {
        ...counts,
        [recipient.type]: counts[recipient.type] + 1,
      };
    });

    const hasCustomer = counts.CUSTOMER > 0;
    const hasSupplier = counts.SUPPLIER > 0;
    const hasEmployee = counts.EMPLOYEE > 0;
    const hasUnidentified = counts.null > 0;

    if (hasCustomer) {
      const customerIndex = sorted.findIndex(recipient => recipient.type === 'CUSTOMER');

      if (customerIndex > -1) {
        sorted.splice(customerIndex, 0, {
          key: 'group_row_customer',
          type: 'CUSTOMER',
          name: 'Clientes',
          isGroup: true,
          length: counts.CUSTOMER,
        });
      }
    }

    if (hasSupplier) {
      const supplierIndex = sorted.findIndex(recipient => recipient.type === 'SUPPLIER');

      if (supplierIndex > -1) {
        sorted.splice(supplierIndex, 0, {
          key: 'group_row_supplier',
          type: 'SUPPLIER',
          name: 'Fornecedores',
          isGroup: true,
          length: counts.SUPPLIER,
        });
      }
    }

    if (hasEmployee) {
      const employeeIndex = sorted.findIndex(recipient => recipient.type === 'EMPLOYEE');

      if (employeeIndex > -1) {
        sorted.splice(employeeIndex, 0, {
          key: 'group_row_employee',
          type: 'EMPLOYEE',
          name: 'Funcionários',
          isGroup: true,
          length: counts.EMPLOYEE,
        });
      }
    }

    if (hasUnidentified) {
      const unidentifiedIndex = sorted.findIndex(recipient => recipient.type === null);

      if (unidentifiedIndex > -1) {
        sorted.splice(unidentifiedIndex, 0, {
          key: 'group_row_unidentified',
          type: null,
          name: 'Outros',
          isGroup: true,
          length: counts.null,
        });
      }
    }

    return sorted;
  }, [allRecipients, type, textFilter]);

  const handleSelectContact = useCallback(
    contact => {
      setIsLoadingContact(true);

      onFetchContact(contact.id, foundContact => {
        setSelectedContact({
          ...foundContact,
        });

        setIsLoadingContact(false);
      });
    },
    [onFetchContact],
  );

  const handleCreatesSimpleContact = useCallback(() => {
    onCreateContact(
      {
        name: textFilter || 'Novo contato',
        type: type || null,
      },
      contact => {
        setSelectedContact(contact);
      },
    );

    setSelectedItems([]);
  }, [onCreateContact, type, textFilter]);

  const fullAddress = useMemo(() => {
    if (!selectedContact || isEmpty(selectedContact)) {
      return '';
    }

    const {
      address_street,
      address_number,
      address_complement,
      address_district,
      address_city,
      address_state,
      address_zip_code,
    } = selectedContact;

    return `${address_street}, ${address_number}, ${address_complement || ''},  ${address_district} ${address_city} - ${address_state} ${address_zip_code}`;
  }, [selectedContact]);

  const renderCheckbox = useCallback(
    () => (
      <StyledFormCheck
        type="checkbox"
        checked={
          filteredRecipients.length > 0 &&
          selectedItems.length === filteredRecipients.length
        }
        onClick={() => {
          if (selectedItems.length === filteredRecipients.length) {
            setSelectedItems([]);
          } else {
            setSelectedItems(
              filteredRecipients
                .filter(f => f.id !== null)
                .map(recipient => recipient.id),
            );
          }
        }}
      />
    ),
    [filteredRecipients, selectedItems],
  );

  const handleChangeType = useCallback(
    (ids, type) => {
      const params = {
        ids,
        type,
      };

      onUpdateMultipleContacts(params, () => {
        setSelectedItems([]);
      });
    },
    [onUpdateMultipleContacts],
  );

  const handleViewTransactions = useCallback(
    (summaryItem, type) => {
      const { age_bracket } = summaryItem;

      const params = {
        type,
        age_bracket,
      };

      onFetchContactsSummaryDetail(params, found => {
        setPendingTransactions(found);

        setSelectedFilter({
          type,
          age_bracket,
        });
      });
    },
    [onFetchContactsSummaryDetail],
  );

  const handleExportContacts = useCallback(
    filters => {
      onExportContacts(filters, asyncOperation => {
        onSetAsyncOperations([asyncOperation]);
      });
    },
    [onExportContacts, onSetAsyncOperations],
  );

  const COLUMN_SIZES = useMemo(() => {
    if (isMobile) {
      return {
        LEFT: 12,
        RIGHT: 12,
      };
    }

    if (isTablet) {
      return {
        LEFT: 12,
        RIGHT: 12,
      };
    }

    if (isDesktopMedium) {
      return {
        LEFT: 6,
        RIGHT: 6,
      };
    }

    if (isDesktopLarge) {
      return {
        LEFT: 5,
        RIGHT: 7,
      };
    }

    if (isDesktopExtraLarge) {
      return {
        LEFT: 4,
        RIGHT: 8,
      };
    }

    return {
      LEFT: 3,
      RIGHT: 0,
    };
  }, [isMobile, isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge]);

  const CARDS_COLUMN_SIZES = useMemo(() => {
    if (isDesktopMedium) {
      return {
        LEFT: 12,
        RIGHT: 12,
      };
    }

    return {
      LEFT: 6,
      RIGHT: 6,
    };
  }, [isDesktopMedium]);

  const SUMMARY_TOTAL_INCOME = useMemo(
    () => summary.incomes.reduce((acc, curr) => acc + curr.amount, 0),
    [summary],
  );
  const SUMMARY_TOTAL_EXPENSE = useMemo(
    () => summary.expenses.reduce((acc, curr) => acc + curr.amount, 0),
    [summary],
  );

  const getItemTotalIncome = useCallback(
    item => {
      const total = SUMMARY_TOTAL_INCOME;

      if (total === 0) {
        return 0;
      }

      return (item.amount / total) * 100;
    },
    [SUMMARY_TOTAL_INCOME],
  );

  const getItemTotalExpense = useCallback(
    item => {
      const total = SUMMARY_TOTAL_EXPENSE;

      if (total === 0) {
        return 0;
      }

      return (item.amount / total) * 100;
    },
    [SUMMARY_TOTAL_EXPENSE],
  );

  return (
    <Container fluid className="content-wrapper fixedHeader h-100">
      <AsyncOperations
        className="mb-3"
        hasPending={hasPending}
        pendingAsyncOperation={pendingAsyncOperation}
      />
      <Row className="h-100">
        <Col sm={COLUMN_SIZES.LEFT}>
          <ContactListHeader
            isMobile={isMobile}
            isTablet={isTablet}
            isLoading={isLoadingRecipients}
            selectedItems={selectedItems}
            textFilter={textFilter}
            type={type}
            filteredRecipients={filteredRecipients}
            handleAddContact={handleAddContact}
            handleExportContacts={handleExportContacts}
            setTextFilter={setTextFilter}
            renderCheckbox={renderCheckbox}
            handleTabChange={handleTabChange}
            handleChangeType={handleChangeType}
            onDeleteContacts={onDeleteContacts}
            setSelectedItems={setSelectedItems}
            setSelectedContact={setSelectedContact}
            selectedContact={selectedContact}
            handleCreatesSimpleContact={handleCreatesSimpleContact}
            onFetchContacts={onFetchContacts}
            user={user}
            totalRecipients={totalRecipients}
          />
          {filteredRecipients.length > 0 && (
            <ContactList
              isLoading={isLoadingRecipients}
              filteredRecipients={filteredRecipients}
              selectedItems={selectedItems}
              selectedContact={selectedContact}
              handleSelectMultiple={handleSelectMultiple}
              handleSelectContact={handleSelectContact}
              handleItemSelected={handleItemSelected}
              handleEditContact={handleEditContact}
              handleDeleteContact={handleDeleteContact}
            />
          )}
        </Col>
        {!isMobile && !isTablet && (
          <Col sm={COLUMN_SIZES.RIGHT}>
            <div style={{ position: 'sticky', top: '60px' }}>
              {isEmpty(selectedContact) && !isLoadingContact && (
                <ContactSummary
                  summary={summary}
                  selectedFilter={selectedFilter}
                  pendingTransactions={pendingTransactions}
                  CARDS_COLUMN_SIZES={CARDS_COLUMN_SIZES}
                  SUMMARY_TOTAL_INCOME={SUMMARY_TOTAL_INCOME}
                  SUMMARY_TOTAL_EXPENSE={SUMMARY_TOTAL_EXPENSE}
                  getItemTotalIncome={getItemTotalIncome}
                  getItemTotalExpense={getItemTotalExpense}
                  handleViewTransactions={handleViewTransactions}
                />
              )}
              <ContactDetail
                selectedContact={selectedContact}
                isLoadingContact={isLoadingContact}
                fullAddress={fullAddress}
                handleEditContact={handleEditContact}
                setSelectedContact={setSelectedContact}
              />
            </div>
          </Col>
        )}
      </Row>
      <ContactForm
        isOpen={isOpen}
        isLoading={isLoadingRecipients}
        contact={selectedEditContact}
        contact_id={selectedEditContact.id}
        onToggleForm={handleToggleForm}
        onAfterSaveCallbackWithReset={contact_related => {
          if (contact_related) {
            setSelectedContact(contact_related);
          }

          handleToggleForm();
        }}
        onAfterDeleteCallback={handleToggleForm}
      />
    </Container>
  );
}

Contacts.defaultProps = {
  allRecipients: [],
};

Contacts.propTypes = {
  isLoading: PropTypes.func,
  activeCompany: PropTypes.object,
  allRecipients: PropTypes.array,
  onFetchContact: PropTypes.func.isRequired,
  onFetchContacts: PropTypes.func.isRequired,
  onFetchContactsSummary: PropTypes.func.isRequired,
  onDeleteContacts: PropTypes.func.isRequired,
  onDeleteContact: PropTypes.func.isRequired,
  onCreateContact: PropTypes.func.isRequired,
  onUpdateMultipleContacts: PropTypes.func.isRequired,
  onFetchContactsSummaryDetail: PropTypes.func.isRequired,
  onExportContacts: PropTypes.func.isRequired,
};

export default Contacts;
