import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { FaExclamationCircle } from 'react-icons/fa';
import ProgressBar from 'react-bootstrap/ProgressBar';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FORMATTERS from 'helpers/formatters';

import { ActionSheet } from '_components/_core';

import { BalanceContainer, Container, Handler } from './styles';

function TransactionSummary({
  transactions,
  transactionFilters,
  searchCriteria,
  canViewIncomes,
  canViewExpenses,
  canViewForecast,
  isTablet,
}) {
  const [actionSheetOpen, setActionSheetOpen] = useState(false);

  const totals = useMemo(() => {
    const initial = {
      INCOME: {
        total_amount: 0,
        total_paid_amount: 0,
        total_unpaid_amount: 0,
      },
      EXPENSE: {
        total_amount: 0,
        total_paid_amount: 0,
        total_unpaid_amount: 0,
      },
      TRANSFER: {
        total_amount: 0,
        total_paid_amount: 0,
        total_unpaid_amount: 0,
      },
    };

    if (!transactions) {
      return initial;
    }

    const finalTransactions = transactions;

    const totalAmount = finalTransactions.reduce((acc, transaction) => {
      const { type } = transaction;

      return {
        ...acc,
        [type]: {
          ...acc[type],
          total_amount: acc[type].total_amount + transaction.amount,
          total_paid_amount:
            acc[type].total_paid_amount + (transaction.paid ? transaction.amount : 0),
          total_unpaid_amount:
            acc[type].total_unpaid_amount + (transaction.paid ? 0 : transaction.amount),
        },
      };
    }, initial);

    return totalAmount;
  }, [transactions]);

  const Line = ({ label, value, muted, canView = true }) => (
    <small
      style={{
        fontSize: '11.9px',
      }}
      className={classNames(
        'd-flex justify-content-between align-items-center mt-2',
        muted ? 'text-muted' : '',
      )}
    >
      <span>{label}</span>
      <span>
        <strong className="financial-value">
          {canView ? FORMATTERS.NUMBER(value) : '---'}
        </strong>
      </span>
    </small>
  );

  const getPercentage = type => {
    if (isEmpty(totals)) {
      return 0;
    }

    const { total_amount, total_paid_amount } = totals[type];

    return (total_paid_amount / total_amount) * 100;
  };

  return (
    <>
      <Container isTablet={isTablet} onClick={() => setActionSheetOpen(!actionSheetOpen)}>
        <div className="text-center">
          <Handler />
        </div>
        <BalanceContainer>
          <div>
            <p className="text-muted p-0 m-0">Resultado previsto</p>
            <p className="p-0 m-0 financial-value">
              {canViewForecast
                ? FORMATTERS.NUMBER(
                    totals.INCOME.total_amount - totals.EXPENSE.total_amount,
                  )
                : '---'}
            </p>
          </div>
          <div>
            <p className="text-muted text-right p-0 m-0">Resultado atual</p>
            <p className="p-0 m-0 text-right financial-value" style={{ fontWeight: 600 }}>
              <span>
                {canViewForecast
                  ? FORMATTERS.NUMBER(
                      totals.INCOME.total_paid_amount - totals.EXPENSE.total_paid_amount,
                    )
                  : '---'}
              </span>
            </p>
          </div>
        </BalanceContainer>
      </Container>
      <ActionSheet
        isOpen={actionSheetOpen}
        onToggle={() => setActionSheetOpen(!actionSheetOpen)}
        detent="content-height"
        prefersReducedMotion
      >
        <div>
          <div className="p-3">
            {(!isEmpty(transactionFilters) || !isEmpty(searchCriteria)) && (
              <div className="bg-gray">
                <p className="d-flex justify-content-center align-items-center">
                  <FaExclamationCircle className="text-yellow mr-1" />A soma está
                  considerando os filtros aplicados
                </p>
              </div>
            )}
            <Row className="p-0">
              <Col xs="6">
                <p className="d-flex justify-content-center align-items-center">
                  <IoArrowUp className="text-success mr-1" />
                  RECEBIMENTOS
                </p>
                <ProgressBar
                  animated
                  now={getPercentage('INCOME') || 0}
                  variant="success"
                />
                <Line
                  label="Realizado"
                  value={totals.INCOME.total_paid_amount}
                  canView={canViewIncomes}
                />
                <Line
                  label="Falta"
                  value={totals.INCOME.total_amount - totals.INCOME.total_paid_amount}
                  canView={canViewIncomes}
                />
                <Line
                  label="Previsto"
                  value={totals.INCOME.total_amount}
                  canView={canViewIncomes}
                  muted
                />
              </Col>
              <Col xs="6">
                <p className="d-flex justify-content-center align-items-center">
                  <IoArrowDown className="text-danger mr-1" />
                  DESPESAS
                </p>
                <ProgressBar
                  animated
                  now={getPercentage('EXPENSE') || 0}
                  variant="danger"
                />
                <Line
                  label="Realizado"
                  value={totals.EXPENSE.total_paid_amount}
                  canView={canViewExpenses}
                />
                <Line
                  label="Falta"
                  value={totals.EXPENSE.total_amount - totals.EXPENSE.total_paid_amount}
                  canView={canViewExpenses}
                />
                <Line
                  label="Previsto"
                  value={totals.EXPENSE.total_amount}
                  canView={canViewExpenses}
                  muted
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <p className="p-0 m-0">RESULTADO PREVISTO PARA O MÊS:</p>
              <h6
                className={classNames({
                  'text-muted':
                    Math.abs(totals.INCOME.total_amount - totals.EXPENSE.total_amount) ===
                    0,
                  'p-0': true,
                  'm-0': true,
                  'ml-2': true,
                  'financial-value': true,
                })}
              >
                {canViewForecast
                  ? FORMATTERS.NUMBER(
                      totals.INCOME.total_amount - totals.EXPENSE.total_amount,
                    )
                  : '---'}
              </h6>
            </div>
          </div>
        </div>
      </ActionSheet>
    </>
  );
}

TransactionSummary.defaultProps = {
  transactionFilters: {},
  searchCriteria: '',
  transactions: [],
  canViewIncomes: false,
  canViewExpenses: false,
  canViewForecast: false,
  isTablet: false,
};

TransactionSummary.propTypes = {
  transactionFilters: PropTypes.object,
  searchCriteria: PropTypes.string,
  transactions: PropTypes.array,
  canViewIncomes: PropTypes.bool,
  canViewExpenses: PropTypes.bool,
  canViewForecast: PropTypes.bool,
  isTablet: PropTypes.bool,
};

export default TransactionSummary;
