import { connect } from 'react-redux';
import { compose } from 'redux';

import filesActionsCreators from '_store/_actions/files';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import contactFilesActionCreators from '_store/_actions/contactFiles';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Files from './Files';

const mapStateToProps = state => ({
  files: state.files.items,
  total: state.files.total,
  activeCompany: state.company.activeCompany,
  isLoadingFiles: state.files.loading,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchFiles: filesActionsCreators.fetchFiles,
  onDeleteTransactionFile: companyTransactionsActionCreators.deleteTransactionFiles,
  onDeleteContactFile: contactFilesActionCreators.deleteContactFiles,
  onFetchStorage: filesActionsCreators.getNewConsumedStorage,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['file_view'], 'all', true),
)(Files);
