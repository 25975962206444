import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { LuPlus, LuTrash, LuPencil } from 'react-icons/lu';

import FORMATTERS from 'helpers/formatters';
import { CopyToClipboard, Button } from '_components/_core';

import { ApiKeysModal } from './components';

import EmptyMessage from '../../../EmptyMessage';
import { CustomCard, CustomCardBody, CustomCardHeader } from '../../../../styles';

import { StyledTable, ActiveKeys } from './styles.js';

const ApiKeys = ({ isLoading, apiKeys, onDeleteKey, onFetchKeys, activeCompany }) => {
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);

  useEffect(() => {
    onFetchKeys();
  }, [onFetchKeys, activeCompany]);

  const handleOpenCreateModal = useCallback(() => {
    setSelectedApiKey(null);
    setIsApiKeyModalOpen(true);
  }, []);

  const handleOpenEditModal = useCallback(apiKey => {
    setSelectedApiKey(apiKey);
    setIsApiKeyModalOpen(true);
  }, []);

  const handleDeleteKey = useCallback(
    keyId => {
      onDeleteKey(keyId, () => {
        onFetchKeys();
      });
    },
    [onDeleteKey, onFetchKeys],
  );

  return (
    <>
      <ApiKeysModal
        isVisible={isApiKeyModalOpen}
        onModalToggle={() => setIsApiKeyModalOpen(!isApiKeyModalOpen)}
        apiKey={selectedApiKey}
      />
      <CustomCard>
        <CustomCardHeader className="d-flex justify-content-between align-items-center">
          <h3>Chaves de API</h3>
          <div className="d-flex">
            <Button
              size="sm"
              variant="dark"
              className="d-flex justify-content-center ml-2"
              onClick={handleOpenCreateModal}
            >
              Nova chave de API
              <LuPlus className="ml-1" />
            </Button>
          </div>
        </CustomCardHeader>
        <CustomCardBody noPadding>
          {isEmpty(apiKeys) && <EmptyMessage />}
          <div className="table-responsive">
            {!isEmpty(apiKeys) && (
              <StyledTable className={classNames({ 'table table-hover': true })}>
                <thead>
                  <tr>
                    <th className="text-left">Nome / Descrição</th>
                    <th className="text-left">Chave</th>
                    <th className="text-center">Data de expiração</th>
                    <th width="5%" className="text-center">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.map(apiKey => {
                    const { id, expire_at } = apiKey;
                    const expireDate = expire_at;
                    const relativeDate = DateTime.fromISO(expire_at)
                      .setLocale('pt-BR')
                      .toRelativeCalendar();

                    return (
                      <tr key={id}>
                        <td>
                          <ActiveKeys className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <strong>{apiKey.name}</strong>
                              <div className="mt-2 text-muted">
                                {apiKey.description || <i>Sem descrição</i>}
                              </div>
                            </div>
                          </ActiveKeys>
                        </td>
                        <td>
                          <CopyToClipboard
                            text={apiKey.api_key}
                            emptyText="Sem documento"
                          >
                            {apiKey.api_key}
                          </CopyToClipboard>
                        </td>
                        <td className="text-center">
                          {!expireDate && 'Nunca'}
                          {expireDate !== null && (
                            <>
                              {FORMATTERS.DATE_DDMMYYYY(expireDate)} ({relativeDate})
                            </>
                          )}
                        </td>
                        <td className="text-center">
                          <span className="d-flex justify-content-center align-items-center bg-black">
                            <Button
                              size="sm"
                              variant="default"
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleOpenEditModal(apiKey)}
                              isLoading={isLoading}
                              disabled={isLoading}
                            >
                              <LuPencil />
                            </Button>
                            <Button
                              size="sm"
                              variant="inverse-danger"
                              className="ml-2 d-flex justify-content-center align-items-center"
                              onClick={() => handleDeleteKey(id)}
                              disabled={isLoading}
                              isLoading={isLoading}
                            >
                              <LuTrash />
                            </Button>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </StyledTable>
            )}
          </div>
        </CustomCardBody>
      </CustomCard>
    </>
  );
};

ApiKeys.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDeleteKey: PropTypes.func.isRequired,
  onFetchKeys: PropTypes.func.isRequired,
  activeCompany: PropTypes.object.isRequired,
};

export default ApiKeys;
