import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

export const PrivacyModeContext = createContext({
  privacyModeEnabled: false,
  togglePrivacyMode: () => {},
});

export const PrivacyModeProvider = ({ children }) => {
  const [privacyModeEnabled, setPrivacyModeEnabled] = useState(() => {
    const savedState = localStorage.getItem('privacy-mode-enabled');

    return savedState ? JSON.parse(savedState) : false;
  });

  const togglePrivacyMode = () => {
    setPrivacyModeEnabled(prevState => !prevState);
  };

  useEffect(() => {
    localStorage.setItem('privacy-mode-enabled', JSON.stringify(privacyModeEnabled));

    if (privacyModeEnabled) {
      document.body.classList.add('privacy-mode-active');
    } else {
      document.body.classList.remove('privacy-mode-active');
    }
  }, [privacyModeEnabled]);

  return (
    <PrivacyModeContext.Provider value={{ privacyModeEnabled, togglePrivacyMode }}>
      {children}
    </PrivacyModeContext.Provider>
  );
};

export const usePrivacyMode = () => useContext(PrivacyModeContext);

PrivacyModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
