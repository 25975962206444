import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { DateTime } from 'luxon';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { Formik, useFormikContext } from 'formik';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import classNames from 'classnames';

import { FastFilterSelect } from '_components/_core';

import FORMATTERS from 'helpers/formatters';
import { checkBlockedPeriod } from 'helpers';
import { PermissionsGate } from '_components/_shared';
import { PAYMENT_STATUS_OPTIONS, EXPENSES_OPTIONS, DATE_OPTIONS } from '.';
import {
  StyledContainer,
  StyledToggleButton,
  StyledDisabledControl,
  StyledDeleteButton,
} from './styles';

const SubmitListener = () => {
  const formik = useFormikContext();
  const [lastValues, updateState] = React.useState(formik.values);

  const submitForm = useCallback(
    debounce(
      () => {
        formik.submitForm();
      },
      100,
      { maxWait: 100 },
    ),
    [],
  );

  React.useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (!valuesEqualLastValues && formik.isValid) {
      submitForm();
    }
  }, [formik.values, formik.initialValues, lastValues, submitForm, formik.isValid]);

  return null;
};

function TransactionFilters({
  activeTab,
  tabsExpanded,
  selectedDate,
  viewType,
  onUpdateTransactionFilters,
  onSetViewType,
  selectedItems,
  transactions,
  onDeleteMultipleTransactions,
  onDeleteTransaction,
  onClearSelectedItems,
  customFilters,
  deletePermission,
  blockedPeriod,
}) {
  const isCurrentMonthSelected = useMemo(() => {
    const now = DateTime.now();
    const selectedMonth = selectedDate.month;
    const selectedYear = selectedDate.year;

    return now.month === selectedMonth && now.year === selectedYear;
  }, [selectedDate]);

  const monthName = useMemo(() => {
    const name = selectedDate.toFormat('MMMM', { locale: 'pt-BR' });

    return name;
  }, [selectedDate]);

  const handleFilter = useCallback(
    values => {
      const params = {};

      if (values.date === 'WEEK') {
        params.event_date_start = DateTime.now().startOf('week').toFormat('yyyy-MM-dd');
        params.event_date_end = DateTime.now().endOf('week').toFormat('yyyy-MM-dd');
      } else if (values.date === 'TODAY') {
        params.event_date_start = DateTime.now().toFormat('yyyy-MM-dd');
        params.event_date_end = DateTime.now().toFormat('yyyy-MM-dd');
      }

      if (values.payment_status === 'PAYED') {
        params.paid = true;
      } else if (values.payment_status === 'ALL_TO_PAY') {
        params.paid = false;
      } else if (values.payment_status === 'EXPIRING_TODAY') {
        params.paid = false;
        params.event_date_start = DateTime.now().toFormat('yyyy-MM-dd');
        params.event_date_end = DateTime.now().toFormat('yyyy-MM-dd');
      } else if (values.payment_status === 'EXPIRED') {
        params.paid = false;
        params.event_date_end = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd');
      }

      if (values.expenses === 'FIXED_EXPENSE') {
        params.type = 'EXPENSE';
        params.sub_type = 'FIXED_EXPENSE';
      } else if (values.expenses === 'VARIABLE_EXPENSE') {
        params.type = 'EXPENSE';
        params.sub_type = 'VARIABLE_EXPENSE';
      } else if (values.expenses === 'PEOPLE') {
        params.type = 'EXPENSE';
        params.sub_type = 'PEOPLE';
      } else if (values.expenses === 'TAXES') {
        params.type = 'EXPENSE';
        params.sub_type = 'TAXES';
      }

      localStorage.setItem('transactions_filters_form', JSON.stringify(values));
      onUpdateTransactionFilters(params);
    },
    [onUpdateTransactionFilters],
  );

  const handleDeleteMultipleTransactions = useCallback(() => {
    if (!isEmpty(selectedItems) && selectedItems.length === 1) {
      const correctTransaction = transactions.find(item => item.id === selectedItems[0]);

      onDeleteTransaction(correctTransaction, () => {
        onClearSelectedItems();
      });

      return;
    }

    const params = {
      ids: selectedItems,
    };

    onDeleteMultipleTransactions(params, () => {
      onClearSelectedItems();
    });
  }, [
    onDeleteMultipleTransactions,
    onDeleteTransaction,
    onClearSelectedItems,
    selectedItems,
    transactions,
  ]);

  const totals = useMemo(() => {
    const initial = {
      total_amount: 0,
      total_paid_amount: 0,
      total_unpaid_amount: 0,
    };

    if (!transactions) {
      return initial;
    }

    let finalTransactions = [];

    if (!isEmpty(selectedItems)) {
      finalTransactions = transactions.filter(transaction =>
        selectedItems.includes(transaction.id),
      );
    } else {
      finalTransactions = transactions;
    }

    const totalAmount = finalTransactions.reduce(
      (acc, transaction) => ({
        total_amount: acc.total_amount + transaction.amount,
        total_paid_amount:
          acc.total_paid_amount + (transaction.paid ? transaction.amount : 0),
        total_unpaid_amount:
          acc.total_unpaid_amount + (!transaction.paid ? transaction.amount : 0),
      }),
      initial,
    );

    return totalAmount;
  }, [transactions, selectedItems]);

  const initialValues = useMemo(() => {
    const initial = {
      date: null,
      payment_status: null,
      expenses: null,
    };

    const storedFilters = localStorage.getItem('transactions_filters_form');

    if (storedFilters) {
      return JSON.parse(storedFilters);
    }

    return initial;
  }, []);

  const isBlockedForDeletion = useMemo(() => {
    if (isEmpty(selectedItems)) {
      return false;
    }

    const relatedTransactions = transactions.filter(transaction =>
      selectedItems.includes(transaction.id),
    );

    const hasChildren = relatedTransactions.some(
      transaction => transaction.splits.length > 0,
    );

    let childTransactions = [];

    if (hasChildren) {
      childTransactions = relatedTransactions.reduce(
        (acc, transaction) => [...acc, ...transaction.splits],
        [],
      );
    }

    const allTransactions = [...relatedTransactions, ...childTransactions];

    const isAnyBlocked = allTransactions.some(
      transaction =>
        !checkBlockedPeriod(blockedPeriod, transaction.event_date, transaction.paid),
    );

    return isAnyBlocked;
  }, [blockedPeriod, selectedDate, transactions, selectedItems]);

  if (activeTab === 'TRANSFER') {
    return null;
  }

  if (!isEmpty(selectedItems)) {
    return (
      <StyledContainer variant="selectedItems">
        <div className="d-flex justify-content-center align-items-center">
          <PermissionsGate permissions={[deletePermission]} type="all">
            {!isBlockedForDeletion && (
              <StyledDeleteButton
                className="mr-3"
                variant="inverse-danger"
                onClick={handleDeleteMultipleTransactions}
              >
                Excluir
              </StyledDeleteButton>
            )}
          </PermissionsGate>
          <strong className="font-weight-bold">{selectedItems.length}</strong>
          &nbsp;&nbsp;
          <span>
            transações selecionadas -{' '}
            <span className="font-weight-bold financial-value">
              {FORMATTERS.NUMBER(totals.total_amount)}
            </span>
          </span>
          <small className="d-flex justify-content-center align-items-center text-muted ml-3">
            (dica: clique com o botão direito do mouse na tabela para mais ações em massa)
          </small>
        </div>
        <div className="d-flex justify-content-center">
          <FaTimes size="1.3em" className="mr-3" onClick={onClearSelectedItems} />
        </div>
      </StyledContainer>
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleFilter} enableReinitialize>
      {({ handleSubmit, setFieldValue, values }) => (
        <StyledContainer>
          <Form onSubmit={handleSubmit}>
            <SubmitListener />
            <Form.Row className="p-0">
              {isCurrentMonthSelected && (
                <FastFilterSelect
                  label="Data:"
                  value={values.date}
                  options={DATE_OPTIONS}
                  name="date"
                  onChange={value => {
                    setFieldValue('date', value);
                  }}
                  isChanged={values.date !== null}
                />
              )}
              {!isCurrentMonthSelected && (
                <StyledDisabledControl value={`Data: ${monthName}`} disabled />
              )}
              <FastFilterSelect
                label="Status de Pagamento:"
                value={values.payment_status}
                options={PAYMENT_STATUS_OPTIONS}
                name="payment_status"
                onChange={value => {
                  setFieldValue('payment_status', value);
                }}
                className="ml-2"
                isChanged={values.payment_status !== null}
              />
              {activeTab !== 'INCOME' && !tabsExpanded && (
                <FastFilterSelect
                  name="expenses"
                  options={EXPENSES_OPTIONS}
                  label="Despesas:"
                  value={values.expenses}
                  onChange={value => {
                    setFieldValue('expenses', value);
                  }}
                  className="ml-2"
                  isChanged={values.expenses !== null}
                />
              )}
              <span className="ml-2" style={{ flex: 1 }}>
                {customFilters}
              </span>
              <Form.Group className="ml-auto mr-3">
                <StyledToggleButton
                  variant="link"
                  onClick={() =>
                    onSetViewType(viewType === 'compact' ? 'complete' : 'compact')
                  }
                  className={classNames({
                    'btn btn-sm': true,
                    'd-flex justify-content-center align-items-center': true,
                    'text-muted': true,
                  })}
                >
                  {viewType === 'compact' && <FaEyeSlash className="mr-2" />}
                  {viewType === 'complete' && <FaEye className="mr-2" />}
                  {'Tags / Centro de Custo'}
                </StyledToggleButton>
              </Form.Group>
            </Form.Row>
          </Form>
        </StyledContainer>
      )}
    </Formik>
  );
}

TransactionFilters.defaultProps = {
  customFilters: null,
};

TransactionFilters.propTypes = {
  activeTab: PropTypes.string,
  tabsExpanded: PropTypes.bool,
  selectedDate: PropTypes.object.isRequired,
  viewType: PropTypes.string.isRequired,
  onSetViewType: PropTypes.func,
  selectedItems: PropTypes.array,
  transactions: PropTypes.array,
  onUpdateTransactionFilters: PropTypes.func.isRequired,
  onDeleteMultipleTransactions: PropTypes.func.isRequired,
  onDeleteTransaction: PropTypes.func.isRequired,
  onClearSelectedItems: PropTypes.func.isRequired,
  customFilters: PropTypes.node,
  deletePermission: PropTypes.string.isRequired,
};

export default TransactionFilters;
