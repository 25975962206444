import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { Row, Col } from 'react-bootstrap';

import FORMATTERS from 'helpers/formatters';
import { ItemsPerPage, Pagination } from '_components/_core/Table/components';
import { LoadingIcon } from '_components/_shared';
import { FileList } from '_components/_shared/FileManagement/components';

function CustomFileList({
  files,
  total,
  isLoadingFiles,
  pagination,
  onPageChange,
  onPageSizeChange,
  onPreviewFile,
  onDownloadFile,
  onDeleteFile,
  values,
  fileType,
}) {
  const groupedByDay = useCallback(
    date_type => {
      const grouped = {};

      let date_field = 'created_at';

      if (date_type === 'transaction_date') {
        date_field = 'event_date';
      }

      if (fileType === 'contact') {
        date_field = 'created_at';
      }

      files.forEach(file => {
        const date = FORMATTERS.DATE_DDMMYYYY(file[date_field]);

        if (!grouped[date]) {
          grouped[date] = [];
        }

        grouped[date].push(file);
      });

      return grouped;
    },
    [files, fileType],
  );

  return (
    <>
      {!isLoadingFiles && isEmpty(files) && (
        <div className="mt-5 mb-5">
          <h4 className="text-center">Nenhum arquivo encontrado</h4>
        </div>
      )}

      {isLoadingFiles && (
        <div className="mt-5 mb-5">
          <LoadingIcon text="Carregando..." />
        </div>
      )}

      {!isLoadingFiles && Array.isArray(files) && !isEmpty(files) && (
        <>
          <Row>
            <Col>
              {Object.keys(groupedByDay(values.date_type)).map(date => (
                <React.Fragment key={date}>
                  <div className="p-3">
                    <p className="mb-0 font-weight-bold">{date}</p>
                  </div>
                  <FileList
                    files={orderBy(groupedByDay(values.date_type)[date], [
                      item => item.file_name.toLowerCase(),
                    ])}
                    onPreviewFile={onPreviewFile}
                    onDownloadFile={onDownloadFile}
                    onDeleteFile={onDeleteFile}
                    showUploadDate={false}
                    noMarginTop
                  />
                </React.Fragment>
              ))}
            </Col>
          </Row>
          <Row>
            <Col xs={7} className="d-flex justify-content-start">
              <ItemsPerPage
                itemsPerPage={pagination.itemsPerPage}
                onChange={onPageSizeChange}
                noMarginsOnTotals
                className="mr-3 ml-3"
                total={total}
                totalBeingShown={files.length}
                maxItemsPerPage={50}
              />
            </Col>
            <Col xs={5} className="d-flex justify-content-end">
              <Pagination
                {...pagination}
                className="mr-3"
                total={total}
                onPageChange={onPageChange}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

CustomFileList.propTypes = {
  files: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  isLoadingFiles: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onPreviewFile: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
};

export default CustomFileList;
