import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';

const uploadPaymentsConfirm = (params, callback) => {
  return dispatch => {
    dispatch(request(params));

    service
      .uploadPaymentsConfirm(params)
      .then(response => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request(params) {
    return {
      type: constants.UPLOAD_PAYMENTS_FILE_CONFIRM_REQUEST,
      payload: params,
    };
  }
  function success(uploadResults) {
    return {
      type: constants.UPLOAD_PAYMENTS_FILE_CONFIRM_SUCCESS,
      payload: uploadResults,
    };
  }
  function failure(error) {
    return {
      type: constants.UPLOAD_PAYMENTS_FILE_CONFIRM_FAILURE,
      payload: { error },
    };
  }
};

export default uploadPaymentsConfirm;
