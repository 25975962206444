import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FileManagement } from '_components/_shared';

function ContactFiles({
  isFetchingFiles,
  files,
  tempFiles,
  contact,
  onFilesUpload,
  onFilesDelete,
  onFetchContactFiles,
  onClearContactFiles,
  onFilesSelected,
}) {
  useEffect(() => {
    onClearContactFiles();

    const contact_id = contact.id;

    if (!contact_id) {
      return;
    }

    onFetchContactFiles(contact_id);
  }, [onFetchContactFiles, onClearContactFiles, contact]);

  return (
    <FileManagement
      files={files}
      tempFiles={tempFiles}
      isFetching={isFetchingFiles}
      entityId={contact.id}
      entityType="contact"
      onUpload={onFilesUpload}
      onDelete={onFilesDelete}
      onFilesSelected={onFilesSelected}
      emptyMessage="Nenhum arquivo anexado a esse contato."
      formFieldLabel="Arquivos desse contato"
    />
  );
}

ContactFiles.defaultProps = {
  contact: {},
  isFetchingFiles: false,
  tempFiles: [],
  files: [],
  onFilesSelected: () => {},
};

ContactFiles.propTypes = {
  contact: PropTypes.object,
  files: PropTypes.array,
  tempFiles: PropTypes.array,
  isFetchingFiles: PropTypes.bool,
  onFilesUpload: PropTypes.func.isRequired,
  onFilesDelete: PropTypes.func.isRequired,
  onFetchContactFiles: PropTypes.func.isRequired,
  onClearContactFiles: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
};

export default ContactFiles;
