export const contactsConstants = {
  // Contact Files
  UPLOAD_CONTACT_FILES_REQUEST: 'UPLOAD_CONTACT_FILES_REQUEST',
  UPLOAD_CONTACT_FILES_SUCCESS: 'UPLOAD_CONTACT_FILES_SUCCESS',
  UPLOAD_CONTACT_FILES_FAILURE: 'UPLOAD_CONTACT_FILES_FAILURE',

  FETCH_CONTACT_FILES_REQUEST: 'FETCH_CONTACT_FILES_REQUEST',
  FETCH_CONTACT_FILES_SUCCESS: 'FETCH_CONTACT_FILES_SUCCESS',
  FETCH_CONTACT_FILES_FAILURE: 'FETCH_CONTACT_FILES_FAILURE',

  DELETE_CONTACT_FILES_REQUEST: 'DELETE_CONTACT_FILES_REQUEST',
  DELETE_CONTACT_FILES_SUCCESS: 'DELETE_CONTACT_FILES_SUCCESS',
  DELETE_CONTACT_FILES_FAILURE: 'DELETE_CONTACT_FILES_FAILURE',

  CLEAR_CONTACT_FILES: 'CLEAR_CONTACT_FILES',
};
