import { FaIdBadge, FaQuestionCircle, FaTruck, FaUser } from 'react-icons/fa';
import pluralize from 'pluralize';

import { Tag } from '_components/_core';

pluralize.addIrregularRule('item', 'itens');

export const TYPES_2 = {
  CUSTOMER: 'Cliente',
  SUPPLIER: 'Fornecedor',
  EMPLOYEE: 'Funcionário',
  [null]: 'Não definido',
};

export const TYPES_ADD = {
  CUSTOMER: 'Cliente',
  SUPPLIER: 'Fornecedor',
  EMPLOYEE: 'Funcionário',
  [null]: 'Contato',
};

export const TYPES_PLURAL = {
  CUSTOMER: 'Clientes',
  SUPPLIER: 'Fornecedores',
  EMPLOYEE: 'Funcionários',
  [null]: 'Outros',
};

export const TYPES_VARIANT = {
  CUSTOMER: 'success',
  SUPPLIER: 'warning',
  EMPLOYEE: 'primary',
  [null]: 'default',
};

export const TYPES_ICONS = {
  CUSTOMER: <FaUser />,
  SUPPLIER: <FaTruck />,
  EMPLOYEE: <FaIdBadge />,
  [null]: <FaQuestionCircle />,
};

export const TYPES_TAG_ICON = {
  CUSTOMER: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.CUSTOMER}>
      {TYPES_ICONS.CUSTOMER}
    </Tag>
  ),
  SUPPLIER: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.SUPPLIER}>
      {TYPES_ICONS.SUPPLIER}
    </Tag>
  ),
  EMPLOYEE: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.EMPLOYEE}>
      {TYPES_ICONS.EMPLOYEE}
    </Tag>
  ),
  [null]: (
    <Tag className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2" variant={TYPES_VARIANT.null}>
      {TYPES_ICONS.null}
    </Tag>
  ),
};

export const AGE_TRANSLATION = {
  MORE_THAN_60_DAYS: 'mais de 60 dias',
  BETWEEN_30_AND_60_DAYS: 'de 30 a 60 dias',
  LESS_THAN_30_DAYS: 'até 30 dias',
};

export const AGE_COLOR_INCOME = {
  MORE_THAN_60_DAYS: '#48C404',
  BETWEEN_30_AND_60_DAYS: '#76DF47',
  LESS_THAN_30_DAYS: '#D7F7C2',
};

export const AGE_COLOR_EXPENSE = {
  MORE_THAN_60_DAYS: '#FF8F0E',
  BETWEEN_30_AND_60_DAYS: '#FCBD3A',
  LESS_THAN_30_DAYS: '#FCEDB9',
};

export const SUMMARY_CONFIG_INCOME = {
  MORE_THAN_60_DAYS: {
    color: '#48C404',
    id: 'income-3',
  },
  BETWEEN_30_AND_60_DAYS: {
    color: '#76DF47',
    id: 'income-2',
  },
  LESS_THAN_30_DAYS: {
    color: '#D7F7C2',
    id: 'income-1',
  },
};

export const SUMMARY_CONFIG_EXPENSE = {
  MORE_THAN_60_DAYS: {
    color: '#FF8F0E',
    id: 'expense-3',
  },
  BETWEEN_30_AND_60_DAYS: {
    color: '#FCBD3A',
    id: 'expense-2',
  },
  LESS_THAN_30_DAYS: {
    color: '#FCEDB9',
    id: 'expense-1',
  },
};

export const TYPES_OPTIONS = [
  { value: null, label: 'Todos' },
  { value: 'CUSTOMER', label: 'Clientes' },
  { value: 'SUPPLIER', label: 'Fornecedores' },
  { value: 'EMPLOYEE', label: 'Funcionários' },
];

export const ROW_HEIGHT = 47.5;
