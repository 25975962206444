import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { LuDownload } from 'react-icons/lu';

import { LoadingIcon } from '_components/_shared';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 90%;
    height: 90%;
    margin: 1.75rem auto;
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  iframe, img {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

function FileViewer({ isVisible, fileForPreview, onModalToggle, onDownloadFile }) {
  const [isLoading, setIsLoading] = useState(true);

  const renderFilePreview = () => {
    if (!fileForPreview) {
      return null;
    }

    const fileUrl = fileForPreview.signed_url || fileForPreview.preview;
    const fileName = fileForPreview.file_name || fileForPreview.path;

    if (!fileUrl) {
      return <div>Não foi possível visualizar este arquivo.</div>;
    }

    const fileExtension = fileName.split('.').pop().toLowerCase();

    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      return (
        <img
          src={fileUrl}
          alt={fileName}
          onLoad={() => setIsLoading(false)}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      );
    }

    if (fileExtension === 'pdf') {
      return (
        <iframe
          src={fileUrl}
          title={fileName}
          onLoad={() => setIsLoading(false)}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      );
    }

    return (
      <div className="p-4 text-center">
        <p>Visualização não disponível para este tipo de arquivo.</p>
        <p>Faça o download para visualizar.</p>
      </div>
    );
  };

  const handleDownload = useCallback(() => {
    if (fileForPreview) {
      onDownloadFile(fileForPreview);
    }
  }, [fileForPreview, onDownloadFile]);

  return (
    <StyledModal show={isVisible} onHide={onModalToggle} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {fileForPreview && (fileForPreview.file_name || fileForPreview.path)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white p-0">
        {isLoading && <LoadingIcon text="Carregando visualização..." />}
        {renderFilePreview()}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={onModalToggle}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="d-flex align-items-center"
          onClick={handleDownload}
        >
          <LuDownload className="mr-2" />
          Download
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
}

FileViewer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  fileForPreview: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
};

FileViewer.defaultProps = {
  fileForPreview: null,
};

export default FileViewer;
