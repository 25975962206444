import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LuSend } from 'react-icons/lu';

import {
  FormTextField,
  FormSelectField,
  FormDateField,
  Button,
  FormTextAreaField,
} from '_components/_core';

import ValidationSchema from './utilities/ValidationSchema';

import {
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  CustomCardHeader,
} from '../../../../styles';

const Notifications = ({ onCreateSingleNotification, isLoading }) => {
  const handleCreateNotification = values => {
    onCreateSingleNotification(values);
  };

  const options = [
    { value: 'default', label: 'Padrão' },
    { value: 'info', label: 'Informação' },
    { value: 'success', label: 'Sucesso' },
    { value: 'warning', label: 'Aviso' },
    { value: 'error', label: 'Erro' },
  ];

  return (
    <Formik
      initialValues={{
        user_id: '',
        title: '',
        description: '',
        content: '',
        variant: 'info',
        important: false,
        redirect_url: '',
        campaign_name: '',
        send_at: '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={handleCreateNotification}
    >
      {({ handleSubmit, errors, touched, resetForm }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <CustomCard noBorders>
                <CustomCardHeader>
                  <h3>Criar notificação para um usuário</h3>
                </CustomCardHeader>
                <CustomCardBody>
                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">ID do Usuário</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={9}>
                      <Form.Group>
                        <FormTextField
                          name="user_id"
                          placeholder="ID do usuário"
                          className={`form-control ${errors.user_id && touched.user_id ? 'is-invalid' : ''}`}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">Tipo</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <FormSelectField
                          name="variant"
                          options={options}
                          placeholder="Tipo de notificação"
                          isSearchable={false}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="d-flex align-items-center justify-content-center">
                        <Form.Label className="m-0 mt-1">Importante?</Form.Label>
                        <FormSelectField
                          isSearchable={false}
                          name="important"
                          options={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' },
                          ]}
                          className="w-100 ml-2"
                          placeholder="Importante"
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">Título</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={9}>
                      <Form.Group>
                        <FormTextField
                          name="title"
                          placeholder="Título da notificação"
                          className={`form-control ${errors.title && touched.title ? 'is-invalid' : ''}`}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">Descrição</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={9}>
                      <Form.Group>
                        <FormTextField
                          name="description"
                          placeholder="Descrição da notificação"
                          className={`form-control ${errors.description && touched.description ? 'is-invalid' : ''}`}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">Conteúdo</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={9}>
                      <Form.Group>
                        <FormTextAreaField
                          name="content"
                          placeholder="Conteúdo da notificação"
                          className={`form-control ${errors.content && touched.content ? 'is-invalid' : ''}`}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">Nome da campanha</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={9}>
                      <Form.Group>
                        <FormTextField
                          name="campaign_name"
                          placeholder="Nome da campanha"
                          className={`form-control ${errors.campaign_name && touched.campaign_name ? 'is-invalid' : ''}`}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">Redirecionar para</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={9}>
                      <Form.Group>
                        <FormTextField
                          name="redirect_url"
                          placeholder="URL de redirecionamento"
                          className={`form-control ${errors.redirect_url && touched.redirect_url ? 'is-invalid' : ''}`}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md={3}>
                      <Form.Group className="m-0 d-flex align-items-center">
                        <Form.Label className="m-0 mt-2">Enviar em</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={9}>
                      <Form.Group>
                        <FormDateField
                          name="send_at"
                          placeholder="Data de envio"
                          className={`form-control ${errors.send_at && touched.send_at ? 'is-invalid' : ''}`}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </CustomCardBody>
                <CustomCardFooter className="d-flex flex-column justify-content-center align-items-center">
                  <Button
                    variant="success"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    disabled={isLoading}
                    className="d-flex align-items-center mt-3"
                  >
                    Enviar notificação
                    <LuSend className="ml-2" />
                  </Button>
                  <Button
                    variant="link"
                    type="reset"
                    size="sm"
                    className="mt-2"
                    disabled={isLoading}
                    onClick={resetForm}
                  >
                    Cancelar
                  </Button>
                </CustomCardFooter>
              </CustomCard>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

Notifications.propTypes = {
  onCreateSingleNotification: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default Notifications;
