export const TRANSLATIONS = {
  TRANSACTION: 'uma transação',
  CONTACT: 'um contato',
  BANK_ACCOUNT: 'uma conta bancária',
  TAGS: 'uma tag',
  COST_CENTER: 'um centro de custo',
  CATEGORY: 'uma categoria',
  TRANSACTION_FILES: 'um arquivo',
  TRANSFERS: 'uma transferência',
  INVITATION: 'um convite',
  PERMISSIONS: 'uma permissão',
  USER: 'um usuário',
  SESSION: 'uma sessão',
  INSTALMENT: 'um parcelamento',
  RECURRENCE: 'uma recorrência',
  SUBSCRIPTION: 'uma assinatura',
  API_KEYS: 'uma chave de API',
  WEBHOOKS: 'um webhook',
};

export const ACTION_TRANSLATION = {
  INSERT: 'criou',
  UPDATE: 'atualizou',
  DELETE: 'excluiu',
  CUSTOM: 'custom',
};

export const ACTION_VARIANT = {
  INSERT: 'success',
  UPDATE: 'warning',
  DELETE: 'danger',
  CUSTOM: 'default',
};

export const ACTIONS_DROPDOWN = [
  {
    label: 'Criação',
    value: 'INSERT',
  },
  {
    label: 'Atualização',
    value: 'UPDATE',
  },
  {
    label: 'Exclusão',
    value: 'DELETE',
  },
  {
    label: 'Outras',
    value: 'CUSTOM',
  },
];

export const ENTITY_TRANSLATION = {
  TRANSACTION: 'Transações',
  CONTACT: 'Contatos',
  BANK_ACCOUNT: 'Contas Bancárias',
  TAGS: 'Tags',
  COST_CENTER: 'Centros de Custo',
  CATEGORY: 'Categorias',
  TRANSACTION_FILES: 'Arquivos',
  TRANSFERS: 'Transferências',
  INVITATION: 'Convites',
  PERMISSIONS: 'Permissões',
  USER: 'Usuários',
  SESSION: 'Sessões',
  INSTALMENT: 'Parcelamentos',
  RECURRENCE: 'Recorrências',
};
