import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

const isFormValuesChanged = (values = {}, initialValues = {}) => {
  const changedValues = Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = !isEqual(initialValues[key], value);

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});

  return !isEmpty(changedValues);
};

export default isFormValuesChanged;
