import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { IoArrowBackOutline, IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Dropzone,
  FormDateField,
  PageHeader,
  Tabs,
} from '_components/_core';
import { AccountSelect } from '_components/_shared';

import { ConfirmPayoutUploadSchema } from './utilities';
import { StyledTable } from './styles';

function TransactionsUpload({
  onFetchAccounts,
  onUploadPayments,
  onUploadPaymentsConfirm,
}) {
  const history = useHistory();

  const [selectedBankStatements, setSelectedBankStatements] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [foundData, setFoundData] = useState([]);

  useEffect(() => {
    onFetchAccounts();
  }, [onFetchAccounts]);

  const handleFilesChanged = useCallback(files => {
    setSelectedBankStatements(files);
  }, []);

  const handleAccountSelected = useCallback(value => {
    if (value) {
      setSelectedAccount(value.value);
    } else {
      setSelectedAccount(null);
    }
  }, []);

  const handleSubmitBankStatement = useCallback(
    values => {
      if (isEmpty(selectedBankStatements) || !selectedAccount) return;

      setIsLoading(true);

      const callback = data => {
        setFoundData(data);
        setEventDate(values.event_date);

        setIsLoading(false);
      };

      onUploadPayments(
        {
          account_id: selectedAccount,
          bank_statements: selectedBankStatements,
          event_date: values.event_date,
        },
        callback,
      );
    },
    [selectedBankStatements, selectedAccount, onUploadPayments],
  );

  const handleConfirmUpload = useCallback(() => {
    if (isEmpty(selectedBankStatements) || !selectedAccount) return;

    setIsLoading(true);

    const params = {
      account_id: selectedAccount,
      bank_statements: selectedBankStatements,
      event_date: eventDate,
    };

    const callback = data => {
      setFoundData(data);

      setIsLoading(false);

      history.push('/transacoes');
    };

    const errorCallback = () => {
      setIsLoading(false);
    };

    onUploadPaymentsConfirm(params, callback, errorCallback);
  }, [
    selectedBankStatements,
    selectedAccount,
    onUploadPaymentsConfirm,
    history,
    eventDate,
  ]);

  const itemsToCreate = useMemo(() => {
    return foundData.filter(i => i.is_new);
  }, [foundData]);

  const itemsToUpdate = useMemo(() => {
    return foundData.filter(i => !i.is_new);
  }, [foundData]);

  const totals = useMemo(() => {
    const total = foundData.reduce(
      (acc, item) => {
        const type = item.transaction.type;

        const amount = item.transaction.amount;

        if (type === 'INCOME') {
          acc.INCOME += amount;
          acc.INCOME_COUNT += 1;
        } else if (type === 'EXPENSE') {
          acc.EXPENSE += amount;
          acc.EXPENSE_COUNT += 1;
        }

        return acc;
      },
      { INCOME: 0, INCOME_COUNT: 0, EXPENSE: 0, EXPENSE_COUNT: 0 },
    );

    return total;
  }, [foundData]);

  const renderTable = useCallback(items => {
    return (
      <div className="w-100">
        <StyledTable className="w-100 table-hover bg-white">
          <thead>
            <tr>
              <th width="200px" className="text-left">
                Data
              </th>
              <th width="180px" className="text-left">
                Tipo
              </th>
              <th className="text-left">Descrição</th>
              <th className="text-left">Recebido de / Pago a</th>
              <th className="text-left">Categoria</th>
              <th width="180px" className="text-right">
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              const { transaction, is_new } = item || {};
              const { event_date, original_event_date } = transaction || {};
              const isSameDate = DateTime.fromISO(event_date, { zone: 'utc' })
                .startOf('day')
                .equals(
                  DateTime.fromISO(original_event_date, { zone: 'utc' }).startOf('day'),
                );

              return (
                <tr key={index}>
                  <td className="text-left">
                    <div className="d-flex flex-column">
                      <span className="d-flex align-items-center justify-content-start">
                        <span>{FORMATTERS.DATE_DDMMYYYY(transaction.event_date)}</span>
                        <span>
                          {transaction.paid && (
                            <FaCheckCircle className="text-success mt-1 ml-2" />
                          )}
                          {!transaction.paid && (
                            <FaExclamationCircle className="text-danger mt-1 ml-2" />
                          )}
                        </span>
                      </span>
                      {!is_new && !isSameDate && (
                        <small className="text-warning">
                          Data original:{' '}
                          {FORMATTERS.DATE_DDMMYYYY(transaction.original_event_date)}
                        </small>
                      )}
                    </div>
                  </td>
                  <td className="text-left">
                    <span>
                      {FORMATTERS.TRANSACTION_TYPE_STRING_SEARCH_PAGE(
                        transaction.type,
                        transaction.sub_type,
                      )}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      <span>{transaction.description}</span>
                    </div>
                  </td>

                  <td className="text-left">
                    {transaction.recipient ? transaction.recipient.name : ''}
                  </td>
                  <td className="text-left">
                    {transaction.category ? transaction.category.description : ''}
                  </td>
                  <td className="text-right">
                    <div className="d-flex flex-column">
                      <span>
                        {FORMATTERS.REPORT_AMOUNT(
                          transaction.amount,
                          transaction.type,
                          transaction.sub_type,
                          false,
                        )}
                      </span>
                      {!is_new && transaction.original_amount !== transaction.amount && (
                        <small className="text-warning">
                          Valor original:{' '}
                          {FORMATTERS.REPORT_AMOUNT(
                            transaction.original_amount,
                            transaction.type,
                            transaction.sub_type,
                            false,
                          )}
                        </small>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </div>
    );
  }, []);

  const getTabs = useCallback(
    values => {
      return [
        {
          id: 1,
          title: `Itens existentes (${itemsToUpdate.length})`,
          content: <div>{renderTable(itemsToUpdate, values)}</div>,
        },
        {
          id: 2,
          title: `Novos itens (${itemsToCreate.length})`,
          content: <div>{renderTable(itemsToCreate, values)}</div>,
        },
      ];
    },
    [itemsToCreate, itemsToUpdate, renderTable],
  );

  return (
    <Container fluid className="content-wrapper">
      <PageHeader
        title={
          <div>
            <Button
              variant="icon"
              className="m-0 p-0 mr-3"
              onClick={() => history.goBack()}
            >
              <IoArrowBackOutline size="1.5em" />
            </Button>
            <span>Importar Pagamentos</span>
          </div>
        }
        sideContent={
          !isEmpty(foundData) && (
            <Button
              variant="success-2"
              isLoading={isLoading}
              onClick={handleConfirmUpload}
            >
              Confirmar importação ({itemsToCreate.length + itemsToUpdate.length} itens)
            </Button>
          )
        }
        variant="small"
      />
      {isEmpty(foundData) && (
        <Formik
          initialValues={{
            account_id: null,
            bank_statements: [],
            event_date: null,
          }}
          validationSchema={ConfirmPayoutUploadSchema}
          onSubmit={handleSubmitBankStatement}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <Form>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Conta bancária</Form.Label>
                            <AccountSelect
                              onChange={handleAccountSelected}
                              value={[selectedAccount]}
                              creatable="transaction_account"
                              account_type="transaction_account"
                              isClearable
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Data de recebimento</Form.Label>
                            <FormDateField
                              name="event_date"
                              placeholder="Data em que o repasse ficou disponível em conta"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>Selecionar arquivo</Form.Label>
                            <Dropzone
                              onChange={handleFilesChanged}
                              accept=".csv"
                              readAsTextWithEncoding
                              showLengthMessage
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col>
                          <Button
                            onClick={handleSubmit}
                            disabled={
                              isLoading ||
                              isEmpty(selectedBankStatements) ||
                              !selectedAccount
                            }
                            isLoading={isLoading}
                          >
                            Importar
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {!isEmpty(foundData) && (
        <>
          <Row>
            <Col>
              <Card>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th width="150px" className="text-center">
                        Qtde
                      </th>
                      <th width="150px" className="text-right">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong className="d-flex align-items-center">
                          <IoArrowUp className="text-success mr-2" />
                          Recebimentos
                        </strong>
                      </td>
                      <td className="text-center">{totals.INCOME_COUNT}</td>
                      <td className="text-right">
                        <span>{FORMATTERS.NUMBER(totals.INCOME)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong className="d-flex align-items-center">
                          <IoArrowDown className="text-danger mr-2" />
                          Despesas
                        </strong>
                      </td>
                      <td className="text-center">{totals.EXPENSE_COUNT}</td>
                      <td className="text-right">
                        <span>{FORMATTERS.NUMBER(totals.EXPENSE)}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <strong>Total líquido</strong>
                      </td>
                      <td className="text-center">
                        {totals.INCOME_COUNT + totals.EXPENSE_COUNT}
                      </td>
                      <td className="text-right">
                        <strong>
                          {FORMATTERS.NUMBER(totals.INCOME - totals.EXPENSE)}
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </StyledTable>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Tabs variant="pills" tabs={getTabs()} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

TransactionsUpload.propTypes = {
  onUploadTransactionsSpreadsheet: PropTypes.func.isRequired,
  onUploadTransactionsSpreadsheetConfirm: PropTypes.func.isRequired,
  onFetchAccounts: PropTypes.func.isRequired,
  onUploadPayments: PropTypes.func.isRequired,
};

export default TransactionsUpload;
