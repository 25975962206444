import constants from '../../_constants/notifications.constants';
import service from '../../../services/notifications.service';

const unreadNotification = (id, callback) => {
  return dispatch => {
    dispatch(request(id));

    service
      .unreadNotification(id)
      .then(response => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request(id) {
    return {
      type: constants.UNREAD_USER_NOTIFICATION_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(data) {
    return {
      type: constants.UNREAD_USER_NOTIFICATION_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.UNREAD_USER_NOTIFICATION_FAILURE,
      payload: error,
    };
  }
};

export default unreadNotification;
