import { contactsConstants } from '_store/_constants';
import alertActions from '_store/_actions/alert.actions';
import service from 'services/contacts.service';

const uploadContactFiles = (formData, contact_id, callback) => {
  return dispatch => {
    dispatch({ type: contactsConstants.UPLOAD_CONTACT_FILES_REQUEST });

    service.uploadContactFiles(formData, contact_id).then(
      response => {
        dispatch({
          type: contactsConstants.UPLOAD_CONTACT_FILES_SUCCESS,
          payload: response.data,
        });

        dispatch(alertActions.success('Arquivos enviados com sucesso.'));

        if (callback) {
          callback(response.data);
        }
      },
      error => {
        dispatch({
          type: contactsConstants.UPLOAD_CONTACT_FILES_FAILURE,
          error,
        });

        dispatch(alertActions.error('Erro ao enviar arquivos.'));
      },
    );
  };
};

export default uploadContactFiles;
