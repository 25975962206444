import React from 'react';
import PropTypes from 'prop-types';
import FORMATTERS from 'helpers/formatters';

import { List, ListItem } from '../styles';

const ExpenseIncomeList = ({ label, items, className, containerClassName }) => (
  <div className={containerClassName}>
    <p className="font-weight-bold text-uppercase">{label}</p>
    <List>
      {items.map(item => (
        <ListItem>
          {item.description}
          <br />
          <strong className={`financial-value ${className}`}>
            {`${FORMATTERS.NUMBER(item.amount)}`}
          </strong>
        </ListItem>
      ))}
    </List>
  </div>
);

export default ExpenseIncomeList;

ExpenseIncomeList.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
};
