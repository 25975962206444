import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { FastFilterSelect } from '_components/_core';

function FormQuickDateField({
  startDateFieldName = 'start_date',
  endDateFieldName = 'end_date',
  quickDateFieldName = 'quick_date',
  defaultValue = 'thisMonth',
  isChanged = null,
  isOptional = false,
}) {
  const formik = useFormikContext();

  const handlePeriodChange = useCallback(
    option => {
      if (option === null || option === '') {
        // Clear date fields when null is selected
        formik.setFieldValue(startDateFieldName, '');
        formik.setFieldValue(endDateFieldName, '');
      } else if (option === 'day') {
        formik.setFieldValue(startDateFieldName, DateTime.now().toFormat('yyyy-MM-dd'));
        formik.setFieldValue(endDateFieldName, DateTime.now().toFormat('yyyy-MM-dd'));
      } else if (option === 'yesterday') {
        formik.setFieldValue(
          startDateFieldName,
          DateTime.now().startOf('day').minus({ days: 1 }).toFormat('yyyy-MM-dd'),
        );
        formik.setFieldValue(
          endDateFieldName,
          DateTime.now().startOf('day').minus({ days: 1 }).toFormat('yyyy-MM-dd'),
        );
      } else if (option === 'last7days') {
        formik.setFieldValue(
          startDateFieldName,
          DateTime.now().startOf('day').minus({ days: 7 }).toFormat('yyyy-MM-dd'),
        );
        formik.setFieldValue(
          endDateFieldName,
          DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
        );
      } else if (option === 'last30days') {
        formik.setFieldValue(
          startDateFieldName,
          DateTime.now().startOf('day').minus({ months: 1 }).toFormat('yyyy-MM-dd'),
        );
        formik.setFieldValue(endDateFieldName, DateTime.now().toFormat('yyyy-MM-dd'));
      } else if (option === 'prevMonth') {
        formik.setFieldValue(
          startDateFieldName,
          DateTime.now().minus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd'),
        );
        formik.setFieldValue(
          endDateFieldName,
          DateTime.now().minus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd'),
        );
      } else if (option === 'thisMonth') {
        formik.setFieldValue(
          startDateFieldName,
          DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        );
        formik.setFieldValue(
          endDateFieldName,
          DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
        );
      } else if (option === 'thisYear') {
        formik.setFieldValue(
          startDateFieldName,
          DateTime.now().startOf('year').toFormat('yyyy-MM-dd'),
        );
        formik.setFieldValue(
          endDateFieldName,
          DateTime.now().endOf('year').toFormat('yyyy-MM-dd'),
        );
      } else if (option === 'previousYear') {
        formik.setFieldValue(
          startDateFieldName,
          DateTime.now().minus({ years: 1 }).startOf('year').toFormat('yyyy-MM-dd'),
        );
        formik.setFieldValue(
          endDateFieldName,
          DateTime.now().minus({ years: 1 }).endOf('year').toFormat('yyyy-MM-dd'),
        );
      }

      if (!isOptional) {
        formik.setFieldValue(quickDateFieldName, option);
      }
    },
    [formik, startDateFieldName, endDateFieldName, quickDateFieldName, isOptional],
  );

  // Build options array with optional empty option if needed
  const baseOptions = [
    { value: 'day', label: 'Hoje' },
    { value: 'yesterday', label: 'Ontem' },
    { value: 'last7days', label: 'Últimos 7 dias' },
    { value: 'last30days', label: 'Últimos 30 dias' },
    { value: 'prevMonth', label: 'Mês passado' },
    { value: 'thisMonth', label: 'Este mês' },
    { value: 'previousYear', label: 'Ano passado' },
    { value: 'thisYear', label: 'Este ano' },
  ];

  const options = isOptional
    ? [{ value: '', label: 'Selecione' }, ...baseOptions]
    : baseOptions;

  // Determine if the field has changed from the default value
  const hasChanged =
    isChanged !== null ? isChanged : formik.values[quickDateFieldName] !== defaultValue;

  return (
    <>
      <FastFilterSelect
        label={isOptional ? null : 'Data rápida:'}
        value={formik.values[quickDateFieldName] || (isOptional ? '' : defaultValue)}
        options={options}
        onChange={handlePeriodChange}
        renderDoubleDots={false}
        renderEmptyText={false}
        triggerStyle={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        hideLabelOnSelect
        isChanged={hasChanged}
      />
    </>
  );
}

FormQuickDateField.propTypes = {
  startDateFieldName: PropTypes.string,
  endDateFieldName: PropTypes.string,
  quickDateFieldName: PropTypes.string,
  defaultValue: PropTypes.string,
  isChanged: PropTypes.bool,
  isOptional: PropTypes.bool,
};

export default FormQuickDateField;
