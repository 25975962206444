import { connect } from 'react-redux';

import DataUpload from './DataUpload';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(DataUpload);
