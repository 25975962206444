import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import FORMATTERS from 'helpers/formatters';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import { BankAccountSelect } from '_components/_shared';
import useTransactions from '_components/Transactions/utilities/useTransactions';

function BankAccountOverview({ balance, variant }) {
  const userPermissions = useSelector(
    state => state.userPermissions.permissions[state.auth.user.id],
  );

  const { selectedAccountIds, onChangeSelectedAccountIds } = useTransactions();

  const handleAccountSelected = useCallback(
    newSelectedAccounts => {
      onChangeSelectedAccountIds(newSelectedAccounts);
    },
    [onChangeSelectedAccountIds],
  );

  const info = useMemo(() => {
    if (isEmpty(selectedAccountIds)) {
      return {
        balance: 0,
        forecast_balance: 0,
      };
    }

    if (selectedAccountIds.length === 1) {
      const [account_id] = selectedAccountIds;

      if (!has(balance, account_id)) {
        return {
          balance: 0,
          forecast_balance: 0,
        };
      }
    }

    const selectedAccounts = selectedAccountIds.map(account_id => balance[account_id]);

    const balanceSum = selectedAccounts.reduce(
      (acc, account = {}) => acc + account.balance,
      0,
    );
    const forecastBalanceSum = selectedAccounts.reduce(
      (acc, account = {}) => acc + account.forecast_balance,
      0,
    );

    return {
      balance: balanceSum,
      forecast_balance: forecastBalanceSum,
    };
  }, [balance, selectedAccountIds]);

  const canViewForecast = useMemo(
    () =>
      hasPermissions({
        permissions: ['view_month_forecast_balance'],
        userPermissions,
        type: 'all',
      }),
    [userPermissions],
  );

  const canViewBalances = useMemo(
    () =>
      hasPermissions({
        permissions: ['view_balance'],
        userPermissions,
        type: 'all',
      }),
    [userPermissions],
  );

  if (variant === 'transactions-mobile') {
    return (
      <div className="d-flex justify-content-between align-items-center bg-yellow">
        <div className="d-flex flex-column justify-content-center align-items-start">
          <small className="text-muted">Prev. de fechamento:</small>
          <small className="text-muted">
            <strong className="financial-value">
              {canViewForecast ? FORMATTERS.NUMBER(info.forecast_balance) : '---'}
            </strong>
          </small>
        </div>
        <BankAccountSelect
          value={selectedAccountIds}
          onChange={handleAccountSelected}
          variant="transactions-page-mobile"
          isMultiple={false}
          displayBalance
          displayPrincipalStar
          canViewBalances={canViewBalances}
        />
      </div>
    );
  }

  return (
    <div>
      <p className="mb-1">CONTA</p>
      <BankAccountSelect
        value={selectedAccountIds}
        onChange={handleAccountSelected}
        variant="transactions-page"
        isMultiple={false}
        displayBalance
        displayPrincipalStar
        canViewBalances={canViewBalances}
      />
      <small className="text-muted">Previsão de fechamento do mês:</small>
      <br />
      <small className="text-muted">
        <strong className="financial-value">
          {canViewForecast ? FORMATTERS.NUMBER(info.forecast_balance) : '---'}
        </strong>
      </small>
    </div>
  );
}

BankAccountOverview.defaultProps = {
  balance: {},
  variant: 'default',
};

BankAccountOverview.propTypes = {
  balance: PropTypes.object,
  variant: PropTypes.string,
};

export default BankAccountOverview;
