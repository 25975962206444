import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import TransactionsUploadSimple from './TransactionsUploadSimple';

const mapStateToProps = state => ({
  isLoading: state.companyTransactions.isLoading,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onUploadTransactionsSpreadsheet:
    companyTransactionsActionCreators.uploadTransactionsSpreadsheet,
  onUploadTransactionsSpreadsheetConfirm:
    companyTransactionsActionCreators.uploadTransactionsSpreadsheetConfirm,
  onFetchAccounts: companyTransactionsActionCreators.fetchAllAccounts,
  onUploadPayments: companyTransactionsActionCreators.uploadPayments,
  onUploadPaymentsConfirm: companyTransactionsActionCreators.uploadPaymentsConfirm,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['import_files']),
)(TransactionsUploadSimple);
