import fetchUserNotifications from './fetchUserNotifcations';
import readNotification from './readNotification';
import archiveNotification from './archiveNotification';
import archiveManyNotifications from './archiveManyNotifications';
import readManyNotifications from './readManyNotifications';
import fetchNewUserNotification from './fetchNewUserNotification';
import createSingleNotification from './createSingleNotification';
import unreadNotification from './unreadNotification';
import unreadManyNotifications from './unreadManyNotifications';

export default {
  fetchUserNotifications,
  readNotification,
  archiveNotification,
  archiveManyNotifications,
  readManyNotifications,
  fetchNewUserNotification,
  createSingleNotification,
  unreadNotification,
  unreadManyNotifications,
};
