import HttpService from './http.service';

async function uploadContactFiles(formData, contact_id) {
  return HttpService.upload(`/contact-files/${contact_id}`, formData);
}

async function fetchContactFiles(contact_id) {
  return HttpService.get(`/contact-files/${contact_id}`);
}

async function deleteContactFiles(file_id) {
  return HttpService.delete(`/contact-files/${file_id}`);
}

export default {
  uploadContactFiles,
  fetchContactFiles,
  deleteContactFiles,
};
