import httpService from './http.service';

export default {
  fetchAllFiles,
  getNewConsumedStorage,
};

async function fetchAllFiles(params) {
  return httpService.get('/files', params);
}

async function getNewConsumedStorage() {
  return httpService.get('/files/storage');
}
