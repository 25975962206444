import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import { Button, Tag } from '_components/_core';
import { LoadingIcon } from '_components/_shared';
import {
  TYPES_ICONS,
  TYPES_PLURAL,
  TYPES_VARIANT,
  ROW_HEIGHT,
} from '../utilities/constants';

import { GroupHeader, StyledFormCheck, StyledListGroupItem } from '../styles';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 47.5,
});

const ContactList = ({
  isLoading,
  filteredRecipients,
  selectedItems,
  selectedContact,
  handleSelectMultiple,
  handleSelectContact,
  handleItemSelected,
  handleEditContact,
  handleDeleteContact,
}) => {
  const renderRow = useCallback(
    ({ index, key, style, parent }) => {
      const recipientRow = filteredRecipients[index];

      if (!recipientRow) {
        return null;
      }

      const { isGroup } = recipientRow;

      if (isGroup) {
        const ids = filteredRecipients
          .filter(recipient => recipient.type === recipientRow.type && recipient.id)
          .map(recipient => recipient.id);

        return (
          <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            {({ registerChild }) => (
              <GroupHeader style={style} ref={registerChild}>
                <StyledFormCheck
                  type="checkbox"
                  checked={
                    selectedItems.length > 0 &&
                    ids.every(item => selectedItems.includes(item))
                  }
                  onClick={e => handleSelectMultiple(e, ids)}
                />
                <Tag
                  className="p-0 pl-2 pr-2 pt-1 pb-1 mr-2"
                  variant={TYPES_VARIANT[recipientRow.type]}
                >
                  {TYPES_ICONS[recipientRow.type]}
                </Tag>
                <h5 className="m-0">
                  {TYPES_PLURAL[recipientRow.type]} ({recipientRow.length})
                </h5>
              </GroupHeader>
            )}
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer
          key={key}
          cache={cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          {({ registerChild }) => (
            <StyledListGroupItem
              style={style}
              className="row"
              action
              onClick={() => handleSelectContact(recipientRow)}
              ref={registerChild}
              isSelected={selectedItems.includes(recipientRow.id)}
              isActive={selectedContact && selectedContact.id === recipientRow.id}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span className="d-flex">
                  <StyledFormCheck
                    type="checkbox"
                    checked={selectedItems.includes(recipientRow.id)}
                    onClick={e => handleItemSelected(e, recipientRow.id)}
                  />
                  <span className="d-flex flex-column ml-2">
                    <span style={{ fontWeight: 600, color: '#071437' }}>
                      {FORMATTERS.MAX_X_CHARS(recipientRow.name, 30)}
                    </span>
                  </span>
                </span>
                <span className="text-muted">
                  <Button
                    size="sm"
                    variant="default"
                    className="m-1 p-1 text-info bg-transparent"
                    onClick={e => handleEditContact(e, recipientRow.id)}
                  >
                    <FaPencilAlt />
                  </Button>
                  <Button
                    size="sm"
                    variant="default"
                    className="m-1 p-1 text-danger bg-transparent"
                    onClick={e => handleDeleteContact(e, recipientRow.id)}
                  >
                    <FaTimes />
                  </Button>
                </span>
              </div>
            </StyledListGroupItem>
          )}
        </CellMeasurer>
      );
    },
    [
      filteredRecipients,
      selectedItems,
      selectedContact,
      handleSelectMultiple,
      handleSelectContact,
      handleItemSelected,
      handleEditContact,
      handleDeleteContact,
    ],
  );

  if (isLoading) {
    return <LoadingIcon text="Aguarde..." className="mt-3 mb-3" />;
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height - 200}
          rowHeight={ROW_HEIGHT}
          rowRenderer={renderRow}
          rowCount={filteredRecipients.length}
          overscanRowCount={3}
        />
      )}
    </AutoSizer>
  );
};

ContactList.propTypes = {
  filteredRecipients: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  selectedContact: PropTypes.object,
  handleSelectMultiple: PropTypes.func.isRequired,
  handleSelectContact: PropTypes.func.isRequired,
  handleItemSelected: PropTypes.func.isRequired,
  handleEditContact: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired,
};

export default ContactList;
