import { connect } from 'react-redux';

import notificationsActionCreators from '_store/_actions/notifications';

import NotificationsDropdown from './NotificationsDropdown';

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
  isLoading: state.notifications.isLoading,
  total: state.notifications.total,
});

const mapDispatchToProps = {
  onMarkAsRead: notificationsActionCreators.readNotification,
  onArchive: notificationsActionCreators.archiveNotification,
  onMarkAllAsRead: notificationsActionCreators.readManyNotifications,
  onArchiveMany: notificationsActionCreators.archiveManyNotifications,
  onFetchNotifications: notificationsActionCreators.fetchUserNotifications,
  onMarkAsUnread: notificationsActionCreators.unreadNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDropdown);
