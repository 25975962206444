import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { getSimpleUuid as uuid } from 'helpers';

import {
  TAX_REGIME,
  LUCRO_PRESUMIDO_IRPJ_OPTIONS,
  LUCRO_PRESUMIDO_CSLL_OPTIONS,
  ANEXO_OPTIONS,
  MEI_TYPE_OPTIONS,
} from 'helpers/constants';

import { Alert, Button, FormDateField, FormSelectField } from '_components/_core';

import ValidationSchema from './utilities/ValidationSchema';

import { StyledModal, ModalFooter } from './styles';

function AddTaxRegimeModal({ isVisible, onToggleModal, onAddTaxRegime }) {
  const handleAddTaxRegime = useCallback(
    (values, { resetForm }) => {
      onAddTaxRegime({
        temp_id: uuid(),
        tax_regime: values.tax_regime,
        start_date: values.start_date,
        metadata: {
          aliquota_irpj:
            values.tax_regime === 'LUCRO_PRESUMIDO' ? values.aliquota_irpj : null,
          aliquota_csll:
            values.tax_regime === 'LUCRO_PRESUMIDO' ? values.aliquota_csll : null,
          anexo: values.tax_regime === 'SIMPLES_NACIONAL' ? values.anexo : null,
          mei_type: values.tax_regime === 'MEI' ? values.mei_type : null,
        },
      });
      resetForm();
      onToggleModal();
    },
    [onAddTaxRegime, onToggleModal],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid) => (
      <ModalFooter>
        <Button variant="secondary" onClick={onToggleModal}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={!isValid}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </ModalFooter>
    ),
    [onToggleModal],
  );

  return (
    <Formik
      initialValues={{
        tax_regime: null,
        start_date: null,
        aliquota_irpj: '1,6%',
        aliquota_csll: '12%',
        anexo: 'FROM_INVOICE',
        mei_type: 'SERVICO',
      }}
      validationSchema={ValidationSchema}
      onSubmit={handleAddTaxRegime}
      enableReinitialize
    >
      {({ handleSubmit, values, isValid }) => (
        <StyledModal
          title="Regime de Tributação"
          isVisible={isVisible}
          toggleModal={onToggleModal}
          footer={renderModalFooter(handleSubmit, isValid)}
        >
          <Form>
            <Form.Row>
              <Form.Group as={Col} md="3">
                <Form.Label>Vigência</Form.Label>
                <FormDateField name="start_date" placeholder="Vigência" />
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Regime de Tributação</Form.Label>
                <FormSelectField
                  name="tax_regime"
                  options={TAX_REGIME}
                  placeholder="Regime de Tributação"
                />
              </Form.Group>
              {values.tax_regime === 'MEI' && (
                <>
                  <Form.Group as={Col} md="5">
                    <Form.Label>Tipo de Atividade</Form.Label>
                    <FormSelectField
                      name="mei_type"
                      options={MEI_TYPE_OPTIONS}
                      placeholder="Tipo de Atividade"
                    />
                  </Form.Group>
                </>
              )}
              {values.tax_regime === 'SIMPLES_NACIONAL' && (
                <>
                  <Form.Group as={Col} md="5">
                    <Form.Label>Anexo</Form.Label>
                    <FormSelectField
                      name="anexo"
                      options={ANEXO_OPTIONS}
                      placeholder="Anexo"
                    />
                  </Form.Group>
                </>
              )}
            </Form.Row>
            <Form.Row>
              {values.tax_regime === 'SIMPLES_NACIONAL' && (
                <Form.Row>
                  {values.anexo === 'V' && (
                    <Col>
                      <Alert
                        title="Anexo V (Fator R)"
                        variant="info"
                        description="Caso o montante da Folha de Pagamento seja superior à 28% do faturamento nos 12 meses anteriores, a tributação será feita pelo Anexo III."
                      />
                    </Col>
                  )}
                </Form.Row>
              )}
              {values.tax_regime === 'LUCRO_PRESUMIDO' && (
                <>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Alíquota IRPJ</Form.Label>
                    <FormSelectField
                      name="aliquota_irpj"
                      options={LUCRO_PRESUMIDO_IRPJ_OPTIONS}
                      placeholder="Alíquota IRPJ"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Alíquota CSLL</Form.Label>
                    <FormSelectField
                      name="aliquota_csll"
                      options={LUCRO_PRESUMIDO_CSLL_OPTIONS}
                      placeholder="Alíquota CSLL"
                    />
                  </Form.Group>
                </>
              )}
            </Form.Row>
          </Form>
        </StyledModal>
      )}
    </Formik>
  );
}

AddTaxRegimeModal.defaultProps = {
  isVisible: false,
};

AddTaxRegimeModal.propTypes = {
  isVisible: PropTypes.bool,
  onToggleModal: PropTypes.func.isRequired,
  onAddTaxRegime: PropTypes.func.isRequired,
};

export default AddTaxRegimeModal;
