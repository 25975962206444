import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Contacts from './Contacts';

const mapStateToProps = state => ({
  isLoadingRecipients: state.companyTransactions.isLoadingSimpleRecipients,
  allRecipients: state.companyTransactions.recipients,
  totalRecipients: state.companyTransactions.totalRecipients,
  activeCompany: state.company.activeCompany,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchContact: companyTransactionsActionCreators.fetchContactById,
  onFetchContacts: companyTransactionsActionCreators.fetchAllRecipientsSimple,
  onFetchContactsSummary: companyTransactionsActionCreators.fetchContactsSummary,
  onFetchContactsSummaryDetail:
    companyTransactionsActionCreators.fetchContactsSummaryDetail,
  onDeleteContacts: companyTransactionsActionCreators.deleteMultipleContacts,
  onDeleteContact: companyTransactionsActionCreators.deleteContact,
  onCreateContact: companyTransactionsActionCreators.createContact,
  onUpdateMultipleContacts: companyTransactionsActionCreators.updateMultipleContacts,
  onFetchTransactions: companyTransactionsActionCreators.fetchAllTransactions,
  onExportContacts: companyTransactionsActionCreators.exportContacts,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_contacts']),
)(Contacts);
