import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Tag, Dropzone } from '_components/_core';
import { LoadingIcon, PermissionsGate } from '_components/_shared';

function FileUpload({ isFetching, tempFiles, onFilesUpload, label }) {
  return (
    <>
      <PermissionsGate permissions={['file_upload']} type="all">
        <Dropzone
          onChange={files => onFilesUpload(files)}
          accept=".pdf, .png, .jpeg, .jpg, .xlsx, .xml, .docx, .txt"
          maxFiles={5}
          shouldSelectFiles={false}
        />
      </PermissionsGate>

      {isFetching && (
        <div className="mt-3">
          <LoadingIcon text="Carregando arquivos" />
        </div>
      )}

      {!isEmpty(tempFiles) && (
        <Tag className="mt-3" variant="warning">
          Os arquivos serão enviados após clicar em Salvar Alterações
        </Tag>
      )}
    </>
  );
}

FileUpload.propTypes = {
  isFetching: PropTypes.bool,
  tempFiles: PropTypes.array,
  onFilesUpload: PropTypes.func.isRequired,
  label: PropTypes.string,
};

FileUpload.defaultProps = {
  isFetching: false,
  tempFiles: [],
  label: 'Arquivos',
};

export default FileUpload;
