import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const deleteTransactionFiles = (id, callback) => {
  return async dispatch => {
    confirmDialog.warning({
      title: 'Excluir Arquivo',
      message:
        'Tem certeza que deseja excluir este arquivo? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service
          .deleteTransactionFiles(id)
          .then(() => {
            dispatch(success(id));
            dispatch(alertActionCreators.success('Arquivo excluído com sucesso!'));

            if (callback) {
              callback();
            }
          })
          .catch(error => {
            dispatch(failure(error));
          });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_TRANSACTION_FILES_REQUEST,
      payload: {
        id,
      },
    };
  }
  function success(response) {
    return {
      type: constants.DELETE_TRANSACTION_FILES_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_TRANSACTION_FILES_FAILURE,
      payload: {
        id,
        error,
      },
    };
  }
};

export default deleteTransactionFiles;
