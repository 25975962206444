import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody, CardHeader, HintIcon } from '_components/_core';

import { EMPTY_OPTIONS } from './utilities';

function CashflowSemester({ semesterOverview, className, accounts, selectedAccountIds }) {
  const accountName = useMemo(() => {
    if (isEmpty(selectedAccountIds) || isEmpty(accounts)) {
      return '';
    }

    if (selectedAccountIds.length === 1) {
      const [accountId] = selectedAccountIds;

      const account = accounts.find(a => a.id === accountId);

      return account ? account.description : '';
    }

    if (selectedAccountIds.length === accounts.length) {
      return 'Todas as contas';
    }

    const names = accounts
      .filter(a => selectedAccountIds.includes(a.id))
      .map(a => a.description);

    if (names.length > 5) {
      return `${names.slice(0, 5).join(', ')}...e mais ${names.length - 5} contas`;
    }

    return names.join(', ');
  }, [accounts, selectedAccountIds]);

  // const options = useMemo(() => (), [semesterOverview]);

  const getChartOptions = useCallback(() => {
    if (!semesterOverview || isEmpty(semesterOverview.expenses)) {
      return EMPTY_OPTIONS;
    }

    return {
      chart: {
        spacingTop: 25,
        backgroundColor: 'transparent',
        height: 275,
      },
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
      credits: {
        enabled: !1,
      },
      exporting: {
        enabled: !1,
      },
      title: !1,
      xAxis: [
        {
          categories: semesterOverview.period,
          lineColor: '#D4D7DC',
          tickColor: '#D4D7DC',
          fontWeight: 'bold',
          labels: {
            style: {
              color: '#8D9096',
              fontFamily: 'Poppins',
            },
          },
          showFirstLabel: !0,
        },
      ],
      yAxis: {
        title: !1,
        lineColor: '#D4D7DC',
        gridLineColor: '#D4D7DC',
        minorGridLineColor: '#F4F5F8',
        minorGridLineWidth: 1,
        minorTickLength: 0,
        minorTickInterval: 'auto',
        labels: {
          style: {
            color: '#8D9096',
            fontFamily: 'Poppins',
          },
          y: 2,
          formatter() {
            return `<span class="financial-value">${FORMATTERS.NUMBER(this.value)}</span>`;
          },
        },
        plotLines: [
          {
            color: '#393A3D',
            value: 0,
            width: 3,
            zIndex: 5,
          },
        ],
      },
      legend: {
        enabled: !1,
      },
      tooltip: {
        borderRadius: 2,
        shadow: !1,
        style: {
          fontFamily: 'Poppins',
        },
        formatter() {
          return `${this.point.series.name}: <strong class="financial-value">${FORMATTERS.NUMBER(this.y)}</strong>`;
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: !0,
          },
        },
        column: {
          pointPadding: 0.15,
          groupPadding: 0.15,
          borderWidth: 0,
          borderRadius: 1,
          shadow: !1,
          stacking: 'normal',
        },
      },
      series: [
        {
          type: 'column',
          name: 'Despesas',
          color: '#DB504A',
          data: [
            -parseFloat(semesterOverview.expenses[0][1]),
            -parseFloat(semesterOverview.expenses[1][1]),
            -parseFloat(semesterOverview.expenses[2][1]),
            -parseFloat(semesterOverview.expenses[3][1]),
            -parseFloat(semesterOverview.expenses[4][1]),
            -parseFloat(semesterOverview.expenses[5][1]),
          ],
        },
        {
          type: 'column',
          name: 'Recebimentos',
          color: '#4CCEAC',
          data: [
            parseFloat(semesterOverview.incomes[0][1]),
            parseFloat(semesterOverview.incomes[1][1]),
            parseFloat(semesterOverview.incomes[2][1]),
            parseFloat(semesterOverview.incomes[3][1]),
            parseFloat(semesterOverview.incomes[4][1]),
            parseFloat(semesterOverview.incomes[5][1]),
          ],
        },
        {
          type: 'line',
          name: 'Saldo',
          color: '#393A3D',
          lineWidth: 1,
          dashStyle: 'dot',
          marker: {
            radius: 3,
          },
          data: [
            parseFloat(semesterOverview.incomes[0][1]) -
              parseFloat(semesterOverview.expenses[0][1]),
            parseFloat(semesterOverview.incomes[1][1]) -
              parseFloat(semesterOverview.expenses[1][1]),
            parseFloat(semesterOverview.incomes[2][1]) -
              parseFloat(semesterOverview.expenses[2][1]),
            parseFloat(semesterOverview.incomes[3][1]) -
              parseFloat(semesterOverview.expenses[3][1]),
            parseFloat(semesterOverview.incomes[4][1]) -
              parseFloat(semesterOverview.expenses[4][1]),
            parseFloat(semesterOverview.incomes[5][1]) -
              parseFloat(semesterOverview.expenses[5][1]),
          ],
        },
      ],
    };
  }, [semesterOverview]);

  if (!semesterOverview || isEmpty(semesterOverview.expenses)) {
    return null;
  }

  return (
    <Card className={className}>
      <CardHeader
        title="Fluxo de caixa do semestre"
        description={<span className="text-muted">{accountName}</span>}
        leftColSize={10}
        rightColSize={2}
        sideContent={
          <HintIcon hint="Aqui você pode acompanhar a evolução dos seus recebimentos, despesas e saldo (lucro ou prejuízo) nos últimos seis meses." />
        }
        adjustSideContentMobile={false}
      />
      <CardBody noPaddingTop className="pt-0">
        <Row>
          <Col>
            <HighchartsReact highcharts={Highcharts} options={getChartOptions()} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

CashflowSemester.defaultProps = {
  semesterOverview: {},
  className: '',
  accounts: [],
  selectedAccountIds: [],
};

CashflowSemester.propTypes = {
  semesterOverview: PropTypes.object,
  className: PropTypes.string,
  accounts: PropTypes.array,
  selectedAccountIds: PropTypes.array,
};

export default CashflowSemester;
