import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  StyledLabel,
  StyledInput,
  StyledAvatar,
  EditIcon,
  AvatarWrapper,
} from './styles';

export default function AvatarUpload({
  company_id,
  company_avatar_url,
  onCompanyAvatarUpload,
  onCompanyAvatarDelete,
  variant,
}) {
  const handleFilesChanged = useCallback(
    e => {
      if (isEmpty(e)) {
        return;
      }

      const formData = new FormData();

      formData.append('avatar_file', e.target.files[0]);

      onCompanyAvatarUpload(company_id, formData);
    },
    [company_id, onCompanyAvatarUpload],
  );

  const handleDeleteAvatar = useCallback(() => {
    onCompanyAvatarDelete(company_id);
  }, [onCompanyAvatarDelete, company_id]);

  const renderAvatar = useCallback(() => {
    if (company_avatar_url) {
      return (
        <div className="row justify-content-center">
          <StyledLabel htmlFor={`inputTag_${company_id}`}>
            <div role="button" tabIndex={0} onKeyPress onClick={handleDeleteAvatar}>
              <AvatarWrapper variant={variant}>
                <EditIcon variant={variant} src="assets/img/delete-avatar.svg" />
                <StyledAvatar
                  variant={variant}
                  src={company_avatar_url || 'assets/img/placeholder-img.png'}
                  alt="avatar_file_placeholder"
                />
              </AvatarWrapper>
            </div>
          </StyledLabel>
        </div>
      );
    }

    return (
      <div className="row justify-content-center">
        <StyledLabel htmlFor={`inputTag_${company_id}`}>
          <AvatarWrapper variant={variant}>
            <EditIcon variant={variant} src="assets/img/edit-avatar.svg" />
            <StyledAvatar
              variant={variant}
              src="assets/img/placeholder-img.png"
              alt="avatar_file_placeholder"
            />
          </AvatarWrapper>
          <StyledInput
            onChange={handleFilesChanged}
            accept=".png, .jpg, .jpeg"
            maxFiles={1}
            id={`inputTag_${company_id}`}
            type="file"
          />
        </StyledLabel>
      </div>
    );
  }, [company_avatar_url, company_id, handleFilesChanged, handleDeleteAvatar, variant]);

  return <>{renderAvatar()}</>;
}

AvatarUpload.defaultProps = {
  variant: 'default',
};

AvatarUpload.propTypes = {
  company_id: PropTypes.string.isRequired,
  company_avatar_url: PropTypes.string,
  onCompanyAvatarUpload: PropTypes.func,
  onCompanyAvatarDelete: PropTypes.func,
  variant: PropTypes.string,
};
