import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { LuMessageSquarePlus } from 'react-icons/lu';
import { Col, Container, Row } from 'react-bootstrap';

import { isFeatureEnabled } from 'helpers';
import { Tabs } from '_components/_core';

import { Notifications } from './components';

function Admin({ user }) {
  const getTabs = useCallback(() => {
    const tabs = [
      {
        id: 'notifications',
        title: 'Notificações',
        icon: <LuMessageSquarePlus className="mr-2" />,
        content: <Notifications />,
      },
    ];

    return tabs;
  }, []);

  if (!isFeatureEnabled('superadmin', user.flags)) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col>
          <pre>{JSON.stringify(user)}</pre>
          <h4 className="mt-2 mb-3">Administrador</h4>
          <Tabs variant="secondary" tabs={getTabs()} />
        </Col>
      </Row>
    </Container>
  );
}

Admin.propTypes = {
  onCreateSingleNotification: PropTypes.func.isRequired,
};

export default Admin;
