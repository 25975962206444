import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';

import { isFeatureEnabled } from 'helpers';
import { Card, CardBody, CardHeader, PageHeader } from '_components/_core';

import { TypeItem, TypeItemContainer, TypeItemContent, TypeItemIcon } from './styles';

const EXCEL_ICON = 'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/excel.svg';
const OFX_ICON = 'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/ofx.svg';
const STRIPE_ICON = 'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/stripe.jpeg';
// const CONTACTS_ICON = 'https://contabil-easy.s3.sa-east-1.amazonaws.com/assets/contacts (1).png';

function DataUpload({ user }) {
  const history = useHistory();

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="Importar dados" variant="small" />
      <Row>
        <Col>
          <Card>
            <CardHeader title="O que deseja importar?" />
            <CardBody noPaddingTop>
              <Row>
                <Col md={12}>
                  <TypeItemContainer>
                    <TypeItem onClick={() => history.push('/importar-movimentacoes')}>
                      <TypeItemIcon src={EXCEL_ICON} />
                      <TypeItemContent>
                        <h4>Movimentações por planilha</h4>
                        <p>
                          Importe <b>movimentações</b> de outros sistemas, usando nosso
                          formato padrão.
                        </p>
                      </TypeItemContent>
                    </TypeItem>
                    <TypeItem onClick={() => history.push('/importar-extrato')}>
                      <TypeItemIcon src={OFX_ICON} />
                      <TypeItemContent>
                        <h4>Movimentações por extrato OFX</h4>
                        <p>
                          Importe suas movimentações do <b>extrato OFX do seu banco</b>{' '}
                          para o Zenply.
                        </p>
                      </TypeItemContent>
                    </TypeItem>
                    {isFeatureEnabled('payments_upload_stripe', user.flags) && (
                      <TypeItem
                        onClick={() =>
                          history.push('/importar-pagamentos?provider=stripe')
                        }
                      >
                        <TypeItemIcon src={STRIPE_ICON} />
                        <TypeItemContent>
                          <h4>Repasses do Stripe</h4>
                          <p>
                            Importe <b>arquivos de repasse</b> do Stripe para o Zenply e
                            economize tempo na conciliação.
                          </p>
                        </TypeItemContent>
                      </TypeItem>
                    )}
                  </TypeItemContainer>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

DataUpload.defaultProps = {
  activeCompany: null,
};

DataUpload.propTypes = {
  activeCompany: PropTypes.object,
};

export default DataUpload;
