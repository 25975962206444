import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';

import alertActions from '_store/_actions/alert.actions';
import confirmDialog from 'helpers/confirmDialog';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';

import { FileList, FileViewer, FileUpload } from './components';

function FileManagement({
  files,
  tempFiles,
  isFetching,
  entityId,
  entityType,
  onUpload,
  onDelete,
  onFilesSelected,
  emptyMessage,
  formFieldLabel,
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [fileForPreview, setFileForPreview] = useState(null);
  const dispatch = useDispatch();

  const userPermissions = useSelector(
    state => state.userPermissions.permissions[state.auth.user.id],
  );

  const canViewFiles = useMemo(
    () =>
      hasPermissions({
        permissions: ['file_view'],
        userPermissions,
        type: 'all',
      }),
    [userPermissions],
  );

  const handleFilesUpload = useCallback(
    files => {
      const formData = new FormData();

      if (isEmpty(files)) {
        return;
      }

      if (files.length > 5) {
        confirmDialog.open({
          icon: 'error',
          title: 'Muitos arquivos',
          message: 'Por favor, envie no máximo 5 arquivos por upload.',
          confirmButtonText: 'Entendi',
          showCancelButton: false,
        });

        return;
      }

      // Different field names for different entity types
      const fieldName = entityType === 'transaction' ? 'receipt' : 'file';

      files.forEach(file => {
        formData.append(fieldName, file);
      });

      if (!entityId) {
        onFilesSelected(files);
      } else {
        onUpload(formData, entityId);
      }
    },
    [onUpload, onFilesSelected, entityId, entityType],
  );

  const handleDeleteFile = useCallback(
    fileId => {
      onDelete(fileId);
    },
    [onDelete],
  );

  const handlePreviewFile = useCallback(
    file => {
      if (!canViewFiles) {
        dispatch(alertActions.error('Você não tem permissão para visualizar anexos.'));
        return;
      }

      setFileForPreview(file);
      setIsPreviewOpen(true);
    },
    [canViewFiles, dispatch],
  );

  const handleDownloadFile = useCallback(
    file => {
      if (!canViewFiles) {
        dispatch(alertActions.error('Você não tem permissão para visualizar anexos.'));
        return;
      }

      const link = document.createElement('a');
      link.href = file.signed_url;
      link.target = '_blank';
      link.setAttribute('download', file.file_name || file.path);
      document.body.appendChild(link);

      link.click();
      link.remove();
    },
    [canViewFiles, dispatch],
  );

  const handleModalToggle = useCallback(() => {
    setIsPreviewOpen(prevState => !prevState);
  }, []);

  return (
    <Form className="mr-3 ml-3">
      <FileViewer
        isVisible={isPreviewOpen}
        fileForPreview={fileForPreview}
        onModalToggle={handleModalToggle}
        onDownloadFile={handleDownloadFile}
      />
      <Form.Row>
        <Form.Group as={Col} lg={12}>
          <Form.Label>{formFieldLabel}</Form.Label>
          <FileUpload
            isFetching={isFetching}
            tempFiles={tempFiles}
            onFilesUpload={handleFilesUpload}
          />
          <FileList
            isFetching={isFetching}
            files={files}
            tempFiles={tempFiles}
            onPreviewFile={handlePreviewFile}
            onDownloadFile={handleDownloadFile}
            onDeleteFile={handleDeleteFile}
            emptyMessage={emptyMessage}
          />
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

FileManagement.propTypes = {
  files: PropTypes.array,
  tempFiles: PropTypes.array,
  isFetching: PropTypes.bool,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.oneOf(['contact', 'transaction']).isRequired,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
  emptyMessage: PropTypes.string,
  formFieldLabel: PropTypes.string,
};

FileManagement.defaultProps = {
  files: [],
  tempFiles: [],
  isFetching: false,
  entityId: null,
  onFilesSelected: () => {},
  emptyMessage: 'Nenhum arquivo anexado.',
  formFieldLabel: 'Arquivos',
};

export default FileManagement;
