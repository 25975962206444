import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  user_id: Yup.string().required('ID do Usuário é obrigatório').nullable(true),
  title: Yup.string().required('Titulo é obrigatório').nullable(true),
  description: Yup.string().required('Descrição é obrigatória').nullable(true),
  content: Yup.string().required('Conteúdo é obrigatório').nullable(true),
  variant: Yup.string().required('Variant é obrigatório').nullable(true),
  campaign_name: Yup.string().required('Nome da campanha é obrigatório').nullable(true),
  send_at: Yup.date().required('Data de envio é obrigatória').nullable(true),
});

export default validationSchema;
