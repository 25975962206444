import { connect } from 'react-redux';
import { compose } from 'redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import companyActionCreators from '_store/_actions/company';
import ibgeActionCreators from '_store/_actions/ibge';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import ContactForm from './ContactForm';

const mapStateToProps = state => ({
  isSearching: state.company.isSearching,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onCreateContact: companyTransactionsActionCreators.createContact,
  onUpdateContact: companyTransactionsActionCreators.updateContact,
  onSearchCompanyByCnpj: companyActionCreators.searchCompany,
  onClearSearchedCompany: companyActionCreators.clearSearchedCompany,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchContact: companyTransactionsActionCreators.fetchContactById,
  onDeleteContact: companyTransactionsActionCreators.deleteContact,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['manager_contacts']),
)(ContactForm);
