import styled, { css } from 'styled-components';
import { Dropdown, Button } from 'react-bootstrap';

export const StyledFileList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  ${({ noMarginTop }) =>
    noMarginTop &&
    css`
    margin-top: 0;
  `}
`;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  transition: all 0s;
  width: 100%;
  min-width: 0;
`;

export const StyledFile = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  text-align: left;
  background-color: transparent;
  border-color: rgb(241, 241, 244);
  color: #212529;
  padding: 14px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0;
  transition: all 0s;

  &:hover,
  &:focus,
  &:active {
    background-color: rgb(241, 241, 244);
    border-color: rgb(241, 241, 244);
    color: #212529;
  }
`;

export const StyledFileName = styled.div`
  font-weight: 500;
  padding-left: 14px;
  font-size: 13px;
  flex: 1;
  min-width: 0;
  width: 100%;

  .file-name {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    width: 100%;
    display: block;
    white-space: normal;
    line-height: 18px;
  }

  small {
    white-space: normal;
    width: 100%;
    display: inline-block;
  }
`;

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: transparent;
  border-color: rgb(241, 241, 244);
  color: #212529;
  border-right: none;
  border-left: none;
  border-top: none;

  &:hover,
  &:focus,
  &:active {
    background-color: rgb(241, 241, 244);
    border-color: #dee2e6;
    color: #212529;
  }

  &::after {
    display: none;
  }
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 400;
  padding: 8px 12px;
`;

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 32px;
`;
