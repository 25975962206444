import * as Yup from 'yup';

import { ptShort } from 'yup-locale-pt';

Yup.setLocale(ptShort);

const ConfirmPayoutUploadSchema = Yup.object().shape({
  bank_statements: Yup.array().required().nullable(true),
  event_date: Yup.date().required().nullable(true),
});

export { ConfirmPayoutUploadSchema };
