import { contactsConstants } from '_store/_constants';
import service from 'services/contacts.service';

const fetchContactFiles = (id) => {
  return (dispatch) => {
    dispatch({ type: contactsConstants.FETCH_CONTACT_FILES_REQUEST });

    service.fetchContactFiles(id).then(
      (response) => {
        dispatch({
          type: contactsConstants.FETCH_CONTACT_FILES_SUCCESS,
          payload: response,
        });
      },
      (error) => {
        dispatch({
          type: contactsConstants.FETCH_CONTACT_FILES_FAILURE,
          error,
        });
      },
    );
  };
};

export default fetchContactFiles;
