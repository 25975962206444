import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FaFileAlt } from 'react-icons/fa';
import {
  FaRegFileCode,
  FaRegFileExcel,
  FaRegFileImage,
  FaRegFilePdf,
  FaRegFileWord,
} from 'react-icons/fa6';

function FileIcon({ fileName }) {
  const fileExtension = useMemo(() => {
    if (!fileName) {
      return '';
    }

    const parts = fileName.split('.');

    return parts[parts.length - 1].toLowerCase();
  }, [fileName]);

  const renderIcon = () => {
    switch (fileExtension) {
      case 'pdf':
        return <FaRegFilePdf size="1.5em" className="ml-2 mr-2" color="#e46355" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <FaRegFileImage size="1.5em" className="ml-2 mr-2" color="#3498db" />;
      case 'xlsx':
      case 'xls':
        return <FaRegFileExcel size="1.5em" className="ml-2 mr-2" color="#27ae60" />;
      case 'docx':
      case 'doc':
        return <FaRegFileWord size="1.5em" className="ml-2 mr-2" color="#2980b9" />;
      case 'xml':
        return <FaRegFileCode size="1.5em" className="ml-2 mr-2" color="#8e44ad" />;
      default:
        return <FaFileAlt size="1.5em" className="ml-2 mr-2" color="#7f8c8d" />;
    }
  };

  return renderIcon();
}

FileIcon.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default FileIcon;
