import { contactsConstants } from '_store/_constants';

const INITIAL_STATE = {
  contactFiles: [],
  isFetchingContactFiles: false,
  isUploading: false,
  deletedFile: null,
};

export function contacts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case contactsConstants.UPLOAD_CONTACT_FILES_REQUEST: {
      return {
        ...state,
        isUploading: true,
      };
    }
    case contactsConstants.UPLOAD_CONTACT_FILES_SUCCESS: {
      const files = action.payload;

      return {
        ...state,
        isUploading: false,
        contactFiles: [...state.contactFiles, ...files],
      };
    }
    case contactsConstants.UPLOAD_CONTACT_FILES_FAILURE: {
      return {
        ...state,
        isUploading: false,
      };
    }
    case contactsConstants.FETCH_CONTACT_FILES_REQUEST: {
      return {
        ...state,
        isFetchingContactFiles: true,
      };
    }
    case contactsConstants.FETCH_CONTACT_FILES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        isFetchingContactFiles: false,
        contactFiles: data,
      };
    }
    case contactsConstants.FETCH_CONTACT_FILES_FAILURE: {
      return {
        ...state,
        isFetchingContactFiles: false,
      };
    }
    case contactsConstants.DELETE_CONTACT_FILES_REQUEST: {
      const { id } = action.payload;
      const deletedFile = state.contactFiles.find(file => file.id === id);
      const contactFiles = state.contactFiles.filter(file => file.id !== id);

      return {
        ...state,
        deletedFile,
        contactFiles,
      };
    }
    case contactsConstants.DELETE_CONTACT_FILES_SUCCESS: {
      return {
        ...state,
        deletedFile: null,
      };
    }
    case contactsConstants.DELETE_CONTACT_FILES_FAILURE: {
      return {
        ...state,
        contactFiles: [...state.contactFiles, state.deletedFile],
        deletedFile: null,
      };
    }
    case contactsConstants.CLEAR_CONTACT_FILES: {
      return {
        ...state,
        contactFiles: [],
      };
    }
    default:
      return state;
  }
}
