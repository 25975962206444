import Swal from 'sweetalert2';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../../_constants/company-transactions.constants';
import service from '../../../../services/company-transactions.service';
import alertActionCreators from '../../alert.actions';

const deleteMultipleContacts = (params, callback) => {
  return async dispatch => {
    const { ids } = params;
    const itemCount = ids.length;

    const title = `Excluir <strong>${itemCount}</strong> Contatos`;
    const html = `Tem certeza que deseja excluir os <strong>${itemCount}</strong> contatos selecionados? Se houverem anexos nos contatos, eles serão excluídos. Esta operação não pode ser revertida.`;
    const inputAttributes = {
      style: 'width: 70%;',
    };
    let inputPlaceholder = `Digite "excluir ${itemCount} contatos"`;
    const confirmButtonText = `Sim, excluir ${itemCount} contatos`;
    const confirmButtonColor = '#d33';
    const icon = 'warning';
    const showCancelButton = true;
    const dangerMode = true;

    let preConfirm = null;
    let input = null;

    if (itemCount >= 5) {
      input = 'text';
      inputPlaceholder = `Digite "excluir ${itemCount} contatos"`;

      preConfirm = inputValue => {
        if (inputValue !== `excluir ${itemCount} contatos`) {
          Swal.showValidationMessage(
            `Por favor, digite "excluir ${itemCount} contatos" para confirmar a exclusão.`,
          );
        }
      };
    } else if (itemCount > 1) {
      preConfirm = null;
    } else {
      preConfirm = () => {};
    }

    confirmDialog.warning({
      title,
      message: html,
      input,
      inputAttributes,
      inputPlaceholder,
      confirmButtonText,
      confirmButtonColor,
      icon,
      showCancelButton,
      dangerMode,
      preConfirm,
      onConfirm: () => {
        const { ids } = params;

        dispatch(request(ids));

        service
          .deleteMultipleContacts(params)
          .then(() => {
            dispatch(success(ids));
            dispatch(alertActionCreators.success('Contatos excluídos com sucesso!'));

            if (callback) {
              callback();
            }
          })
          .catch(error => {
            dispatch(failure(error));
          });
      },
      onCancel: () => {},
    });
  };

  function request(ids) {
    return {
      type: constants.DELETE_MULTIPLE_RECIPIENTS_REQUEST,
      payload: {
        ids,
      },
    };
  }
  function success(ids) {
    return {
      type: constants.DELETE_MULTIPLE_RECIPIENTS_SUCCESS,
      payload: {
        ids,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_MULTIPLE_RECIPIENTS_FAILURE,
      payload: error,
    };
  }
};

export default deleteMultipleContacts;
