import { toast } from 'react-toastify';
import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import httpService from '../../../services/http.service';

const generateTransactionReport = params => {
  return async dispatch => {
    dispatch(request(params));

    httpService.setupAlertOptions({
      position: toast.POSITION.TOP_LEFT,
      autoClose: 3000,
    });

    try {
      const response = await service.generateTransactionReport(params);
      dispatch(success(response));
    } catch (error) {
      dispatch(failure(error));
    } finally {
      httpService.setupAlertOptions({
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  function request() {
    return {
      type: constants.GENERATE_TRANSACTION_REPORT_REQUEST,
      payload: {},
    };
  }
  function success(response) {
    return {
      type: constants.GENERATE_TRANSACTION_REPORT_SUCCESS,
      payload: response.data,
    };
  }
  function failure(error) {
    return {
      type: constants.GENERATE_TRANSACTION_REPORT_FAILURE,
      payload: error,
    };
  }
};

export default generateTransactionReport;
