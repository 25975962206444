import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaMinusCircle } from 'react-icons/fa';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';

import FORMATTERS from 'helpers/formatters';
import { Card, CardBody, CardHeader, HintIcon } from '_components/_core';

import { StyledTable } from './styles';

function DemonstrativeResults({ reports }) {
  const data = useMemo(() => {
    if (!reports) {
      return {};
    }

    const report = reports.demonstrative_results || {};

    return report.results || {};
  }, [reports]);

  const gross_profit_className = useMemo(() => {
    if (data.gross_profit < 0) {
      return 'text-danger financial-value';
    }

    return 'text-success financial-value';
  }, [data]);

  const operational_profit_className = useMemo(() => {
    if (data.operational_profit < 0) {
      return 'text-danger financial-value';
    }

    return 'text-success financial-value';
  }, [data]);

  const net_profit_className = useMemo(() => {
    if (data.net_profit < 0) {
      return 'text-danger financial-value';
    }

    return 'text-success financial-value';
  }, [data]);

  const net_profit_label = useMemo(() => {
    if (data.net_profit < 0) {
      return 'Prejuízo Líquido do Exercício';
    }

    return 'Lucro Líquido do Exercício';
  }, [data]);

  const net_profit_icon = useCallback(() => {
    if (data.net_profit < 0) {
      return <IoArrowDown className={net_profit_className} />;
    }

    return <IoArrowUp className={net_profit_className} />;
  }, [data, net_profit_className]);

  return (
    <Card>
      <CardHeader
        title="Demonstração de Resultados"
        description={<span className="text-muted">Todas as contas</span>}
        leftColSize={10}
        rightColSize={2}
        sideContent={
          <HintIcon hint="Aqui você visualiza um breve demonstrativo dos resultados do mês selecionado." />
        }
        adjustSideContentMobile={false}
      />
      <CardBody noPaddingTop className="pt-3">
        <StyledTable>
          <tbody>
            <tr>
              <td>Receita Bruta</td>
              <td className="column-amount">
                <span className="text-success financial-value">
                  {FORMATTERS.NUMBER(data.gross_income)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <FaMinusCircle />
                Impostos
              </td>
              <td className="column-amount">
                <span className="text-danger financial-value">
                  {FORMATTERS.NUMBER(data.total_taxes)}
                </span>
              </td>
            </tr>
            <tr className="result">
              <td className="result">Lucro Bruto</td>
              <td className="column-amount">
                <strong className={gross_profit_className}>
                  {FORMATTERS.NUMBER(data.gross_profit)}
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                <FaMinusCircle />
                Total de Desp. Variáveis
              </td>
              <td className="column-amount">
                <span className="text-danger financial-value">
                  {FORMATTERS.NUMBER(data.variable_expenses)}
                </span>
              </td>
            </tr>
            <tr className="result">
              <td className="result">Lucro Operacional</td>
              <td className="column-amount">
                <strong className={operational_profit_className}>
                  {FORMATTERS.NUMBER(data.operational_profit)}
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                <FaMinusCircle />
                Total de Desp. Fixas
              </td>
              <td className="column-amount">
                <span className="text-danger financial-value">
                  {FORMATTERS.NUMBER(data.fixed_expenses)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <FaMinusCircle />
                Gastos com Pessoal
              </td>
              <td className="column-amount">
                <span className="text-danger financial-value">
                  {FORMATTERS.NUMBER(data.people_expenses)}
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot className="positive">
            <tr className="result">
              <td>
                {net_profit_icon()}
                {net_profit_label}
              </td>
              <td className="column-amount">
                <strong className={net_profit_className}>
                  {FORMATTERS.NUMBER(data.net_profit)}
                </strong>
              </td>
            </tr>
          </tfoot>
        </StyledTable>
      </CardBody>
    </Card>
  );
}

DemonstrativeResults.defaultProps = {
  reports: {},
};

DemonstrativeResults.propTypes = {
  reports: PropTypes.object,
};
export default DemonstrativeResults;
