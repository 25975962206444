import styled from 'styled-components';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { Button } from '_components/_core';

export const StyledWrapper = styled.div`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: #0D0E10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  margin-right: 8px;
  margin-left: 8px !important;
  background-color: #F9FBFC;
  border: 1px solid #E8E7EA;
  border-radius: 8px;
  position: relative;

  :hover, :focus {
    border: 1px solid #E8E7EA;
    background-color: #fff;
    text-decoration: none;
    color: #0D0E10;
    cursor: pointer;
  }

  &&& {
    .dropleft .dropdown-toggle::before {
      display: none;
    }

    &::before {
      display: none !important;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    display: ${({ hasUnread }) => (hasUnread ? 'block' : 'none')};
  }
`;

export const StyledOverlayTrigger = styled(OverlayTrigger)`
  display: flex;
  align-items: center;
`;

export const StyledPopover = styled(Popover)`
  min-width: 400px;
  padding: 0 !important;
  color: rgb(42, 46, 52);
  font-family: var(--font-family);
  border: none;

  &.popover, .popover-body {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  }

  &.popover .arrow::before, .popover .arrow::after {
    display: none;
  }

  &.bs-popover-bottom .arrow::after {
    display: none;
  }


`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuEntry = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const StyledMenuText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #333;
`;

export const NotificationMenuTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  padding-top: 4px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  padding: 0;

  &:first-child {
    padding-right: 8px;
  }
`;

export const NotificationTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(37, 47, 74);
`;

export const BoldNotificationTitle = styled(NotificationTitle)`
  font-weight: bold;
`;

export const NotificationDescription = styled.div`
  font-size: 13px;
  color: #252F4A;
  display: flex;
  align-items: center;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
`;

export const NotificationContent = styled.div`
  font-size: 12px;
  color: #999;
`;

export const NotificationWrapper = styled.div` 
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  
  /* padding-left: 10px;
  padding-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px; */

  /* &:hover {
    background-color: #f5f5f5;
  } */
`;

export const NotificationVariantDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  
  ${({ variant }) => {
    switch (variant) {
      case 'info':
        return 'background-color: #2684FF;';
      case 'success':
        return 'background-color: #38A915;';
      case 'warning':
        return 'background-color: #fcca00;';
      case 'error':
        return 'background-color: #e64d43;';
      default:
        return 'background-color: #383d41;';
    }
  }}
`;

export const NotificationDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationSubmenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationDate = styled.div`
  font-size: 10px;
  color: #999;
  margin-right: auto;
`;

export const ActionButton = styled(Button)`
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const ActionMenu = styled.div`
  ${ActionButton}:hover & {
    display: flex;
  }
`;

export const ActionMenuItem = styled.div`
  &:hover {
    /* cursor: pointer;
    background-color: #f5f5f5; */

    ${ActionButton} {
      display: flex;
    }
  }
`;

export const NotificationSeparator = styled.hr`
  border: 0;
  border-top: 1px solid #F1F1F4;
  margin: 0;
`;

export const NotificationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
`;

export const StyledCounter = styled.div`
 
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TabButton = styled.button`
  flex: 1;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  background-color: ${({ active }) => (active ? '#fff' : '#f5f5f5')};
  border: 2px solid ${({ active }) => (active ? '#169c49' : '#f5f5f5')};
  color: ${({ active }) => (active ? '#169c49' : '#333')};
  border-radius: 8px;
  cursor: pointer;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${({ active }) => (active ? '#f5f5f5' : '#f5f5f5')};
  }
`;
