import { contactsConstants } from '_store/_constants';
import alertActions from '_store/_actions/alert.actions';
import confirmDialog from 'helpers/confirmDialog';
import service from 'services/contacts.service';

const deleteContactFiles = (id, callback) => {
  return dispatch => {
    confirmDialog.warning({
      title: 'Excluir Arquivo',
      message:
        'Tem certeza que deseja excluir este arquivo? Esta operação não pode ser revertida.',
      confirmButtonText: 'Sim, excluir!',
      icon: 'warning',
      onConfirm: () => {
        dispatch({
          type: contactsConstants.DELETE_CONTACT_FILES_REQUEST,
          payload: { id },
        });

        service.deleteContactFiles(id).then(
          response => {
            dispatch({
              type: contactsConstants.DELETE_CONTACT_FILES_SUCCESS,
              payload: id,
            });

            dispatch(alertActions.success('Arquivo excluído com sucesso.'));

            if (callback) {
              callback(response);
            }
          },
          error => {
            dispatch({
              type: contactsConstants.DELETE_CONTACT_FILES_FAILURE,
              error,
            });

            dispatch(alertActions.error('Erro ao excluir arquivo.'));
          },
        );
      },
      onCancel: () => {},
    });
  };
};

export default deleteContactFiles;
