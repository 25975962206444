import { connect } from 'react-redux';

import sidebarActionCreators from '_store/_actions/sidebar';
import userActionCreators from '_store/_actions/user';
import authActionCreators from '_store/_actions/auth';
import companyActionCreators from '_store/_actions/company';
import subscriptionActionCreators from '_store/_actions/subscription';
import notificationsActionCreators from '_store/_actions/notifications';

import NavBar from './NavBar';

const mapStateToProps = state => ({
  collapsed: state.sidebar.collapsed,
  transactionsOverview: state.companyTransactions.transactionsOverview,
  user: state.auth.user,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = {
  onSidebarToggle: sidebarActionCreators.toggleSidebar,
  onLoadUserPermissions: userActionCreators.fetchUsersPermissions,
  onLogout: authActionCreators.logout,
  onFetchBlockedPeriod: companyActionCreators.getBlockedPeriod,
  onFetchInvitations: userActionCreators.fetchMyCompanyInvitations,
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onUpdateStoreSubscription: subscriptionActionCreators.updateStoreSubscription,
  onFetchNewNotification: notificationsActionCreators.fetchNewUserNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
